import { useState, useEffect } from 'react';

const useResizeDimensions = (
  myRef: React.RefObject<
    HTMLDivElement | HTMLUListElement | HTMLLIElement | null
  >,
  resize?: boolean
) => {
  const initialDimensions = {
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  };
  const [dimensions, setDimensions] = useState(initialDimensions);

  useEffect(() => {
    if (!myRef || myRef?.current === null || undefined) {
      setDimensions(initialDimensions);
    } else {
      const getDimensions = () => {
        if (myRef.current !== null) {
          const rect = myRef.current.getBoundingClientRect();
          return {
            width: myRef?.current.offsetWidth || 0,
            height: myRef?.current.offsetHeight || 0,
            top: rect.top,
            left: rect.left,
          };
        }
        return initialDimensions;
      };

      const handleResize = () => {
        setDimensions(getDimensions());
      };

      if (myRef?.current && myRef.current !== null) {
        setDimensions(getDimensions());
      }

      window.addEventListener('resize', handleResize);

      if (resize) {
        handleResize();
      }

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [myRef, resize]);

  return dimensions;
};

export default useResizeDimensions;
