import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.section`
  width: 100%;
`;

export const Header = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 15px 10px;

  .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 160px;
  }

  .cogsHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    text-align: right;

    .cogsSubHeading {
      margin-right: 15px;
    }
  }

  .cogsPercentage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    text-align: right;
  }
`;

export const CostLink = styled(Span)`
  margin-left: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary.default};
  }
`;

export const ListColumns = styled(LayoutColumnContainer)`
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-gap: 15px;

  @media ${device.mobileL} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
`;
