import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export const Container = styled.div`
  width: 100%;
  max-width: 950px;
  margin: 0 auto;

  .content {
    padding-bottom: 30px;
  }

  .contentSubHeading {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    @media ${device.mobileM} {
      padding-left: 75px;
    }

    @media ${device.mobileL} {
      padding-bottom: 0;
    }
  }

  .contentText {
    display: block;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: ${({ theme }) => theme.fontSize.button};

    @media ${device.mobileM} {
      padding-left: 75px;
    }

    @media ${device.mobileL} {
      padding-top: 0;
    }
  }

  .contentFooter {
    position: relative;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .contentFooterText {
    font-size: ${({ theme }) => theme.fontSize.button};
  }
`;
