import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import {
  AccountType,
  IngredientConfirmationState,
  IngredientsDocument,
  MetricType,
  useCreateIngredientMutation,
  useCreateIngredientProductMutation,
  useIngredientQuery,
  useIngredientsQuery,
  useUpdateIngredientMutation,
  useVenueQuery,
  VenueDocument,
} from '../../../generated/graphql';
import { theme } from '../../../styles/theme';
import { constants } from '../../../utils/constants';
import { getMetricData, isEmpty } from '../../../utils/helper';
import { Button } from '../../shared/button';
import { Input, SelectList } from '../../shared/formElements';
import { CheckboxStyled } from '../../shared/formElements/FormElements.styles';
import { LayoutColumn, LayoutPage } from '../../shared/layout';
import {
  ButtonGroup,
  NestedColumns,
  SideLabel,
} from '../../shared/layout/Layout.styles';
import { DotsLoading, LogoLoading } from '../../shared/loading';
import { H3, Span } from '../../shared/typefaces/Typefaces.styles';
import { SmallButton } from '../ingredient/Ingredient.styles';
import { Container, Form, Header } from './IngredientDetails.styles';
import { MetricWithOptionalId } from './ingredientDetails.types';
import { Requests } from './sections';
import { overlayConstants } from '../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

// * when creating a new ingredient
//   if you click an existing ingredient, it will redirect you to that one, and create the product if not in your library yet
//   if you click add, it doesn't immediately create the ingredient, but creates it when you click save
//
// * When editing an existing ingredient (verified)
//   if you click an existing ingredient, it will redirect you to that one, and create the product if not in your library yet
//   clicking 'add' doesn't immediately create the ingredient, it creates it when you save by copying the ingredient and updating the ingredient product to match the new ingredient
//
// * When editing an existing ingredient (unverified)
//   if you click an existing ingredient, it will redirect you to that one, and create the product if not in your library yet
//   clicking 'update' doesn't immediately update the ingredient, it updates it when you save
//
const IngredientDetails = () => {
  const {
    appWidth,
    selectedIngredient,
    selectedVenueObject,
    newIngredientName,
    selectedRecipe,
    extraSliderToggle,
    account,
    user,
    dispatch,
  } = useContext(LayoutContext);
  const [state, setState] = useState({
    initialLoad: true,
    uniqueDisplayNameError: false,
    metricError: false,
    requestChange: false,
  });
  const venue = useVenueQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
  });
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const ingredients = useIngredientsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const ingredient = useIngredientQuery({
    variables: {
      input: {
        ingredientId: selectedIngredient!,
        venueId: selectedVenueObject?.id!,
      },
    },
    skip: !selectedIngredient, // don't make query if ingredient is new (i.e. no info to load)
    fetchPolicy: 'cache-and-network',
  });
  const [
    updateIngredientMutation,
    updateIngredient,
  ] = useUpdateIngredientMutation();
  const [
    createIngredientMutation,
    createIngredient,
  ] = useCreateIngredientMutation();
  const [
    createIngredientProductMutation,
    createIngredientProduct,
  ] = useCreateIngredientProductMutation();

  const availableIngredients = ingredients?.data?.ingredients.ingredients?.map(
    (ingredient) => ({
      label: ingredient.displayName,
      value: ingredient.id,
    })
  );

  const selectedIngredientObject = ingredient.data?.ingredient.ingredient;
  const isSelectedIngredientVerified =
    selectedIngredientObject?.confirmationState ===
    IngredientConfirmationState.Verified;

  const metrics = selectedIngredientObject?.metrics;
  const wholeMetricData = getMetricData(MetricType.Whole, metrics);
  const cupMetricData = getMetricData(MetricType.Cup, metrics);
  const sliceMetricData = getMetricData(MetricType.Slice, metrics);
  const initialLiquidValue = ingredient.data?.ingredient.ingredient?.liquid;

  const defaultFormValues = {
    displayName: newIngredientName
      ? {
          label: newIngredientName,
          value: newIngredientName,
          inputValue: newIngredientName,
        }
      : selectedIngredientObject
      ? {
          label: selectedIngredientObject.displayName,
          value: selectedIngredientObject.id,
        }
      : { label: '', value: '' },
    cup: cupMetricData ? Number(cupMetricData.grams) : '',
    cupId: cupMetricData ? cupMetricData.id : 'grams',
    whole: wholeMetricData ? Number(wholeMetricData.grams) : '',
    wholeId: wholeMetricData ? wholeMetricData.id : 'grams',
    slice: sliceMetricData ? Number(sliceMetricData.grams) : '',
    sliceId: sliceMetricData ? sliceMetricData.id : 'grams',
    liquid: selectedIngredient ? initialLiquidValue : false,
  };

  const {
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const shouldUpdateExistingIngredient =
    (selectedIngredient && !isSelectedIngredientVerified) ||
    (selectedIngredient && account?.type === AccountType.RecipeRevenue);

  const requestForIngredientChange =
    selectedIngredient &&
    isSelectedIngredientVerified &&
    account?.type !== AccountType.RecipeRevenue &&
    state.requestChange;

  const shouldSkipReset =
    selectedIngredient && (ingredient.loading || ingredients.loading);

  useEffect(() => {
    if (shouldSkipReset) {
      return;
    }

    reset(defaultFormValues);
  }, [JSON.stringify(defaultFormValues), shouldSkipReset]);

  const newSelectedIngredient = async (displayName: any) => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
      setValue('displayName', '');
      return;
    }

    // bail out if displayName is something unusual
    if (!displayName || typeof displayName !== 'object') {
      return;
    }

    // try to find if the user selected an ingredient that already exists
    const existingSelectedIngredient = ingredients.data?.ingredients?.ingredients?.find(
      (i) => i.id === displayName.value
    );

    const ingredientProductFromVenue = venue.data?.venue?.userVenue?.venue.ingredientProducts.find(
      (ingredientProduct) =>
        ingredientProduct.ingredientId === displayName.value
    );

    // if the user chose the 'Add' or 'Update' option, we handle that on submit
    if (!existingSelectedIngredient) {
      return;
    }

    // if the user chose an ingredient that we haven't got in our venue yet, copy it into our venue
    if (existingSelectedIngredient && !ingredientProductFromVenue) {
      try {
        const response = await createIngredientProductMutation({
          variables: {
            input: {
              ingredientId: displayName.value,
              venueId: selectedVenueObject?.id!,
              totalActiveSeconds: getTotalActiveSeconds(),
            },
          },
          refetchQueries: ['Venue'],
          awaitRefetchQueries: true,
        });
        if (response.data?.createIngredientProduct.successful) {
          const ingredientProduct =
            response.data?.createIngredientProduct.ingredientProduct;
          await waitToUpdateExtraSlider('ingredient');
          dispatch({
            type: 'SELECT_INGREDIENT_PRODUCT',
            payload: ingredientProduct?.id,
          });
          dispatch({
            type: 'SELECT_INGREDIENT',
            payload: ingredientProduct?.ingredientId,
          });
          dispatch({ type: 'TOGGLE_TOP_SLIDER' });
        }
      } catch (error) {
        console.log('error', error);
      }
      return;
    }

    // if the user chose an existing ingredient that is in our venue already, redirect to that
    if (existingSelectedIngredient && ingredientProductFromVenue) {
      try {
        dispatch({
          type: 'SELECT_INGREDIENT_PRODUCT',
          payload: ingredientProductFromVenue?.id,
        });
        dispatch({
          type: 'SELECT_INGREDIENT',
          payload: existingSelectedIngredient.id,
        });
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const waitToUpdateExtraSlider = async (page: string) => {
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });
  };

  const makeMetricArray = (
    {
      whole,
      wholeId,
      cup,
      cupId,
      slice,
      sliceId,
    }: ReturnType<typeof getValues>,
    isCreatingNewMetrics: boolean
  ): MetricWithOptionalId[] => {
    const metricArray: MetricWithOptionalId[] = [];
    if (!isEmpty(whole)) {
      metricArray.push({
        id: wholeId !== 'grams' && !isCreatingNewMetrics ? wholeId : undefined,
        type: MetricType.Whole,
        grams: Number(whole),
      });
    }
    if (!isEmpty(cup)) {
      metricArray.push({
        id: cupId !== 'grams' && !isCreatingNewMetrics ? cupId : undefined,
        type: MetricType.Cup,
        grams: Number(cup),
      });
    }
    if (!isEmpty(slice)) {
      metricArray.push({
        id: sliceId !== 'grams' && !isCreatingNewMetrics ? sliceId : undefined,
        type: MetricType.Slice,
        grams: Number(slice),
      });
    }
    return metricArray;
  };

  const onSubmit = async (formData: ReturnType<typeof getValues>) => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      // redirect to guest overlay
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    } else {
      // Redirect user to ingredient product page if no changes are required
      if (!shouldUpdateExistingIngredient && !requestForIngredientChange) {
        await waitToUpdateExtraSlider('ingredient');
        dispatch({ type: 'TOGGLE_TOP_SLIDER' });
        return;
      }

      // handle issues with metric array
      const metricArray = makeMetricArray(formData, !selectedIngredient);
      if (isEmpty(metricArray)) {
        setState({ ...state, metricError: true });
        return;
      }

      // User is editing an existing ingredient which is unverified
      if (shouldUpdateExistingIngredient || requestForIngredientChange) {
        const input = {
          displayName: formData.displayName.label,
          liquid: Boolean(formData.liquid),
          ingredientId: selectedIngredient!,
          metricInput: metricArray,
          venueId: selectedVenueObject?.id!,
          totalActiveSeconds: getTotalActiveSeconds(),
        };
        try {
          const response = await updateIngredientMutation({
            variables: {
              input,
            },
          });

          if (response.data?.updateIngredient.successful) {
            await waitToUpdateExtraSlider('ingredient');
            dispatch({ type: 'TOGGLE_TOP_SLIDER' });
          }
        } catch (error) {
          console.log('err ###', error);
        }
        return;
      }

      // User is creating a new ingredient or editing a verified ingredient
      try {
        const inputData = {
          displayName: formData.displayName.label,
          liquid: Boolean(formData.liquid),
          metricInput: metricArray,
          venueId: selectedVenueObject?.id!,
          totalActiveSeconds: getTotalActiveSeconds(),
        };
        const response = await createIngredientMutation({
          variables: {
            input: inputData,
          },
          refetchQueries: [
            {
              query: VenueDocument,
              variables: {
                input: { venueId: selectedVenueObject?.id! },
              },
            },
            {
              query: IngredientsDocument,
            },
          ],
          awaitRefetchQueries: true,
        });

        if (response.data?.createIngredient.successful) {
          const ingredientId = response.data?.createIngredient.ingredient?.id;
          if (!ingredientId) {
            throw new Error('Received undefined ingredient id');
          }

          await waitToUpdateExtraSlider('ingredient');
          dispatch({ type: 'TOGGLE_TOP_SLIDER' });
          dispatch({
            type: 'SELECT_INGREDIENT',
            payload: ingredientId,
          });
          dispatch({
            type: 'SELECT_INGREDIENT_PRODUCT',
            payload: response.data.createIngredient.ingredientProduct?.id,
          });
          dispatch({ type: 'SET_NEW_INGREDIENT_NAME', payload: '' });
          dispatch({
            type: 'SET_NEW_INGREDIENT_ID',
            payload: ingredientId,
          });
        }
      } catch (error: any) {
        console.log('error', error);
        if (error.message.includes('unique')) {
          setState({ ...state, uniqueDisplayNameError: true });
        }
      }
    }
  };

  const handleCancel = () => {
    if (newIngredientName && selectedRecipe) {
      if (selectedIngredient && !newIngredientName) {
        dispatch({ type: 'TOGGLE_TOP_SLIDER' });
        dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });
      } else {
        if (newIngredientName) {
          dispatch({ type: 'SET_NEW_INGREDIENT_NAME', payload: '' });
        }
        if (selectedRecipe) {
          dispatch({ type: 'TOGGLE_TOP_SLIDER' });
          if (extraSliderToggle) {
            dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
          }
        }
      }
    } else {
      if (selectedIngredient && !newIngredientName && !isEmpty(metrics)) {
        dispatch({ type: 'TOGGLE_TOP_SLIDER' });
        dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'ingredient' });
      } else {
        if (newIngredientName) {
          dispatch({ type: 'SET_NEW_INGREDIENT_NAME', payload: '' });
        }
        dispatch({ type: 'TOGGLE_TOP_SLIDER' });
        if (extraSliderToggle) {
          dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
        }
      }
    }
  };

  const sideLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  if (
    ingredient.loading ||
    ingredients.loading ||
    createIngredientProduct.loading
  ) {
    return <LogoLoading size={60} />;
  }

  const handleCancelCTA =
    appWidth !== 0 && appWidth < theme.mQ.tablet ? (
      <Button color="default" asCircle inversed onClick={handleCancel}>
        x
      </Button>
    ) : newIngredientName ? (
      <Button color="default" inversed onClick={handleCancel}>
        Complete later
      </Button>
    ) : (
      <Button color="default" inversed onClick={handleCancel}>
        Cancel
      </Button>
    );

  const liquid = watch('liquid');
  const selectedIngredientName = selectedIngredientObject?.displayName || '';
  const saveOrAdd = selectedIngredient ? 'Save' : 'Add';
  const savingOrAdding = selectedIngredient ? 'Saving' : 'Adding';
  const isLoading =
    updateIngredient.loading ||
    createIngredientProduct.loading ||
    createIngredient.loading;

  const formValuesChanged = (value: any) => {
    const formValues = Object.values(defaultFormValues);
    const changed = formValues.find((v) => v.toString() === value.toString());
    // Can only do this because no form values are overlap
    if (!changed) {
      setState({ ...state, requestChange: true });
    } else {
      setState({ ...state, requestChange: false });
    }
  };

  return (
    <LayoutPage
      backButton
      backButtonCTA={handleCancel}
      align="center"
      withLine
      heading={selectedIngredient ? 'Edit Ingredient' : 'Add Ingredient'}
      subHeading={
        selectedIngredient
          ? `Update ${selectedIngredientName} details`
          : !isEmpty(venue)
          ? `Find or Add a new ingredient for ${venue?.data?.venue?.userVenue?.venue?.displayName}`
          : 'Find or Add a new Ingredient'
      }
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              handleCancelCTA,
              <SmallButton
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) =>
                    requestForIngredientChange
                      ? 'Request'
                      : loading
                      ? savingOrAdding
                      : saveOrAdd
                  }
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </SmallButton>,
            ]
          : [
              handleCancelCTA,
              <Button
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(loading) =>
                    requestForIngredientChange
                      ? `${loading ? 'Requesting' : 'Request'} Change`
                      : `${loading ? savingOrAdding : saveOrAdd} Ingredient`
                  }
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <Container>
        <Header>{selectedIngredientName} Details</Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <LayoutColumn>
            {sideLabel('Ingredient Name *')}
            <SelectList
              autoComplete
              freeSolo
              control={control}
              name="displayName"
              label={fieldLabel('Ingredient Name *')}
              handleChange={newSelectedIngredient}
              errorText={
                errors.displayName
                  ? errors.displayName.value?.message
                  : state.uniqueDisplayNameError
                  ? 'Ingredient name must be unique'
                  : undefined
              }
              renderAddValueOptionLabel={
                selectedIngredient && !isSelectedIngredientVerified
                  ? (v) => `Update name to ${v}`
                  : undefined
              }
              options={availableIngredients || []}
            />
          </LayoutColumn>

          <Span fontSize="small" color="faded" className="description">
            Update or add relevate ingredient metric amounts in grams. This is
            used to calculate ingredient costs in Recipe Revenue
          </Span>
          <H3 className="subheading">Ingredient Unit Metrics</H3>
          <Span
            fontSize="small"
            color="faded"
            className="description helperText"
          >
            Please enter atleast one relevant metric weight below
          </Span>
          {state.metricError && (
            <Span fontSize="small" color="faded" className="errorMessage">
              Please confirm atleast <u>one</u> ingredient metric weight
            </Span>
          )}
          <LayoutColumn>
            {sideLabel('One Metric Cup')}
            <NestedColumns>
              <Controller
                as={<Input />}
                type="number"
                name="cup"
                label={fieldLabel('One Metric Cup')}
                control={control}
                errorText={errors.cup && errors.cup.message}
                handleChange={(value) => formValuesChanged(value)}
              />
              <SelectList
                control={control}
                name="cupId"
                noBorder
                options={[
                  {
                    label: liquid
                      ? constants.metric.ml.p
                      : constants.metric.gram.p,
                    value: cupMetricData ? cupMetricData.id : 'grams',
                  },
                ]}
              />
            </NestedColumns>
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('One Whole')}
            <NestedColumns>
              <Controller
                as={<Input />}
                type="number"
                name="whole"
                label={fieldLabel('One Whole')}
                control={control}
                errorText={errors.whole && errors.whole.message}
                defaultValue=""
                handleChange={formValuesChanged}
              />
              <SelectList
                control={control}
                name="wholeId"
                noBorder
                options={[
                  {
                    label: liquid
                      ? constants.metric.ml.p
                      : constants.metric.gram.p,
                    value: wholeMetricData ? wholeMetricData.id : 'grams',
                  },
                ]}
              />
            </NestedColumns>
          </LayoutColumn>

          <LayoutColumn>
            {sideLabel('One Slice')}
            <NestedColumns>
              <Controller
                as={<Input />}
                type="number"
                name="slice"
                label={fieldLabel('One Slice')}
                control={control}
                errorText={errors.slice && errors.slice.message}
                defaultValue=""
                handleChange={formValuesChanged}
              />
              <SelectList
                control={control}
                name="sliceId"
                noBorder
                options={[
                  {
                    label: liquid
                      ? constants.metric.ml.p
                      : constants.metric.gram.p,
                    value: sliceMetricData ? sliceMetricData.id : 'grams',
                  },
                ]}
              />
            </NestedColumns>
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Is a Liquid')}
            <CheckboxStyled
              name="liquid"
              inputRef={register}
              defaultChecked={selectedIngredient ? initialLiquidValue : false}
              onChange={formValuesChanged}
            />
          </LayoutColumn>
        </Form>
      </Container>

      <ButtonGroup>
        <Button color="default" inversed onClick={handleCancel}>
          {newIngredientName ? 'Complete later' : 'Cancel'}
        </Button>

        <Button
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          <DotsLoading
            text={(loading) =>
              requestForIngredientChange
                ? `${loading ? 'Requesting' : 'Request'} Change`
                : `${loading ? savingOrAdding : saveOrAdd} Ingredient`
            }
            isLoading={isLoading}
            size="small"
            lineHeight={10}
            noMargin
          />
        </Button>
      </ButtonGroup>
      <Requests metrics={metrics} />
    </LayoutPage>
  );
};

const validationSchema = Yup.object().shape({
  // displayName: Yup.object().shape(displayNameObject),
});

export default IngredientDetails;
