import React, { useContext, FC } from 'react';
import { LayoutColumn } from '../../../shared/layout';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, SelectList } from '../../../shared/formElements';
import { SideLabel, Section } from '../Venue.styles';
import { LayoutContext } from '../../../../contexts/layoutContext';
import { theme } from '../../../../styles/theme';
import { IVenueDataProps } from '../../account/sections/venues/venues.types';
import { RecommendedPriceType } from '../../../../generated/graphql';

const VenueOptions: FC<IVenueDataProps> = ({ data }) => {
  const { addVenue, appWidth } = useContext(LayoutContext);
  const { errors, control } = useFormContext();

  const sideLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  const venue = addVenue ? undefined : data?.data?.venue?.userVenue?.venue;

  const recipeCostOption = {
    label: '2 x Total Recipe Cost',
    value: RecommendedPriceType.RecipeCost,
  };

  const recommendedPriceOptons = [
    { label: '4.1 x Food Cost', value: RecommendedPriceType.FoodCost },
    recipeCostOption,
    {
      label: 'Based On Target Margin',
      value: RecommendedPriceType.TargetMargin,
    },
  ];

  const recommendedPriceDefault =
    venue?.recommendedPrice || recipeCostOption.value;

  return (
    <Section>
      <LayoutColumn>
        {sideLabel('Target Food Margin')}
        <Controller
          as={<Input />}
          control={control}
          label={fieldLabel('Target Food Margin')}
          name="targetFoodMargin"
          errorText={errors.targetFoodMargin && errors.targetFoodMargin.message}
          defaultValue={
            addVenue || venue?.targetFoodMargin === null
              ? ''
              : venue?.targetFoodMargin
          }
        />
      </LayoutColumn>
      <LayoutColumn>
        {sideLabel('Minimum Recipe Sales Price')}
        <SelectList
          control={control}
          name="recommendedPrice"
          label={fieldLabel('Minimum Recipe Sales Price')}
          options={recommendedPriceOptons}
          defaultValue={
            addVenue ? recipeCostOption.value : recommendedPriceDefault
          }
          addValue="%"
        />
      </LayoutColumn>
    </Section>
  );
};

export default VenueOptions;
