import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: 'Query';
  brand: BrandResponse;
  brands: BrandResponse;
  buildNo: Scalars['Float'];
  ingredients: IngredientsResponse;
  ingredientsWithState: IngredientsResponse;
  ingredient: IngredientResponse;
  ingredientRequest: IngredientRequestResponse;
  /** Gets Example Recipe */
  exampleRecipe: RecipeResponse;
  /** Finds all the recipes that belong to a Venue */
  recipe: RecipeResponse;
  /** Finds all the recipes that belong to a Venue */
  venueRecipes: RecipesResponse;
  /** Finds all the recipes that belong to a Category */
  recipeCategoryRecipes: RecipesResponse;
  supplier: SupplierResponse;
  suppliers: SupplierResponse;
  supplierGroups: SupplierGroupResponse;
  me?: Maybe<UserResponse>;
  users: UsersResponse;
  invoices: InvoicesResponse;
  venues: Array<UserVenue>;
  venueStats: VenueResponse;
  venue?: Maybe<UserVenueResponse>;
  stocktake: StocktakeResponse;
  stocktakes: StocktakeResponse;
  inventoryStocktake: StocktakeResponse;
  addOns: AddOnResponse;
};

export type QueryBrandArgs = {
  input: GetBrandInput;
};

export type QueryIngredientsWithStateArgs = {
  input: GetIngredientsWithStateInput;
};

export type QueryIngredientArgs = {
  input: GetIngredientInput;
};

export type QueryIngredientRequestArgs = {
  input: GetIngredientRequestInput;
};

export type QueryExampleRecipeArgs = {
  input: ExampleRecipeInput;
};

export type QueryRecipeArgs = {
  input: GetRecipeInput;
};

export type QueryVenueRecipesArgs = {
  input: GetVenueRecipesInput;
};

export type QueryRecipeCategoryRecipesArgs = {
  input: GetRecipeCategoryRecipesInput;
};

export type QuerySupplierArgs = {
  input: GetSupplierInput;
};

export type QueryVenueArgs = {
  input: GetVenueInput;
};

export type QueryStocktakeArgs = {
  input: GetStocktakeInput;
};

export type QueryStocktakesArgs = {
  input: GetStocktakesInput;
};

export type QueryInventoryStocktakeArgs = {
  input: GetInventoryStocktakeInput;
};

export type BrandResponse = {
  __typename?: 'BrandResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  brands?: Maybe<Array<Brand>>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  creatorId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  creator: User;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  isEmailConfirmed: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  position: PositionType;
  subscriptionStatus: SubscriptionStatus;
  accountType: AccountType;
  admin: Scalars['Boolean'];
  lastLoginAt: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripePaymentExpMonth?: Maybe<Scalars['Float']>;
  stripePaymentExpYear?: Maybe<Scalars['Float']>;
  stripePaymentLast4?: Maybe<Scalars['String']>;
  billingCycle: BillingCycle;
  addressId: Scalars['String'];
  billingAddressId: Scalars['String'];
  venuesSharedWithYou: Array<UserVenue>;
  ingredients: Array<Ingredient>;
  ingredientRequests?: Maybe<Array<IngredientRequest>>;
  brands: Array<Brand>;
  suppliers: Array<Supplier>;
  stocktakes: Array<Stocktake>;
  addOns: Array<AddOn>;
  sections: Array<Section>;
  recipes: Array<Recipe>;
  address?: Maybe<Address>;
  billingAddress?: Maybe<Address>;
};

/** PositionType */
export enum PositionType {
  Chef = 'CHEF',
  Employee = 'EMPLOYEE',
  HeadChef = 'HEAD_CHEF',
  Manager = 'MANAGER',
  Other = 'OTHER',
  Owner = 'OWNER',
}

/** SubscriptionStatus */
export enum SubscriptionStatus {
  SubscriptionActive = 'SUBSCRIPTION_ACTIVE',
  SubscriptionInactive = 'SUBSCRIPTION_INACTIVE',
  SubscriptionPending = 'SUBSCRIPTION_PENDING',
}

/** AccountType */
export enum AccountType {
  Guest = 'GUEST',
  Registered = 'REGISTERED',
  RecipePlus = 'RECIPE_PLUS',
  HeadChef = 'HEAD_CHEF',
  Owner = 'OWNER',
  RecipeRevenue = 'RECIPE_REVENUE',
  Feedback = 'FEEDBACK',
}

/** Payment cycle of subscriptions */
export enum BillingCycle {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type UserVenue = {
  __typename?: 'UserVenue';
  id: Scalars['ID'];
  status: Scalars['String'];
  selected: Scalars['Boolean'];
  totalActiveSeconds: Scalars['Float'];
  userId: Scalars['String'];
  venueId: Scalars['String'];
  user: User;
  venue: Venue;
};

export type Venue = {
  __typename?: 'Venue';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlName: Scalars['String'];
  email: Scalars['String'];
  type: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  weeksOpen: Scalars['Float'];
  weeksOpenUnit: Scalars['String'];
  prepTime: Scalars['Float'];
  prepTimeUnit: Scalars['String'];
  avgWastagePercentage?: Maybe<Scalars['Float']>;
  wastageUnit?: Maybe<Scalars['String']>;
  personal?: Maybe<Scalars['Boolean']>;
  active: Scalars['Boolean'];
  recipeProfitIncreasePerYear: Scalars['Float'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  creatorId: Scalars['String'];
  targetFoodMargin?: Maybe<Scalars['Float']>;
  recommendedPrice: Scalars['String'];
  venueCostId: Scalars['String'];
  venueCost: VenueCost;
  usersSharedWithVenue: Array<UserVenue>;
  address?: Maybe<Address>;
  recipes: Array<Recipe>;
  stocktakes: Array<Stocktake>;
  sections: Array<Section>;
  ingredientProducts: Array<IngredientProduct>;
  venueProfitTrends: Array<VenueProfitTrend>;
};

export type VenueCost = {
  __typename?: 'VenueCost';
  id: Scalars['ID'];
  chefCost: Scalars['Float'];
  chefCostUnit: Scalars['String'];
  rentCost: Scalars['Float'];
  rentCostUnit: Scalars['String'];
  waterCost: Scalars['Float'];
  waterCostUnit: Scalars['String'];
  powerCost: Scalars['Float'];
  powerCostUnit: Scalars['String'];
  insuranceCost: Scalars['Float'];
  insuranceCostUnit: Scalars['String'];
  councilCost: Scalars['Float'];
  councilCostUnit: Scalars['String'];
  foodWastePercentage: Scalars['Float'];
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  shire?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryShort?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLookup?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type Recipe = {
  __typename?: 'Recipe';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlName: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  /** Number of serves sold per week */
  weeklySalesPerServe: Scalars['Float'];
  salesPricePerServe: Scalars['Float'];
  active: Scalars['Boolean'];
  recipeRevenue: Scalars['Float'];
  /** Profit per batch prepared */
  recipeProfit: Scalars['Float'];
  /** Original Profit per batch prepared */
  originalRecipeProfit: Scalars['Float'];
  /** Profit increase per serve of current selected version vs original version */
  recipeProfitIncreasePerServe: Scalars['Float'];
  /** Profit increase per serve of most profitable version vs original version */
  highestRecipeProfitIncreasePerServe: Scalars['Float'];
  originalServes: Scalars['Float'];
  serves: Scalars['Float'];
  totalGrams: Scalars['Float'];
  totalTime: Scalars['Float'];
  foodCostPercentage: Scalars['Float'];
  usedAsTemplateCount: Scalars['Float'];
  recipeActiveSeconds: Scalars['Float'];
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  venueId: Scalars['String'];
  venue: Venue;
  originalCreatorId?: Maybe<Scalars['String']>;
  creatorId: Scalars['String'];
  creator: User;
  recipeVersions: Array<RecipeVersion>;
  numScaledRecipeVersions?: Maybe<Scalars['Int']>;
  recipeAsIngredients?: Maybe<Array<RecipeAsIngredient>>;
  recipeProfitTrend?: Maybe<Array<RecipeProfitTrend>>;
};

export type RecipeVersion = {
  __typename?: 'RecipeVersion';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlName: Scalars['String'];
  serves: Scalars['Float'];
  recipeWastage?: Maybe<Scalars['Float']>;
  recipeId: Scalars['String'];
  selected: Scalars['Boolean'];
  totalStaffTime: Scalars['Float'];
  totalTime: Scalars['Float'];
  totalGrams: Scalars['Float'];
  active: Scalars['Boolean'];
  original: Scalars['Boolean'];
  isScaled: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  recipe: Recipe;
  recipeTimeItems: Array<RecipeTimeItem>;
  recipeAsIngredients?: Maybe<Array<RecipeAsIngredient>>;
  recipeIngredients?: Maybe<Array<RecipeIngredient>>;
};

export type RecipeTimeItem = {
  __typename?: 'RecipeTimeItem';
  id: Scalars['ID'];
  description: Scalars['String'];
  quantity: Scalars['Float'];
  unit: TimeUnitType;
  staffTime: Scalars['Boolean'];
  order: Scalars['Float'];
  recipeVersionId: Scalars['String'];
  recipeVersion: RecipeVersion;
};

/** TimeUnitType */
export enum TimeUnitType {
  Second = 'SECOND',
  Minute = 'MINUTE',
  Hour = 'HOUR',
  Day = 'DAY',
}

export type RecipeAsIngredient = {
  __typename?: 'RecipeAsIngredient';
  id: Scalars['ID'];
  unit: RecipeMetricUnit;
  quantity: Scalars['Float'];
  order: Scalars['Float'];
  recipeVersionId: Scalars['String'];
  recipeVersion: RecipeVersion;
  recipeId: Scalars['String'];
  recipe: Recipe;
};

/** RecipeMetricUnit */
export enum RecipeMetricUnit {
  Serve = 'SERVE',
  Percentage = 'PERCENTAGE',
  Gram = 'GRAM',
}

export type RecipeIngredient = {
  __typename?: 'RecipeIngredient';
  id: Scalars['ID'];
  unit: IngredientMetricUnit;
  quantity: Scalars['Float'];
  order: Scalars['Float'];
  recipeVersionId: Scalars['String'];
  ingredientId: Scalars['String'];
  recipeVersion: RecipeVersion;
  ingredient: Ingredient;
};

/** IngredientMetricUnit */
export enum IngredientMetricUnit {
  Cup = 'CUP',
  Gram = 'GRAM',
  Ml = 'ML',
  Slice = 'SLICE',
  Tablespoon = 'TABLESPOON',
  Teaspoon = 'TEASPOON',
  Whole = 'WHOLE',
}

export type Ingredient = {
  __typename?: 'Ingredient';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlName: Scalars['String'];
  averageCost100g: Scalars['Float'];
  averageWastagePercentage: Scalars['Float'];
  confirmationState: IngredientConfirmationState;
  creatorId: Scalars['String'];
  active: Scalars['Boolean'];
  liquid: Scalars['Boolean'];
  category: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  creator: User;
  metrics: Array<Metric>;
  ingredientRequests: Array<IngredientRequest>;
  ingredientProducts: Array<IngredientProduct>;
  recipeIngredients: Array<RecipeIngredient>;
  stocktakeIngredients: Array<StocktakeIngredient>;
};

/** State of admin approval of the ingredient. */
export enum IngredientConfirmationState {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
  Ignored = 'IGNORED',
}

export type Metric = {
  __typename?: 'Metric';
  id: Scalars['ID'];
  type: Scalars['String'];
  grams: Scalars['Float'];
  ingredientId: Scalars['String'];
  ingredient: Ingredient;
};

export type IngredientRequest = {
  __typename?: 'IngredientRequest';
  id: Scalars['ID'];
  creatorId: Scalars['String'];
  ingredientId: Scalars['String'];
  venueId: Scalars['String'];
  metricId: Scalars['String'];
  type: Scalars['String'];
  grams: Scalars['Float'];
  newMetric: Scalars['Boolean'];
  status: IngredientRequestStatus;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  ingredient: Ingredient;
  creator: User;
};

/** IngredientRequestStatus */
export enum IngredientRequestStatus {
  Pending = 'PENDING',
  Denied = 'DENIED',
  Updated = 'UPDATED',
  Verified = 'VERIFIED',
  New = 'NEW',
}

export type IngredientProduct = {
  __typename?: 'IngredientProduct';
  id: Scalars['ID'];
  ingredientId: Scalars['String'];
  venueId: Scalars['String'];
  brandId?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  sizeId?: Maybe<Scalars['String']>;
  wastageId?: Maybe<Scalars['String']>;
  recipeCount: Array<Scalars['ID']>;
  ingredient: Ingredient;
  venue: Venue;
  wastage?: Maybe<Wastage>;
  brand?: Maybe<Brand>;
  size?: Maybe<Size>;
  supplier?: Maybe<Supplier>;
  required: Array<Required>;
};

export type Wastage = {
  __typename?: 'Wastage';
  id: Scalars['ID'];
  unitType: WastageUnitType;
  amount: Scalars['Float'];
};

/** The type of units to record. Can be percentage or grams. */
export enum WastageUnitType {
  Percentage = 'PERCENTAGE',
  Gram = 'GRAM',
}

export type Size = {
  __typename?: 'Size';
  id: Scalars['ID'];
  productCost: Scalars['Float'];
  productGrams: Scalars['Float'];
  unitAmount?: Maybe<Scalars['Float']>;
  unitType?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  addressId: Scalars['String'];
  address: Address;
  supplierGroupId?: Maybe<Scalars['ID']>;
  supplierGroup?: Maybe<SupplierGroup>;
  creatorId: Scalars['String'];
  creator: User;
  ingredients: Array<IngredientProduct>;
};

export type SupplierGroup = {
  __typename?: 'SupplierGroup';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  urlName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  addressId: Scalars['String'];
  address: Address;
  creatorId: Scalars['String'];
  creator: User;
  suppliers: Array<Supplier>;
};

export type Required = {
  __typename?: 'Required';
  id: Scalars['ID'];
  quantity: Scalars['Float'];
  unitAmount: Scalars['Float'];
  active: Scalars['Boolean'];
  ingredientProductId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  ingredientProduct: IngredientProduct;
};

export type StocktakeIngredient = {
  __typename?: 'StocktakeIngredient';
  id: Scalars['ID'];
  quantity: Scalars['Float'];
  order: Scalars['Float'];
  ingredientId: Scalars['String'];
  stocktakeSectionId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  ingredient: Ingredient;
  stocktakeSection: StocktakeSection;
};

export type StocktakeSection = {
  __typename?: 'StocktakeSection';
  id: Scalars['ID'];
  totalItems: Scalars['Float'];
  stocktakeId: Scalars['String'];
  sectionId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  stocktake: Stocktake;
  stocktakeIngredients: Array<StocktakeIngredient>;
  section: Section;
};

export type Stocktake = {
  __typename?: 'Stocktake';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  totalItems: Scalars['Float'];
  totalRequiredItems: Scalars['Float'];
  active: Scalars['Boolean'];
  status: StocktakeStatus;
  duplicatedId?: Maybe<Scalars['String']>;
  duplicatedName?: Maybe<Scalars['String']>;
  creatorId: Scalars['String'];
  venueId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  stocktakeSections: Array<StocktakeSection>;
  creator: User;
  venue: Venue;
};

/** Progress status of stocktake */
export enum StocktakeStatus {
  New = 'NEW',
  Editing = 'EDITING',
  Skipped = 'SKIPPED',
  Confirmed = 'CONFIRMED',
}

export type Section = {
  __typename?: 'Section';
  id: Scalars['ID'];
  displayName: Scalars['String'];
  totalRequiredItems: Scalars['Float'];
  creatorId: Scalars['String'];
  venueId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  stocktakeSections?: Maybe<Array<StocktakeSection>>;
  creator: User;
  venue: Venue;
};

export type RecipeProfitTrend = {
  __typename?: 'RecipeProfitTrend';
  id: Scalars['ID'];
  serves: Scalars['Float'];
  recipeRevenue: Scalars['Float'];
  recipeProfit: Scalars['Float'];
  recipeProfitIncreasePerServe: Scalars['Float'];
  ingredientCost: Scalars['Float'];
  staffCost: Scalars['Float'];
  totalCost: Scalars['Float'];
  /** Number of serves sold per week */
  weeklySalesPerServe: Scalars['Float'];
  recipeActiveSeconds: Scalars['Float'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  creatorId: Scalars['String'];
  creator: User;
  recipeId: Scalars['String'];
  recipe: Recipe;
};

export type VenueProfitTrend = {
  __typename?: 'VenueProfitTrend';
  id: Scalars['ID'];
  totalActiveSeconds: Scalars['Float'];
  recipeProfitIncreasePerYear: Scalars['Float'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  venueId: Scalars['String'];
  venue: Venue;
};

export type AddOn = {
  __typename?: 'AddOn';
  id: Scalars['ID'];
  userId: Scalars['String'];
  app: AddOnApp;
  endDate: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  user: User;
};

/** AddOn App Type */
export enum AddOnApp {
  Calculator = 'CALCULATOR',
}

export type GetBrandInput = {
  brandId: Scalars['String'];
};

export type IngredientsResponse = {
  __typename?: 'IngredientsResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  ingredients?: Maybe<Array<Ingredient>>;
};

export type GetIngredientsWithStateInput = {
  confirmationState: IngredientConfirmationState;
};

export type IngredientResponse = {
  __typename?: 'IngredientResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  ingredient?: Maybe<Ingredient>;
  ingredientProduct?: Maybe<IngredientProduct>;
};

export type GetIngredientInput = {
  ingredientProductId?: Maybe<Scalars['String']>;
  ingredientId: Scalars['String'];
  venueId: Scalars['String'];
};

export type IngredientRequestResponse = {
  __typename?: 'IngredientRequestResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  ingredientRequest?: Maybe<Array<IngredientRequest>>;
};

export type GetIngredientRequestInput = {
  ingredientId: Scalars['String'];
  venueId: Scalars['String'];
};

export type RecipeResponse = {
  __typename?: 'RecipeResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  recipe?: Maybe<Recipe>;
  recipeVersion?: Maybe<RecipeVersion>;
};

export type ExampleRecipeInput = {
  recipeUrl: Scalars['String'];
};

export type GetRecipeInput = {
  recipeId: Scalars['String'];
  venueId?: Maybe<Scalars['String']>;
};

export type RecipesResponse = {
  __typename?: 'RecipesResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  recipes: Array<Recipe>;
};

export type GetVenueRecipesInput = {
  venueId?: Maybe<Scalars['String']>;
};

export type GetRecipeCategoryRecipesInput = {
  /** Get recipes that belong to a recipe category */
  category: RecipeCategory;
};

/** The category of recipes that can be used as a template */
export enum RecipeCategory {
  Bakery = 'BAKERY',
  Cafe = 'CAFE',
  Catering = 'CATERING',
  Drink = 'DRINK',
  FoodTruck = 'FOOD_TRUCK',
  GlutenFree = 'GLUTEN_FREE',
  Manufacturing = 'MANUFACTURING',
  Restaurant = 'RESTAURANT',
  Retail = 'RETAIL',
  Savoury = 'SAVOURY',
  SubRecipe = 'SUB_RECIPE',
  Sweet = 'SWEET',
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN',
}

export type SupplierResponse = {
  __typename?: 'SupplierResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  suppliers?: Maybe<Array<Supplier>>;
};

export type GetSupplierInput = {
  supplierId: Scalars['String'];
};

export type SupplierGroupResponse = {
  __typename?: 'SupplierGroupResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  supplierGroup?: Maybe<Supplier>;
  supplierGroups?: Maybe<Array<Supplier>>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
};

export type InvoicesResponse = {
  __typename?: 'InvoicesResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<StripeInvoice>>;
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  id: Scalars['ID'];
  invoiceUrl?: Maybe<Scalars['String']>;
  createdUnixSec: Scalars['Float'];
};

export type VenueResponse = {
  __typename?: 'VenueResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  venue?: Maybe<Venue>;
  venues?: Maybe<Array<Venue>>;
};

export type UserVenueResponse = {
  __typename?: 'UserVenueResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  userVenue?: Maybe<UserVenue>;
  userVenues?: Maybe<Array<UserVenue>>;
};

export type GetVenueInput = {
  venueId?: Maybe<Scalars['String']>;
};

export type StocktakeResponse = {
  __typename?: 'StocktakeResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  stocktake?: Maybe<Stocktake>;
  stocktakes?: Maybe<Array<Stocktake>>;
};

export type GetStocktakeInput = {
  stocktakeId: Scalars['String'];
};

export type GetStocktakesInput = {
  venueId: Scalars['String'];
};

export type GetInventoryStocktakeInput = {
  venueId: Scalars['String'];
};

export type AddOnResponse = {
  __typename?: 'AddOnResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  addOns?: Maybe<Array<AddOn>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBrand: BrandResponse;
  updateBrand: BrandResponse;
  createIngredientProduct: IngredientProductResponse;
  updateIngredientProduct: IngredientProductResponse;
  createIngredient: IngredientResponse;
  updateIngredient: IngredientResponse;
  bulkCreateIngredients: BulkIngredientsResponse;
  deleteIngredient: CrudResponse;
  bulkVerifyIngredients: CrudResponse;
  ignoreIngredient: CrudResponse;
  createRecipe: RecipeResponse;
  /** Updates the values of a recipe if values provided. */
  updateOriginalRecipe: RecipeResponse;
  /** Updates the values of a recipe if values provided. */
  updateRecipe: RecipeResponse;
  /** Delete Recipe */
  deleteRecipe: CrudResponse;
  /** Delete Recipe Version */
  deleteRecipeVersion: DeleteRecipeVersionResponse;
  /** Selected Recipe Version */
  setSelectedRecipeVersion: RecipeResponse;
  /** Set original recipe version */
  setOriginalRecipeVersion: RecipeResponse;
  /** Update recipe category */
  updateRecipeCategory: CrudResponse;
  /** Create recipe from recipe template */
  createRecipeFromTemplate: RecipeResponse;
  createSupplier: SupplierResponse;
  updateSupplier: SupplierResponse;
  login: AuthResponse;
  signup: AuthResponse;
  createFeedbackUser: AuthResponse;
  signupWithRecipe: AuthRecipeResponse;
  signupWithRecipeTemplate: AuthRecipeResponse;
  updateUser: UserResponse;
  revokeRefreshTokenForUser: CrudResponse;
  logout: AuthResponse;
  sendPasswordReset: CrudResponse;
  resetPasswordWithToken: CrudResponse;
  confirmEmailWithToken: CrudResponse;
  resendEmailConfirmationWithToken: CrudResponse;
  resendEmailConfirmationWithAuth: CrudResponse;
  updateBillingAddress: UpdateBillingAddressResponse;
  savePaymentMethod: SavePaymentMethodResponse;
  clearPaymentMethod: CrudResponse;
  updateSubscription: UpdateSubscriptionResponse;
  createVenue: VenueResponse;
  updateVenue: VenueResponse;
  upateUserVenue: VenueResponse;
  setSelectedVenue: UserVenueResponse;
  deleteVenue: VenueResponse;
  signS3: SignS3Response;
  createStocktake: StocktakeResponse;
  updateStocktakeSection: StocktakeResponse;
  createStocktakeSection: StocktakeResponse;
  /** Confirm stocktake */
  confirmStocktake: CrudResponse;
  /** Confirm stocktake */
  deleteStocktakeSection: CrudResponse;
};

export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};

export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};

export type MutationCreateIngredientProductArgs = {
  input: CreateIngredientProductInput;
};

export type MutationUpdateIngredientProductArgs = {
  input: UpdateIngredientProductInput;
};

export type MutationCreateIngredientArgs = {
  input: CreateIngredientInput;
};

export type MutationUpdateIngredientArgs = {
  input: UpdateIngredientInput;
};

export type MutationBulkCreateIngredientsArgs = {
  input: BulkCreateIngredientsInput;
};

export type MutationDeleteIngredientArgs = {
  input: DeleteIngredientInput;
};

export type MutationBulkVerifyIngredientsArgs = {
  input: BulkVerifyIngredientsInput;
};

export type MutationIgnoreIngredientArgs = {
  input: IgnoreIngredientInput;
};

export type MutationCreateRecipeArgs = {
  input: CreateRecipeInput;
};

export type MutationUpdateOriginalRecipeArgs = {
  input: UpdateOriginalRecipeInput;
};

export type MutationUpdateRecipeArgs = {
  input: UpdateRecipeInput;
};

export type MutationDeleteRecipeArgs = {
  input: DeleteRecipeInput;
};

export type MutationDeleteRecipeVersionArgs = {
  input: DeleteRecipeVersionInput;
};

export type MutationSetSelectedRecipeVersionArgs = {
  input: SetSelectedRecipeVersionInput;
};

export type MutationSetOriginalRecipeVersionArgs = {
  input: SetOriginalRecipeVersionInput;
};

export type MutationUpdateRecipeCategoryArgs = {
  input: UpdateRecipeCategoryInput;
};

export type MutationCreateRecipeFromTemplateArgs = {
  input: CreateRecipeFromTemplateInput;
};

export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};

export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationSignupArgs = {
  input: SignupInput;
};

export type MutationCreateFeedbackUserArgs = {
  input: CreateFeedbackUserInput;
};

export type MutationSignupWithRecipeArgs = {
  input: SignupWithRecipeInput;
};

export type MutationSignupWithRecipeTemplateArgs = {
  input: SignupWithRecipeTemplateInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationRevokeRefreshTokenForUserArgs = {
  input: RevokeRefreshTokenInput;
};

export type MutationSendPasswordResetArgs = {
  input: SendPasswordResetInput;
};

export type MutationResetPasswordWithTokenArgs = {
  input: ResetPasswordWithTokenInput;
};

export type MutationConfirmEmailWithTokenArgs = {
  input: ConfirmEmailWithTokenInput;
};

export type MutationResendEmailConfirmationWithTokenArgs = {
  input: ConfirmEmailWithTokenInput;
};

export type MutationUpdateBillingAddressArgs = {
  input: UpdateBillingAddressInput;
};

export type MutationSavePaymentMethodArgs = {
  input: SavePaymentMethodInput;
};

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type MutationCreateVenueArgs = {
  input: CreateVenueInput;
};

export type MutationUpdateVenueArgs = {
  input: UpdateVenueInput;
};

export type MutationUpateUserVenueArgs = {
  input: UpdateUserVenueInput;
};

export type MutationSetSelectedVenueArgs = {
  input: SetSelectedVenueInput;
};

export type MutationDeleteVenueArgs = {
  input: DeleteVenueInput;
};

export type MutationSignS3Args = {
  input: SignS3Input;
};

export type MutationCreateStocktakeArgs = {
  input: CreateStocktakeInput;
};

export type MutationUpdateStocktakeSectionArgs = {
  input: UpdateStocktakeSectionInput;
};

export type MutationCreateStocktakeSectionArgs = {
  input: CreateStocktakeSectionInput;
};

export type MutationConfirmStocktakeArgs = {
  input: ConfirmStocktakeInput;
};

export type MutationDeleteStocktakeSectionArgs = {
  input: DeleteStocktakeSectionInput;
};

export type CreateBrandInput = {
  ingredientProductId?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website: Scalars['String'];
};

export type UpdateBrandInput = {
  brandId: Scalars['String'];
  ingredientProductId?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type IngredientProductResponse = {
  __typename?: 'IngredientProductResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  ingredientProduct?: Maybe<IngredientProduct>;
};

export type CreateIngredientProductInput = {
  ingredientId: Scalars['String'];
  venueId: Scalars['String'];
  brandId?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  sizeInput?: Maybe<CreateSizeInput>;
  wastageInput?: Maybe<CreateWastageInput>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type CreateSizeInput = {
  productCost: Scalars['Float'];
  productGrams: Scalars['Float'];
};

export type CreateWastageInput = {
  unitType: WastageUnitType;
  amount: Scalars['Float'];
};

export type UpdateIngredientProductInput = {
  id: Scalars['String'];
  ingredientId: Scalars['String'];
  venueId: Scalars['String'];
  sizeInput?: Maybe<UpdateSizeInput>;
  supplierId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  wastageInput?: Maybe<UpdateWastageInput>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type UpdateSizeInput = {
  productCost: Scalars['Float'];
  productGrams: Scalars['Float'];
};

export type UpdateWastageInput = {
  id?: Maybe<Scalars['String']>;
  unitType: WastageUnitType;
  amount: Scalars['Float'];
};

export type CreateIngredientInput = {
  venueId: Scalars['String'];
  displayName: Scalars['String'];
  liquid?: Maybe<Scalars['Boolean']>;
  metricInput: Array<CreateMetricInput>;
  wastageInput?: Maybe<CreateWastageInput>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type CreateMetricInput = {
  type: MetricType;
  grams: Scalars['Float'];
};

/** MetricType */
export enum MetricType {
  Teaspoon = 'TEASPOON',
  Tablespoon = 'TABLESPOON',
  Cup = 'CUP',
  Slice = 'SLICE',
  Whole = 'WHOLE',
}

export type UpdateIngredientInput = {
  ingredientId: Scalars['String'];
  venueId: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  liquid?: Maybe<Scalars['Boolean']>;
  metricInput: Array<UpdateMetricInput>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type UpdateMetricInput = {
  id?: Maybe<Scalars['String']>;
  type: MetricType;
  grams?: Maybe<Scalars['Float']>;
};

export type BulkIngredientsResponse = {
  __typename?: 'BulkIngredientsResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  data?: Maybe<BulkIngredientsDataResponse>;
};

export type BulkIngredientsDataResponse = {
  __typename?: 'BulkIngredientsDataResponse';
  created: Array<Scalars['String']>;
  updated: Array<Scalars['String']>;
  skipped: Array<Scalars['String']>;
};

export type BulkCreateIngredientsInput = {
  venueId: Scalars['String'];
  ingredients: Array<CreateBulkIngredientInput>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type CreateBulkIngredientInput = {
  displayName: Scalars['String'];
  category: Scalars['String'];
  liquid?: Maybe<Scalars['String']>;
  metricCupGrams?: Maybe<Scalars['String']>;
  metricWholeGrams?: Maybe<Scalars['String']>;
  metricSliceGrams?: Maybe<Scalars['String']>;
  productCost: Scalars['String'];
  productGrams: Scalars['String'];
  supplierGroupDisplayName: Scalars['String'];
  supplierLocationDisplayName: Scalars['String'];
  supplierWebsite: Scalars['String'];
  brandDisplayName?: Maybe<Scalars['String']>;
  brandWebsite?: Maybe<Scalars['String']>;
  wastePercentageAmount?: Maybe<Scalars['String']>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type CrudResponse = {
  __typename?: 'CrudResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type DeleteIngredientInput = {
  id: Scalars['String'];
  ingredientPartialDelete?: Maybe<Scalars['Boolean']>;
  ingredientProductInput?: Maybe<DeleteIngredientProductInput>;
  venueId: Scalars['String'];
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type DeleteIngredientProductInput = {
  id: Scalars['String'];
};

export type BulkVerifyIngredientsInput = {
  ingredientIds: Array<Scalars['String']>;
};

export type IgnoreIngredientInput = {
  id: Scalars['String'];
};

export type CreateRecipeInput = {
  id: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  weeklySalesPerServe?: Maybe<Scalars['Float']>;
  salesPricePerServe?: Maybe<Scalars['Float']>;
  recipeRevenue?: Maybe<Scalars['Float']>;
  recipeProfit?: Maybe<Scalars['Float']>;
  serves?: Maybe<Scalars['Float']>;
  totalGrams?: Maybe<Scalars['Float']>;
  totalTime?: Maybe<Scalars['Float']>;
  foodCostPercentage?: Maybe<Scalars['Float']>;
  staffCost?: Maybe<Scalars['Float']>;
  ingredientCost?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  recipeVersion: CreateRecipeVersionInput;
  venueId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
  recipeActiveSeconds?: Maybe<Scalars['Float']>;
};

export type CreateRecipeVersionInput = {
  id: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  serves: Scalars['Float'];
  recipeWastage?: Maybe<Scalars['Float']>;
  selected?: Maybe<Scalars['Boolean']>;
  totalStaffTime: Scalars['Float'];
  totalTime: Scalars['Float'];
  totalGrams: Scalars['Float'];
  recipeId?: Maybe<Scalars['String']>;
  /** The items for the recipe, each item type in the array must be explicit (so we can tell them apart when adding them to the database). */
  items: Array<CreateItemInput>;
  active: Scalars['Boolean'];
  original?: Maybe<Scalars['Boolean']>;
};

export type CreateItemInput = {
  /** The type of recipe item to be added. Can only be either an ingredient, a recipe as ingredient or a recipe time item */
  itemType: ItemType;
  content?: Maybe<RecipeContentInput>;
};

export enum ItemType {
  Time = 'TIME',
  Ingredient = 'INGREDIENT',
  Recipe = 'RECIPE',
  Delete = 'DELETE',
}

export type RecipeContentInput = {
  recipeItemId?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  staffTime?: Maybe<Scalars['Boolean']>;
  timeUnit?: Maybe<TimeUnitType>;
  recipeUnit?: Maybe<RecipeMetricUnit>;
  ingredientUnit?: Maybe<IngredientMetricUnit>;
  deleteType?: Maybe<ItemDeleteType>;
  newIngredientName?: Maybe<Scalars['String']>;
};

export enum ItemDeleteType {
  Ingredient = 'INGREDIENT',
  Time = 'TIME',
  Recipe = 'RECIPE',
}

export type UpdateOriginalRecipeInput = {
  id: Scalars['String'];
  venueId: Scalars['String'];
  originalRecipeProfit?: Maybe<Scalars['Float']>;
  originalRecipeServes?: Maybe<Scalars['Float']>;
};

export type UpdateRecipeInput = {
  id: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  weeklySalesPerServe?: Maybe<Scalars['Float']>;
  salesPricePerServe?: Maybe<Scalars['Float']>;
  recipeRevenue: Scalars['Float'];
  originalRecipeProfit?: Maybe<Scalars['Float']>;
  originalRecipeServes?: Maybe<Scalars['Float']>;
  recipeProfit: Scalars['Float'];
  recipeProfitIncreasePerServe: Scalars['Float'];
  highestRecipeProfitIncreasePerServe: Scalars['Float'];
  serves?: Maybe<Scalars['Float']>;
  totalGrams?: Maybe<Scalars['Float']>;
  totalTime?: Maybe<Scalars['Float']>;
  foodCostPercentage?: Maybe<Scalars['Float']>;
  staffCost?: Maybe<Scalars['Float']>;
  ingredientCost?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  recipeVersion?: Maybe<UpdateRecipeVersionInput>;
  venueId: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  recipeIngredientIdArray?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<RecipeCategory>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
  recipeActiveSeconds?: Maybe<Scalars['Float']>;
};

export type UpdateRecipeVersionInput = {
  id?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  serves?: Maybe<Scalars['Float']>;
  selected?: Maybe<Scalars['Boolean']>;
  totalStaffTime: Scalars['Float'];
  totalTime: Scalars['Float'];
  totalGrams: Scalars['Float'];
  recipeWastage?: Maybe<Scalars['Float']>;
  recipeId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  original?: Maybe<Scalars['Boolean']>;
  isScaled?: Maybe<Scalars['Boolean']>;
  /** The items for the recipe, each item type in the array must be explicit (so we can tell them apart when adding them to the database). */
  items: Array<CreateItemInput>;
};

export type DeleteRecipeInput = {
  id: Scalars['String'];
  venueId: Scalars['String'];
};

export type DeleteRecipeVersionResponse = {
  __typename?: 'DeleteRecipeVersionResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  selectedRecipeVersion?: Maybe<Scalars['String']>;
  recipe?: Maybe<Recipe>;
};

export type DeleteRecipeVersionInput = {
  recipeId: Scalars['String'];
  recipeVersionId: Scalars['String'];
  venueId: Scalars['String'];
};

export type SetSelectedRecipeVersionInput = {
  recipeId: Scalars['String'];
  recipeVersionId: Scalars['String'];
};

export type SetOriginalRecipeVersionInput = {
  recipeId: Scalars['String'];
  recipeVersionId: Scalars['String'];
};

export type UpdateRecipeCategoryInput = {
  id: Scalars['String'];
  category?: Maybe<RecipeCategory>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type CreateRecipeFromTemplateInput = {
  recipeId: Scalars['String'];
  venueId: Scalars['String'];
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type CreateSupplierInput = {
  ingredientProductId?: Maybe<Scalars['String']>;
  supplierGroupId?: Maybe<Scalars['String']>;
  supplierGroupName: Scalars['String'];
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  supplierAddress?: Maybe<UpdateAddressInput>;
};

export type UpdateAddressInput = {
  addressId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  shire?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryShort?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLookup?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type UpdateSupplierInput = {
  supplierId: Scalars['String'];
  supplierGroupId?: Maybe<Scalars['String']>;
  ingredientProductId?: Maybe<Scalars['String']>;
  supplierGroupName: Scalars['String'];
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  supplierAddress?: Maybe<UpdateAddressInput>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignupInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  position: PositionType;
  accountType?: Maybe<AccountType>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
};

export type CreateFeedbackUserInput = {
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type AuthRecipeResponse = {
  __typename?: 'AuthRecipeResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  recipe?: Maybe<Recipe>;
};

export type SignupWithRecipeInput = {
  id: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  weeklySalesPerServe?: Maybe<Scalars['Float']>;
  salesPricePerServe?: Maybe<Scalars['Float']>;
  recipeRevenue?: Maybe<Scalars['Float']>;
  recipeProfit?: Maybe<Scalars['Float']>;
  serves?: Maybe<Scalars['Float']>;
  totalGrams?: Maybe<Scalars['Float']>;
  totalTime?: Maybe<Scalars['Float']>;
  foodCostPercentage?: Maybe<Scalars['Float']>;
  staffCost?: Maybe<Scalars['Float']>;
  ingredientCost?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  recipeVersion: CreateRecipeVersionInput;
  venueId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
  recipeActiveSeconds?: Maybe<Scalars['Float']>;
};

export type SignupWithRecipeTemplateInput = {
  recipeId: Scalars['String'];
};

export type UpdateUserInput = {
  venueId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  currentPassword?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  position?: Maybe<PositionType>;
  accountType?: Maybe<AccountType>;
  address?: Maybe<UpdateAddressInput>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type RevokeRefreshTokenInput = {
  userId: Scalars['String'];
};

export type SendPasswordResetInput = {
  email: Scalars['String'];
};

export type ResetPasswordWithTokenInput = {
  passwordResetToken: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ConfirmEmailWithTokenInput = {
  confirmEmailToken: Scalars['String'];
};

export type UpdateBillingAddressResponse = {
  __typename?: 'UpdateBillingAddressResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  billingAddress?: Maybe<Address>;
};

export type UpdateBillingAddressInput = {
  paymentMethodId: Scalars['String'];
  address: UpdateAddressInput;
  venueId: Scalars['String'];
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type SavePaymentMethodResponse = {
  __typename?: 'SavePaymentMethodResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  billingAddress?: Maybe<Address>;
};

export type SavePaymentMethodInput = {
  paymentMethodId: Scalars['String'];
  address: UpdateAddressInput;
  venueId: Scalars['String'];
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type UpdateSubscriptionResponse = {
  __typename?: 'UpdateSubscriptionResponse';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type UpdateSubscriptionInput = {
  accountType: AccountType;
  billingCycle: BillingCycle;
  addOns?: Maybe<Array<AddOnApp>>;
  venueId: Scalars['String'];
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type CreateVenueInput = {
  previousVenueId: Scalars['String'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  type: VenueType;
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  weeksOpen?: Maybe<Scalars['Float']>;
  weeksOpenUnit?: Maybe<Scalars['String']>;
  prepTime?: Maybe<Scalars['Float']>;
  prepTimeUnit?: Maybe<Scalars['String']>;
  avgWastagePercentage?: Maybe<Scalars['Float']>;
  wastageUnit?: Maybe<Scalars['String']>;
  personal?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<Scalars['String']>;
  targetFoodMargin?: Maybe<Scalars['Float']>;
  recommendedPrice: RecommendedPriceType;
  venueCost: CreateVenueCostInput;
  venueAddressId?: Maybe<Scalars['String']>;
  venueAddress: CreateAddressInput;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

/** The type of units to record. Can be percentage or grams. */
export enum VenueType {
  Bar = 'BAR',
  Bakery = 'BAKERY',
  Bbq = 'BBQ',
  Cafe = 'CAFE',
  Catering = 'CATERING',
  FoodTruck = 'FOOD_TRUCK',
  Manufacturing = 'MANUFACTURING',
  Restaurant = 'RESTAURANT',
  Retail = 'RETAIL',
  Personal = 'PERSONAL',
  RecipeRevenue = 'RECIPE_REVENUE',
  Sweets = 'SWEETS',
}

/** How Recommended Profit Is Calculated */
export enum RecommendedPriceType {
  FoodCost = 'FOOD_COST',
  RecipeCost = 'RECIPE_COST',
  TargetMargin = 'TARGET_MARGIN',
}

export type CreateVenueCostInput = {
  id?: Maybe<Scalars['String']>;
  chefCost?: Maybe<Scalars['Float']>;
  chefCostUnit?: Maybe<Scalars['String']>;
  rentCost?: Maybe<Scalars['Float']>;
  rentCostUnit?: Maybe<Scalars['String']>;
  waterCost?: Maybe<Scalars['Float']>;
  waterCostUnit?: Maybe<Scalars['String']>;
  powerCost?: Maybe<Scalars['Float']>;
  powerCostUnit?: Maybe<Scalars['String']>;
  insuranceCost?: Maybe<Scalars['Float']>;
  insuranceCostUnit?: Maybe<Scalars['String']>;
  councilCost?: Maybe<Scalars['Float']>;
  councilCostUnit?: Maybe<Scalars['String']>;
  foodWastePercentage?: Maybe<Scalars['Float']>;
};

export type CreateAddressInput = {
  location?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  road?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  shire?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryShort?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLookup?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type UpdateVenueInput = {
  venueId: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<VenueType>;
  phone?: Maybe<Scalars['String']>;
  internationalPhone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  weeksOpen?: Maybe<Scalars['Float']>;
  weeksOpenUnit?: Maybe<Scalars['String']>;
  prepTime?: Maybe<Scalars['Float']>;
  prepTimeUnit?: Maybe<Scalars['String']>;
  avgWastagePercentage?: Maybe<Scalars['Float']>;
  wastageUnit?: Maybe<Scalars['String']>;
  personal?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  venueCost?: Maybe<UpdateVenueCostInput>;
  venueAddressId?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<UpdateAddressInput>;
  targetFoodMargin?: Maybe<Scalars['Float']>;
  recommendedPrice: RecommendedPriceType;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type UpdateVenueCostInput = {
  id?: Maybe<Scalars['String']>;
  chefCost?: Maybe<Scalars['Float']>;
  chefCostUnit?: Maybe<Scalars['String']>;
  rentCost?: Maybe<Scalars['Float']>;
  rentCostUnit?: Maybe<Scalars['String']>;
  waterCost?: Maybe<Scalars['Float']>;
  waterCostUnit?: Maybe<Scalars['String']>;
  powerCost?: Maybe<Scalars['Float']>;
  powerCostUnit?: Maybe<Scalars['String']>;
  insuranceCost?: Maybe<Scalars['Float']>;
  insuranceCostUnit?: Maybe<Scalars['String']>;
  councilCost?: Maybe<Scalars['Float']>;
  councilCostUnit?: Maybe<Scalars['String']>;
  foodWastePercentage?: Maybe<Scalars['Float']>;
};

export type UpdateUserVenueInput = {
  status: Scalars['String'];
  userId: Scalars['String'];
  venueId: Scalars['String'];
};

export type SetSelectedVenueInput = {
  venueId: Scalars['String'];
  previousVenueId: Scalars['String'];
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type DeleteVenueInput = {
  venueId: Scalars['String'];
};

export type SignS3Response = {
  __typename?: 'SignS3Response';
  successful: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  signedRequest: Scalars['String'];
  url: Scalars['String'];
};

export type SignS3Input = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  currentImageKey?: Maybe<Scalars['String']>;
};

export type CreateStocktakeInput = {
  displayName: Scalars['String'];
  venueId: Scalars['String'];
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type UpdateStocktakeSectionInput = {
  stocktakeId: Scalars['String'];
  stocktakeSectionId: Scalars['String'];
  venueId: Scalars['String'];
  /** Stocktake Section */
  section: SectionInput;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type SectionInput = {
  sectionId?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  /** Stocktake Ingredients */
  ingredients: Array<StocktakeIngredientInput>;
};

export type StocktakeIngredientInput = {
  stocktakeItemId?: Maybe<Scalars['String']>;
  ingredientId: Scalars['String'];
  quantity: Scalars['Float'];
  order: Scalars['Float'];
  unitAmount: Scalars['Float'];
  required: Scalars['Float'];
  unitType: Scalars['String'];
};

export type CreateStocktakeSectionInput = {
  stocktakeId: Scalars['String'];
  venueId: Scalars['String'];
  /** Stocktake Section */
  section: SectionInput;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type ConfirmStocktakeInput = {
  stocktakeId: Scalars['String'];
  venueId: Scalars['String'];
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type DeleteStocktakeSectionInput = {
  stocktakeId: Scalars['String'];
  venueId: Scalars['String'];
  updatedStocktakeTotalRequiredItems: Scalars['Float'];
  stocktakeSectionId: Scalars['String'];
  stocktakeIngredientIds: Array<Scalars['String']>;
  totalActiveSeconds?: Maybe<Scalars['Float']>;
};

export type ConfirmEmailWithTokenMutationVariables = Exact<{
  input: ConfirmEmailWithTokenInput;
}>;

export type ConfirmEmailWithTokenMutation = { __typename?: 'Mutation' } & {
  confirmEmailWithToken: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error'
  >;
};

export type CreateFeedbackUserMutationVariables = Exact<{
  input: CreateFeedbackUserInput;
}>;

export type CreateFeedbackUserMutation = { __typename?: 'Mutation' } & {
  createFeedbackUser: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'successful' | 'error' | 'accessToken'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'firstName'
          | 'email'
          | 'lastName'
          | 'phone'
          | 'internationalPhone'
          | 'position'
          | 'accountType'
          | 'billingCycle'
          | 'admin'
          | 'isEmailConfirmed'
          | 'createdAt'
        >
      >;
    };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  login: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'successful' | 'error'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'email'
          | 'phone'
          | 'internationalPhone'
          | 'firstName'
          | 'lastName'
          | 'position'
          | 'accountType'
          | 'billingCycle'
          | 'admin'
          | 'isEmailConfirmed'
          | 'createdAt'
        > & {
            address?: Maybe<
              { __typename?: 'Address' } & Pick<Address, 'addressLookup'>
            >;
            billingAddress?: Maybe<
              { __typename?: 'Address' } & Pick<Address, 'addressLookup'>
            >;
            venuesSharedWithYou: Array<
              { __typename?: 'UserVenue' } & Pick<
                UserVenue,
                'status' | 'selected' | 'totalActiveSeconds'
              > & {
                  venue: { __typename?: 'Venue' } & Pick<
                    Venue,
                    | 'id'
                    | 'displayName'
                    | 'weeksOpen'
                    | 'website'
                    | 'email'
                    | 'type'
                    | 'phone'
                    | 'internationalPhone'
                    | 'active'
                    | 'creatorId'
                    | 'recipeProfitIncreasePerYear'
                  > & {
                      address?: Maybe<
                        { __typename?: 'Address' } & Pick<
                          Address,
                          | 'id'
                          | 'location'
                          | 'unit'
                          | 'streetNumber'
                          | 'road'
                          | 'city'
                          | 'shire'
                          | 'state'
                          | 'country'
                          | 'postcode'
                          | 'addressLine1'
                          | 'addressLine2'
                          | 'addressLookup'
                          | 'lat'
                          | 'lng'
                        >
                      >;
                      venueCost: { __typename?: 'VenueCost' } & Pick<
                        VenueCost,
                        | 'id'
                        | 'chefCost'
                        | 'chefCostUnit'
                        | 'rentCost'
                        | 'rentCostUnit'
                      >;
                    };
                }
            >;
            addOns: Array<{ __typename?: 'AddOn' } & Pick<AddOn, 'app'>>;
          }
      >;
    };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation' } & {
  logout: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'successful' | 'error'
  >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserResponse' } & Pick<
      UserResponse,
      'successful' | 'error'
    > & {
        user?: Maybe<
          { __typename?: 'User' } & Pick<
            User,
            | 'id'
            | 'email'
            | 'phone'
            | 'internationalPhone'
            | 'firstName'
            | 'lastName'
            | 'position'
            | 'accountType'
            | 'billingCycle'
            | 'admin'
            | 'isEmailConfirmed'
            | 'createdAt'
            | 'updatedAt'
          > & {
              address?: Maybe<
                { __typename?: 'Address' } & Pick<
                  Address,
                  | 'id'
                  | 'location'
                  | 'unit'
                  | 'streetNumber'
                  | 'road'
                  | 'city'
                  | 'shire'
                  | 'state'
                  | 'country'
                  | 'countryShort'
                  | 'postcode'
                  | 'addressLine1'
                  | 'addressLine2'
                  | 'addressLookup'
                  | 'lat'
                  | 'lng'
                >
              >;
              billingAddress?: Maybe<
                { __typename?: 'Address' } & Pick<
                  Address,
                  | 'id'
                  | 'location'
                  | 'unit'
                  | 'streetNumber'
                  | 'road'
                  | 'city'
                  | 'shire'
                  | 'state'
                  | 'country'
                  | 'countryShort'
                  | 'postcode'
                  | 'addressLine1'
                  | 'addressLine2'
                  | 'addressLookup'
                  | 'lat'
                  | 'lng'
                >
              >;
              venuesSharedWithYou: Array<
                { __typename?: 'UserVenue' } & Pick<
                  UserVenue,
                  | 'id'
                  | 'status'
                  | 'venueId'
                  | 'userId'
                  | 'selected'
                  | 'totalActiveSeconds'
                > & {
                    venue: { __typename?: 'Venue' } & Pick<
                      Venue,
                      | 'id'
                      | 'displayName'
                      | 'email'
                      | 'type'
                      | 'phone'
                      | 'internationalPhone'
                      | 'website'
                      | 'weeksOpen'
                      | 'weeksOpenUnit'
                      | 'prepTime'
                      | 'prepTimeUnit'
                      | 'avgWastagePercentage'
                      | 'targetFoodMargin'
                      | 'recommendedPrice'
                      | 'wastageUnit'
                      | 'personal'
                      | 'active'
                      | 'creatorId'
                      | 'createdAt'
                      | 'updatedAt'
                      | 'recipeProfitIncreasePerYear'
                    > & {
                        address?: Maybe<
                          { __typename?: 'Address' } & Pick<
                            Address,
                            | 'id'
                            | 'location'
                            | 'unit'
                            | 'streetNumber'
                            | 'road'
                            | 'city'
                            | 'shire'
                            | 'state'
                            | 'country'
                            | 'postcode'
                            | 'addressLine1'
                            | 'addressLine2'
                            | 'addressLookup'
                            | 'lat'
                            | 'lng'
                          >
                        >;
                        venueCost: { __typename?: 'VenueCost' } & Pick<
                          VenueCost,
                          | 'id'
                          | 'chefCost'
                          | 'chefCostUnit'
                          | 'councilCost'
                          | 'councilCostUnit'
                          | 'foodWastePercentage'
                          | 'insuranceCost'
                          | 'insuranceCostUnit'
                          | 'powerCost'
                          | 'powerCostUnit'
                          | 'rentCost'
                          | 'rentCostUnit'
                          | 'waterCost'
                          | 'waterCostUnit'
                        >;
                        recipes: Array<
                          { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            | 'id'
                            | 'numScaledRecipeVersions'
                            | 'displayName'
                            | 'recipeProfit'
                            | 'recipeProfitIncreasePerServe'
                            | 'highestRecipeProfitIncreasePerServe'
                            | 'recipeRevenue'
                            | 'salesPricePerServe'
                            | 'weeklySalesPerServe'
                            | 'foodCostPercentage'
                            | 'active'
                          >
                        >;
                      };
                  }
              >;
              addOns: Array<
                { __typename?: 'AddOn' } & Pick<AddOn, 'app' | 'endDate'>
              >;
            }
        >;
      }
  >;
};

export type ResendEmailConfirmationWithAuthMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendEmailConfirmationWithAuthMutation = {
  __typename?: 'Mutation';
} & {
  resendEmailConfirmationWithAuth: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error'
  >;
};

export type ResetPasswordWithTokenMutationVariables = Exact<{
  input: ResetPasswordWithTokenInput;
}>;

export type ResetPasswordWithTokenMutation = { __typename?: 'Mutation' } & {
  resetPasswordWithToken: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error'
  >;
};

export type SendPasswordResetMutationVariables = Exact<{
  input: SendPasswordResetInput;
}>;

export type SendPasswordResetMutation = { __typename?: 'Mutation' } & {
  sendPasswordReset: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error'
  >;
};

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;

export type SignupMutation = { __typename?: 'Mutation' } & {
  signup: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'successful' | 'error' | 'accessToken'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'firstName'
          | 'email'
          | 'lastName'
          | 'phone'
          | 'internationalPhone'
          | 'position'
          | 'accountType'
          | 'billingCycle'
          | 'admin'
          | 'isEmailConfirmed'
          | 'createdAt'
        > & {
            venuesSharedWithYou: Array<
              { __typename?: 'UserVenue' } & Pick<
                UserVenue,
                'status' | 'selected' | 'totalActiveSeconds'
              > & {
                  venue: { __typename?: 'Venue' } & Pick<
                    Venue,
                    | 'id'
                    | 'displayName'
                    | 'weeksOpen'
                    | 'website'
                    | 'email'
                    | 'type'
                    | 'phone'
                    | 'internationalPhone'
                    | 'active'
                    | 'creatorId'
                    | 'recipeProfitIncreasePerYear'
                  > & {
                      address?: Maybe<
                        { __typename?: 'Address' } & Pick<
                          Address,
                          | 'id'
                          | 'location'
                          | 'unit'
                          | 'streetNumber'
                          | 'road'
                          | 'city'
                          | 'shire'
                          | 'state'
                          | 'country'
                          | 'countryShort'
                          | 'postcode'
                          | 'addressLine1'
                          | 'addressLine2'
                          | 'addressLookup'
                          | 'lat'
                          | 'lng'
                        >
                      >;
                      venueCost: { __typename?: 'VenueCost' } & Pick<
                        VenueCost,
                        'chefCost' | 'chefCostUnit' | 'rentCost'
                      >;
                    };
                }
            >;
            addOns: Array<{ __typename?: 'AddOn' } & Pick<AddOn, 'app'>>;
          }
      >;
    };
};

export type SignupWithRecipeMutationVariables = Exact<{
  input: SignupWithRecipeInput;
}>;

export type SignupWithRecipeMutation = { __typename?: 'Mutation' } & {
  signupWithRecipe: { __typename?: 'AuthRecipeResponse' } & Pick<
    AuthRecipeResponse,
    'successful' | 'error' | 'accessToken'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'firstName'
          | 'email'
          | 'lastName'
          | 'phone'
          | 'internationalPhone'
          | 'position'
          | 'isEmailConfirmed'
          | 'admin'
          | 'createdAt'
        > & {
            venuesSharedWithYou: Array<
              { __typename?: 'UserVenue' } & Pick<
                UserVenue,
                'status' | 'selected' | 'totalActiveSeconds'
              > & {
                  venue: { __typename?: 'Venue' } & Pick<
                    Venue,
                    | 'id'
                    | 'displayName'
                    | 'weeksOpen'
                    | 'website'
                    | 'email'
                    | 'type'
                    | 'phone'
                    | 'internationalPhone'
                    | 'active'
                    | 'creatorId'
                    | 'recipeProfitIncreasePerYear'
                  > & {
                      address?: Maybe<
                        { __typename?: 'Address' } & Pick<
                          Address,
                          | 'id'
                          | 'location'
                          | 'unit'
                          | 'streetNumber'
                          | 'road'
                          | 'city'
                          | 'shire'
                          | 'state'
                          | 'country'
                          | 'countryShort'
                          | 'postcode'
                          | 'addressLine1'
                          | 'addressLine2'
                          | 'addressLookup'
                          | 'lat'
                          | 'lng'
                        >
                      >;
                      venueCost: { __typename?: 'VenueCost' } & Pick<
                        VenueCost,
                        'chefCost' | 'chefCostUnit' | 'rentCost'
                      >;
                    };
                }
            >;
            addOns: Array<{ __typename?: 'AddOn' } & Pick<AddOn, 'app'>>;
          }
      >;
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'venueId'
          | 'active'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                'id' | 'displayName' | 'serves' | 'selected' | 'active'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type SignupWithRecipeTemplateMutationVariables = Exact<{
  input: SignupWithRecipeTemplateInput;
}>;

export type SignupWithRecipeTemplateMutation = { __typename?: 'Mutation' } & {
  signupWithRecipeTemplate: { __typename?: 'AuthRecipeResponse' } & Pick<
    AuthRecipeResponse,
    'successful' | 'error' | 'accessToken'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'firstName'
          | 'email'
          | 'lastName'
          | 'phone'
          | 'internationalPhone'
          | 'position'
          | 'isEmailConfirmed'
          | 'admin'
          | 'createdAt'
        > & {
            venuesSharedWithYou: Array<
              { __typename?: 'UserVenue' } & Pick<
                UserVenue,
                'status' | 'selected' | 'totalActiveSeconds'
              > & {
                  venue: { __typename?: 'Venue' } & Pick<
                    Venue,
                    | 'id'
                    | 'displayName'
                    | 'weeksOpen'
                    | 'website'
                    | 'email'
                    | 'type'
                    | 'phone'
                    | 'internationalPhone'
                    | 'active'
                    | 'creatorId'
                    | 'recipeProfitIncreasePerYear'
                  > & {
                      address?: Maybe<
                        { __typename?: 'Address' } & Pick<
                          Address,
                          | 'id'
                          | 'location'
                          | 'unit'
                          | 'streetNumber'
                          | 'road'
                          | 'city'
                          | 'shire'
                          | 'state'
                          | 'country'
                          | 'countryShort'
                          | 'postcode'
                          | 'addressLine1'
                          | 'addressLine2'
                          | 'addressLookup'
                          | 'lat'
                          | 'lng'
                        >
                      >;
                      venueCost: { __typename?: 'VenueCost' } & Pick<
                        VenueCost,
                        'chefCost' | 'chefCostUnit' | 'rentCost'
                      >;
                    };
                }
            >;
            addOns: Array<{ __typename?: 'AddOn' } & Pick<AddOn, 'app'>>;
          }
      >;
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'venueId'
          | 'active'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                'id' | 'displayName' | 'serves' | 'selected' | 'active'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type BrandQueryVariables = Exact<{
  input: GetBrandInput;
}>;

export type BrandQuery = { __typename?: 'Query' } & {
  brand: { __typename?: 'BrandResponse' } & Pick<
    BrandResponse,
    'successful'
  > & {
      brand?: Maybe<
        { __typename?: 'Brand' } & Pick<
          Brand,
          'id' | 'displayName' | 'email' | 'website' | 'phone' | 'creatorId'
        >
      >;
    };
};

export type BrandsQueryVariables = Exact<{ [key: string]: never }>;

export type BrandsQuery = { __typename?: 'Query' } & {
  brands: { __typename?: 'BrandResponse' } & Pick<
    BrandResponse,
    'successful'
  > & {
      brands?: Maybe<
        Array<
          { __typename?: 'Brand' } & Pick<
            Brand,
            'id' | 'displayName' | 'website' | 'email' | 'creatorId' | 'phone'
          >
        >
      >;
    };
};

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;

export type CreateBrandMutation = { __typename?: 'Mutation' } & {
  createBrand: { __typename?: 'BrandResponse' } & Pick<
    BrandResponse,
    'successful'
  > & {
      brand?: Maybe<
        { __typename?: 'Brand' } & Pick<
          Brand,
          'id' | 'displayName' | 'website' | 'email' | 'phone' | 'creatorId'
        >
      >;
    };
};

export type UpdateBrandMutationVariables = Exact<{
  input: UpdateBrandInput;
}>;

export type UpdateBrandMutation = { __typename?: 'Mutation' } & {
  updateBrand: { __typename?: 'BrandResponse' } & Pick<
    BrandResponse,
    'successful' | 'error'
  > & {
      brand?: Maybe<
        { __typename?: 'Brand' } & Pick<
          Brand,
          'id' | 'displayName' | 'website' | 'email' | 'phone' | 'creatorId'
        >
      >;
    };
};

export type BulkCreateIngredientsMutationVariables = Exact<{
  input: BulkCreateIngredientsInput;
}>;

export type BulkCreateIngredientsMutation = { __typename?: 'Mutation' } & {
  bulkCreateIngredients: { __typename?: 'BulkIngredientsResponse' } & Pick<
    BulkIngredientsResponse,
    'successful' | 'error'
  > & {
      data?: Maybe<
        { __typename?: 'BulkIngredientsDataResponse' } & Pick<
          BulkIngredientsDataResponse,
          'created' | 'updated' | 'skipped'
        >
      >;
    };
};

export type BulkVerifyIngredientsMutationVariables = Exact<{
  input: BulkVerifyIngredientsInput;
}>;

export type BulkVerifyIngredientsMutation = { __typename?: 'Mutation' } & {
  bulkVerifyIngredients: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error'
  >;
};

export type CreateIngredientMutationVariables = Exact<{
  input: CreateIngredientInput;
}>;

export type CreateIngredientMutation = { __typename?: 'Mutation' } & {
  createIngredient: { __typename?: 'IngredientResponse' } & Pick<
    IngredientResponse,
    'successful' | 'error'
  > & {
      ingredient?: Maybe<
        { __typename?: 'Ingredient' } & Pick<
          Ingredient,
          | 'id'
          | 'displayName'
          | 'liquid'
          | 'averageCost100g'
          | 'averageWastagePercentage'
          | 'creatorId'
          | 'confirmationState'
        > & {
            metrics: Array<
              { __typename?: 'Metric' } & Pick<Metric, 'type' | 'grams'>
            >;
          }
      >;
      ingredientProduct?: Maybe<
        { __typename?: 'IngredientProduct' } & Pick<
          IngredientProduct,
          'id' | 'brandId' | 'supplierId' | 'sizeId' | 'wastageId'
        > & {
            ingredient: { __typename?: 'Ingredient' } & Pick<
              Ingredient,
              'id' | 'displayName'
            >;
            brand?: Maybe<
              { __typename?: 'Brand' } & Pick<
                Brand,
                'id' | 'displayName' | 'website' | 'email' | 'phone'
              >
            >;
            supplier?: Maybe<
              { __typename?: 'Supplier' } & Pick<
                Supplier,
                | 'id'
                | 'creatorId'
                | 'displayName'
                | 'website'
                | 'email'
                | 'phone'
              > & {
                  address: { __typename?: 'Address' } & Pick<
                    Address,
                    | 'id'
                    | 'location'
                    | 'unit'
                    | 'streetNumber'
                    | 'road'
                    | 'city'
                    | 'shire'
                    | 'state'
                    | 'country'
                    | 'countryShort'
                    | 'postcode'
                    | 'addressLine1'
                    | 'addressLine2'
                    | 'addressLookup'
                    | 'lat'
                    | 'lng'
                  >;
                }
            >;
            size?: Maybe<
              { __typename?: 'Size' } & Pick<
                Size,
                'id' | 'productCost' | 'productGrams'
              >
            >;
            wastage?: Maybe<
              { __typename?: 'Wastage' } & Pick<
                Wastage,
                'id' | 'unitType' | 'amount'
              >
            >;
          }
      >;
    };
};

export type CreateIngredientProductMutationVariables = Exact<{
  input: CreateIngredientProductInput;
}>;

export type CreateIngredientProductMutation = { __typename?: 'Mutation' } & {
  createIngredientProduct: { __typename?: 'IngredientProductResponse' } & Pick<
    IngredientProductResponse,
    'successful' | 'error'
  > & {
      ingredientProduct?: Maybe<
        { __typename?: 'IngredientProduct' } & Pick<
          IngredientProduct,
          | 'id'
          | 'ingredientId'
          | 'brandId'
          | 'supplierId'
          | 'sizeId'
          | 'wastageId'
        > & {
            ingredient: { __typename?: 'Ingredient' } & Pick<
              Ingredient,
              'id' | 'displayName'
            >;
            brand?: Maybe<
              { __typename?: 'Brand' } & Pick<
                Brand,
                'id' | 'displayName' | 'website' | 'email' | 'phone'
              >
            >;
            supplier?: Maybe<
              { __typename?: 'Supplier' } & Pick<
                Supplier,
                | 'id'
                | 'creatorId'
                | 'displayName'
                | 'website'
                | 'email'
                | 'phone'
              > & {
                  address: { __typename?: 'Address' } & Pick<
                    Address,
                    | 'id'
                    | 'location'
                    | 'unit'
                    | 'streetNumber'
                    | 'road'
                    | 'city'
                    | 'shire'
                    | 'state'
                    | 'country'
                    | 'countryShort'
                    | 'postcode'
                    | 'addressLine1'
                    | 'addressLine2'
                    | 'addressLookup'
                    | 'lat'
                    | 'lng'
                  >;
                }
            >;
            size?: Maybe<
              { __typename?: 'Size' } & Pick<
                Size,
                'id' | 'productCost' | 'productGrams'
              >
            >;
            wastage?: Maybe<
              { __typename?: 'Wastage' } & Pick<
                Wastage,
                'id' | 'unitType' | 'amount'
              >
            >;
          }
      >;
    };
};

export type IgnoreIngredientMutationVariables = Exact<{
  input: IgnoreIngredientInput;
}>;

export type IgnoreIngredientMutation = { __typename?: 'Mutation' } & {
  ignoreIngredient: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error'
  >;
};

export type IngredientQueryVariables = Exact<{
  input: GetIngredientInput;
}>;

export type IngredientQuery = { __typename?: 'Query' } & {
  ingredient: { __typename?: 'IngredientResponse' } & Pick<
    IngredientResponse,
    'successful' | 'error'
  > & {
      ingredient?: Maybe<
        { __typename?: 'Ingredient' } & Pick<
          Ingredient,
          | 'id'
          | 'displayName'
          | 'liquid'
          | 'averageCost100g'
          | 'averageWastagePercentage'
          | 'confirmationState'
        > & {
            metrics: Array<
              { __typename?: 'Metric' } & Pick<Metric, 'id' | 'type' | 'grams'>
            >;
            ingredientProducts: Array<
              { __typename?: 'IngredientProduct' } & Pick<
                IngredientProduct,
                | 'id'
                | 'venueId'
                | 'sizeId'
                | 'brandId'
                | 'supplierId'
                | 'wastageId'
              > & {
                  size?: Maybe<
                    { __typename?: 'Size' } & Pick<
                      Size,
                      | 'id'
                      | 'productCost'
                      | 'productGrams'
                      | 'unitAmount'
                      | 'unitType'
                    >
                  >;
                  brand?: Maybe<
                    { __typename?: 'Brand' } & Pick<
                      Brand,
                      'id' | 'displayName' | 'email' | 'website' | 'phone'
                    >
                  >;
                  supplier?: Maybe<
                    { __typename?: 'Supplier' } & Pick<
                      Supplier,
                      'id' | 'displayName' | 'email' | 'website' | 'phone'
                    > & {
                        address: { __typename?: 'Address' } & Pick<
                          Address,
                          | 'id'
                          | 'location'
                          | 'unit'
                          | 'streetNumber'
                          | 'road'
                          | 'city'
                          | 'shire'
                          | 'state'
                          | 'country'
                          | 'countryShort'
                          | 'postcode'
                          | 'addressLine1'
                          | 'addressLine2'
                          | 'addressLookup'
                          | 'lat'
                          | 'lng'
                        >;
                      }
                  >;
                  wastage?: Maybe<
                    { __typename?: 'Wastage' } & Pick<
                      Wastage,
                      'id' | 'unitType' | 'amount'
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type IngredientRequestQueryVariables = Exact<{
  input: GetIngredientRequestInput;
}>;

export type IngredientRequestQuery = { __typename?: 'Query' } & {
  ingredientRequest: { __typename?: 'IngredientRequestResponse' } & Pick<
    IngredientRequestResponse,
    'successful' | 'error'
  > & {
      ingredientRequest?: Maybe<
        Array<
          { __typename?: 'IngredientRequest' } & Pick<
            IngredientRequest,
            | 'id'
            | 'creatorId'
            | 'ingredientId'
            | 'venueId'
            | 'metricId'
            | 'type'
            | 'grams'
            | 'newMetric'
            | 'status'
            | 'createdAt'
            | 'updatedAt'
          >
        >
      >;
    };
};

export type IngredientsQueryVariables = Exact<{ [key: string]: never }>;

export type IngredientsQuery = { __typename?: 'Query' } & {
  ingredients: { __typename?: 'IngredientsResponse' } & Pick<
    IngredientsResponse,
    'successful' | 'error'
  > & {
      ingredients?: Maybe<
        Array<
          { __typename?: 'Ingredient' } & Pick<
            Ingredient,
            | 'id'
            | 'displayName'
            | 'liquid'
            | 'averageCost100g'
            | 'averageWastagePercentage'
            | 'confirmationState'
            | 'active'
          > & {
              metrics: Array<
                { __typename?: 'Metric' } & Pick<
                  Metric,
                  'id' | 'type' | 'grams'
                >
              >;
            }
        >
      >;
    };
};

export type IngredientsWithStateQueryVariables = Exact<{
  input: GetIngredientsWithStateInput;
}>;

export type IngredientsWithStateQuery = { __typename?: 'Query' } & {
  ingredientsWithState: { __typename?: 'IngredientsResponse' } & Pick<
    IngredientsResponse,
    'successful' | 'error'
  > & {
      ingredients?: Maybe<
        Array<
          { __typename?: 'Ingredient' } & Pick<
            Ingredient,
            'id' | 'displayName' | 'averageCost100g'
          > & {
              metrics: Array<
                { __typename?: 'Metric' } & Pick<
                  Metric,
                  'id' | 'type' | 'grams'
                >
              >;
              ingredientProducts: Array<
                { __typename?: 'IngredientProduct' } & Pick<
                  IngredientProduct,
                  'id'
                > & {
                    brand?: Maybe<
                      { __typename?: 'Brand' } & Pick<
                        Brand,
                        'id' | 'displayName'
                      >
                    >;
                    supplier?: Maybe<
                      { __typename?: 'Supplier' } & Pick<
                        Supplier,
                        'id' | 'displayName'
                      >
                    >;
                  }
              >;
            }
        >
      >;
    };
};

export type UpdateIngredientMutationVariables = Exact<{
  input: UpdateIngredientInput;
}>;

export type UpdateIngredientMutation = { __typename?: 'Mutation' } & {
  updateIngredient: { __typename?: 'IngredientResponse' } & Pick<
    IngredientResponse,
    'successful' | 'error'
  > & {
      ingredient?: Maybe<
        { __typename?: 'Ingredient' } & Pick<
          Ingredient,
          | 'id'
          | 'displayName'
          | 'liquid'
          | 'averageCost100g'
          | 'averageWastagePercentage'
          | 'confirmationState'
        > & {
            metrics: Array<
              { __typename?: 'Metric' } & Pick<Metric, 'id' | 'type' | 'grams'>
            >;
          }
      >;
    };
};

export type UpdateIngredientProductMutationVariables = Exact<{
  input: UpdateIngredientProductInput;
}>;

export type UpdateIngredientProductMutation = { __typename?: 'Mutation' } & {
  updateIngredientProduct: { __typename?: 'IngredientProductResponse' } & Pick<
    IngredientProductResponse,
    'successful' | 'error'
  > & {
      ingredientProduct?: Maybe<
        { __typename?: 'IngredientProduct' } & Pick<
          IngredientProduct,
          | 'id'
          | 'ingredientId'
          | 'brandId'
          | 'supplierId'
          | 'sizeId'
          | 'wastageId'
        > & {
            ingredient: { __typename?: 'Ingredient' } & Pick<
              Ingredient,
              'id' | 'displayName'
            >;
            brand?: Maybe<
              { __typename?: 'Brand' } & Pick<
                Brand,
                'id' | 'displayName' | 'website' | 'email' | 'phone'
              >
            >;
            supplier?: Maybe<
              { __typename?: 'Supplier' } & Pick<
                Supplier,
                | 'id'
                | 'creatorId'
                | 'displayName'
                | 'website'
                | 'email'
                | 'phone'
                | 'internationalPhone'
              > & {
                  supplierGroup?: Maybe<
                    { __typename?: 'SupplierGroup' } & Pick<
                      SupplierGroup,
                      'id' | 'displayName'
                    >
                  >;
                  address: { __typename?: 'Address' } & Pick<
                    Address,
                    | 'id'
                    | 'location'
                    | 'unit'
                    | 'streetNumber'
                    | 'road'
                    | 'city'
                    | 'shire'
                    | 'state'
                    | 'country'
                    | 'countryShort'
                    | 'postcode'
                    | 'addressLine1'
                    | 'addressLine2'
                    | 'addressLookup'
                    | 'lat'
                    | 'lng'
                  >;
                }
            >;
            size?: Maybe<
              { __typename?: 'Size' } & Pick<
                Size,
                'id' | 'productCost' | 'productGrams'
              >
            >;
            wastage?: Maybe<
              { __typename?: 'Wastage' } & Pick<
                Wastage,
                'id' | 'unitType' | 'amount'
              >
            >;
          }
      >;
    };
};

export type CreateRecipeMutationVariables = Exact<{
  input: CreateRecipeInput;
}>;

export type CreateRecipeMutation = { __typename?: 'Mutation' } & {
  createRecipe: { __typename?: 'RecipeResponse' } & Pick<
    RecipeResponse,
    'successful' | 'error'
  > & {
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'recipeRevenue'
          | 'recipeProfit'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'serves'
          | 'totalGrams'
          | 'foodCostPercentage'
          | 'venueId'
          | 'active'
          | 'createdAt'
          | 'category'
          | 'imageUrl'
          | 'numScaledRecipeVersions'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                | 'id'
                | 'displayName'
                | 'serves'
                | 'recipeWastage'
                | 'totalStaffTime'
                | 'totalTime'
                | 'totalGrams'
                | 'selected'
                | 'active'
                | 'original'
                | 'createdAt'
                | 'updatedAt'
                | 'isScaled'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            | 'id'
                            | 'displayName'
                            | 'averageCost100g'
                            | 'averageWastagePercentage'
                            | 'confirmationState'
                          > & {
                              metrics: Array<
                                { __typename?: 'Metric' } & Pick<
                                  Metric,
                                  'id' | 'type' | 'grams'
                                >
                              >;
                            };
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            | 'id'
                            | 'displayName'
                            | 'serves'
                            | 'totalGrams'
                            | 'totalTime'
                            | 'recipeRevenue'
                            | 'recipeProfit'
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type CreateRecipeFromTemplateMutationVariables = Exact<{
  input: CreateRecipeFromTemplateInput;
}>;

export type CreateRecipeFromTemplateMutation = { __typename?: 'Mutation' } & {
  createRecipeFromTemplate: { __typename?: 'RecipeResponse' } & Pick<
    RecipeResponse,
    'successful' | 'error'
  > & {
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'venueId'
          | 'active'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'recipeProfit'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                'id' | 'displayName' | 'serves' | 'selected'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            | 'id'
                            | 'displayName'
                            | 'serves'
                            | 'totalGrams'
                            | 'totalTime'
                            | 'recipeRevenue'
                            | 'recipeProfit'
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type DeleteRecipeVersionMutationVariables = Exact<{
  input: DeleteRecipeVersionInput;
}>;

export type DeleteRecipeVersionMutation = { __typename?: 'Mutation' } & {
  deleteRecipeVersion: { __typename?: 'DeleteRecipeVersionResponse' } & Pick<
    DeleteRecipeVersionResponse,
    'successful' | 'error' | 'selectedRecipeVersion'
  > & {
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'venueId'
          | 'active'
          | 'numScaledRecipeVersions'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'recipeProfit'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                | 'id'
                | 'displayName'
                | 'serves'
                | 'selected'
                | 'active'
                | 'isScaled'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type GetRecipeCategoryRecipesQueryVariables = Exact<{
  input: GetRecipeCategoryRecipesInput;
}>;

export type GetRecipeCategoryRecipesQuery = { __typename?: 'Query' } & {
  recipeCategoryRecipes: { __typename?: 'RecipesResponse' } & Pick<
    RecipesResponse,
    'successful' | 'error'
  > & {
      recipes: Array<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          'id' | 'displayName' | 'imageUrl'
        >
      >;
    };
};

export type RecipeQueryVariables = Exact<{
  input: GetRecipeInput;
}>;

export type RecipeQuery = { __typename?: 'Query' } & {
  recipe: { __typename?: 'RecipeResponse' } & Pick<
    RecipeResponse,
    'successful' | 'error'
  > & {
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'recipeRevenue'
          | 'recipeProfit'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'serves'
          | 'originalServes'
          | 'originalRecipeProfit'
          | 'totalGrams'
          | 'foodCostPercentage'
          | 'venueId'
          | 'active'
          | 'createdAt'
          | 'category'
          | 'imageUrl'
          | 'numScaledRecipeVersions'
          | 'recipeActiveSeconds'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                | 'id'
                | 'displayName'
                | 'serves'
                | 'recipeWastage'
                | 'totalStaffTime'
                | 'totalTime'
                | 'totalGrams'
                | 'selected'
                | 'active'
                | 'original'
                | 'createdAt'
                | 'updatedAt'
                | 'isScaled'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            | 'id'
                            | 'displayName'
                            | 'averageCost100g'
                            | 'averageWastagePercentage'
                            | 'confirmationState'
                          > & {
                              metrics: Array<
                                { __typename?: 'Metric' } & Pick<
                                  Metric,
                                  'id' | 'type' | 'grams'
                                >
                              >;
                            };
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            | 'id'
                            | 'displayName'
                            | 'serves'
                            | 'totalGrams'
                            | 'recipeRevenue'
                            | 'recipeProfit'
                            | 'totalTime'
                          >;
                        }
                    >
                  >;
                }
            >;
            recipeProfitTrend?: Maybe<
              Array<
                { __typename?: 'RecipeProfitTrend' } & Pick<
                  RecipeProfitTrend,
                  | 'id'
                  | 'serves'
                  | 'recipeRevenue'
                  | 'recipeProfit'
                  | 'recipeProfitIncreasePerServe'
                  | 'ingredientCost'
                  | 'staffCost'
                  | 'totalCost'
                  | 'recipeActiveSeconds'
                  | 'weeklySalesPerServe'
                  | 'createdAt'
                  | 'creatorId'
                >
              >
            >;
          }
      >;
    };
};

export type RecipesQueryVariables = Exact<{
  input: GetVenueRecipesInput;
}>;

export type RecipesQuery = { __typename?: 'Query' } & {
  venueRecipes: { __typename?: 'RecipesResponse' } & Pick<
    RecipesResponse,
    'successful' | 'error'
  > & {
      recipes: Array<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'recipeRevenue'
          | 'recipeProfit'
          | 'originalRecipeProfit'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'originalServes'
          | 'serves'
          | 'totalGrams'
          | 'totalTime'
          | 'foodCostPercentage'
          | 'venueId'
          | 'active'
          | 'category'
          | 'imageUrl'
          | 'numScaledRecipeVersions'
          | 'createdAt'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                | 'id'
                | 'displayName'
                | 'serves'
                | 'totalGrams'
                | 'totalTime'
                | 'recipeWastage'
                | 'selected'
                | 'active'
                | 'isScaled'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                }
            >;
            venue: { __typename?: 'Venue' } & Pick<
              Venue,
              'id' | 'displayName' | 'recipeProfitIncreasePerYear'
            >;
            creator: { __typename?: 'User' } & Pick<User, 'id' | 'firstName'>;
          }
      >;
    };
};

export type RecipesProfitTrendQueryVariables = Exact<{
  input: GetVenueRecipesInput;
}>;

export type RecipesProfitTrendQuery = { __typename?: 'Query' } & {
  venueRecipes: { __typename?: 'RecipesResponse' } & Pick<
    RecipesResponse,
    'successful' | 'error'
  > & {
      recipes: Array<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'recipeRevenue'
          | 'recipeProfit'
          | 'originalRecipeProfit'
          | 'recipeProfitIncreasePerServe'
          | 'originalServes'
          | 'serves'
          | 'foodCostPercentage'
        > & {
            recipeProfitTrend?: Maybe<
              Array<
                { __typename?: 'RecipeProfitTrend' } & Pick<
                  RecipeProfitTrend,
                  | 'id'
                  | 'serves'
                  | 'recipeRevenue'
                  | 'recipeProfit'
                  | 'recipeProfitIncreasePerServe'
                  | 'ingredientCost'
                  | 'staffCost'
                  | 'totalCost'
                  | 'createdAt'
                >
              >
            >;
          }
      >;
    };
};

export type SetOriginalRecipeVersionMutationVariables = Exact<{
  input: SetOriginalRecipeVersionInput;
}>;

export type SetOriginalRecipeVersionMutation = { __typename?: 'Mutation' } & {
  setOriginalRecipeVersion: { __typename?: 'RecipeResponse' } & Pick<
    RecipeResponse,
    'successful' | 'error'
  > & {
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'venueId'
          | 'active'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                | 'id'
                | 'displayName'
                | 'serves'
                | 'selected'
                | 'original'
                | 'active'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
      recipeVersion?: Maybe<
        { __typename?: 'RecipeVersion' } & Pick<
          RecipeVersion,
          'id' | 'displayName' | 'serves' | 'selected'
        > & {
            recipeTimeItems: Array<
              { __typename?: 'RecipeTimeItem' } & Pick<
                RecipeTimeItem,
                | 'id'
                | 'unit'
                | 'quantity'
                | 'description'
                | 'staffTime'
                | 'order'
              >
            >;
            recipeIngredients?: Maybe<
              Array<
                { __typename?: 'RecipeIngredient' } & Pick<
                  RecipeIngredient,
                  'id' | 'unit' | 'quantity' | 'order'
                > & {
                    ingredient: { __typename?: 'Ingredient' } & Pick<
                      Ingredient,
                      'id' | 'displayName'
                    >;
                  }
              >
            >;
            recipeAsIngredients?: Maybe<
              Array<
                { __typename?: 'RecipeAsIngredient' } & Pick<
                  RecipeAsIngredient,
                  'id' | 'unit' | 'quantity' | 'order'
                > & {
                    recipe: { __typename?: 'Recipe' } & Pick<
                      Recipe,
                      'id' | 'displayName'
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type SetSelectedRecipeVersionMutationVariables = Exact<{
  input: SetSelectedRecipeVersionInput;
}>;

export type SetSelectedRecipeVersionMutation = { __typename?: 'Mutation' } & {
  setSelectedRecipeVersion: { __typename?: 'RecipeResponse' } & Pick<
    RecipeResponse,
    'successful' | 'error'
  > & {
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'venueId'
          | 'active'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                | 'id'
                | 'displayName'
                | 'serves'
                | 'selected'
                | 'original'
                | 'active'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            'id' | 'displayName'
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
      recipeVersion?: Maybe<
        { __typename?: 'RecipeVersion' } & Pick<
          RecipeVersion,
          'id' | 'displayName' | 'serves' | 'selected'
        > & {
            recipeTimeItems: Array<
              { __typename?: 'RecipeTimeItem' } & Pick<
                RecipeTimeItem,
                | 'id'
                | 'unit'
                | 'quantity'
                | 'description'
                | 'staffTime'
                | 'order'
              >
            >;
            recipeIngredients?: Maybe<
              Array<
                { __typename?: 'RecipeIngredient' } & Pick<
                  RecipeIngredient,
                  'id' | 'unit' | 'quantity' | 'order'
                > & {
                    ingredient: { __typename?: 'Ingredient' } & Pick<
                      Ingredient,
                      'id' | 'displayName'
                    >;
                  }
              >
            >;
            recipeAsIngredients?: Maybe<
              Array<
                { __typename?: 'RecipeAsIngredient' } & Pick<
                  RecipeAsIngredient,
                  'id' | 'unit' | 'quantity' | 'order'
                > & {
                    recipe: { __typename?: 'Recipe' } & Pick<
                      Recipe,
                      'id' | 'displayName'
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type UpdateOriginalRecipeMutationVariables = Exact<{
  input: UpdateOriginalRecipeInput;
}>;

export type UpdateOriginalRecipeMutation = { __typename?: 'Mutation' } & {
  updateOriginalRecipe: { __typename?: 'RecipeResponse' } & Pick<
    RecipeResponse,
    'successful' | 'error'
  > & {
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'recipeRevenue'
          | 'recipeProfit'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'serves'
          | 'originalServes'
          | 'originalRecipeProfit'
          | 'totalGrams'
          | 'foodCostPercentage'
          | 'venueId'
          | 'active'
          | 'createdAt'
          | 'category'
          | 'imageUrl'
          | 'numScaledRecipeVersions'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                | 'id'
                | 'displayName'
                | 'serves'
                | 'recipeWastage'
                | 'totalStaffTime'
                | 'totalTime'
                | 'totalGrams'
                | 'selected'
                | 'active'
                | 'original'
                | 'createdAt'
                | 'updatedAt'
                | 'isScaled'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            | 'id'
                            | 'displayName'
                            | 'averageCost100g'
                            | 'averageWastagePercentage'
                            | 'confirmationState'
                          > & {
                              metrics: Array<
                                { __typename?: 'Metric' } & Pick<
                                  Metric,
                                  'id' | 'type' | 'grams'
                                >
                              >;
                            };
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            | 'id'
                            | 'displayName'
                            | 'serves'
                            | 'totalGrams'
                            | 'recipeRevenue'
                            | 'recipeProfit'
                          >;
                        }
                    >
                  >;
                }
            >;
            recipeProfitTrend?: Maybe<
              Array<
                { __typename?: 'RecipeProfitTrend' } & Pick<
                  RecipeProfitTrend,
                  | 'id'
                  | 'serves'
                  | 'recipeRevenue'
                  | 'recipeProfit'
                  | 'recipeProfitIncreasePerServe'
                  | 'ingredientCost'
                  | 'staffCost'
                  | 'totalCost'
                  | 'recipeActiveSeconds'
                  | 'weeklySalesPerServe'
                  | 'createdAt'
                  | 'creatorId'
                >
              >
            >;
          }
      >;
    };
};

export type UpdateRecipeMutationVariables = Exact<{
  input: UpdateRecipeInput;
}>;

export type UpdateRecipeMutation = { __typename?: 'Mutation' } & {
  updateRecipe: { __typename?: 'RecipeResponse' } & Pick<
    RecipeResponse,
    'successful' | 'error'
  > & {
      recipe?: Maybe<
        { __typename?: 'Recipe' } & Pick<
          Recipe,
          | 'id'
          | 'displayName'
          | 'weeklySalesPerServe'
          | 'salesPricePerServe'
          | 'recipeRevenue'
          | 'recipeProfit'
          | 'recipeProfitIncreasePerServe'
          | 'highestRecipeProfitIncreasePerServe'
          | 'serves'
          | 'originalServes'
          | 'originalRecipeProfit'
          | 'totalGrams'
          | 'foodCostPercentage'
          | 'venueId'
          | 'active'
          | 'createdAt'
          | 'category'
          | 'imageUrl'
          | 'numScaledRecipeVersions'
        > & {
            recipeVersions: Array<
              { __typename?: 'RecipeVersion' } & Pick<
                RecipeVersion,
                | 'id'
                | 'displayName'
                | 'serves'
                | 'recipeWastage'
                | 'totalStaffTime'
                | 'totalTime'
                | 'totalGrams'
                | 'selected'
                | 'active'
                | 'original'
                | 'createdAt'
                | 'updatedAt'
                | 'isScaled'
              > & {
                  recipeTimeItems: Array<
                    { __typename?: 'RecipeTimeItem' } & Pick<
                      RecipeTimeItem,
                      | 'id'
                      | 'unit'
                      | 'quantity'
                      | 'description'
                      | 'staffTime'
                      | 'order'
                    >
                  >;
                  recipeIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeIngredient' } & Pick<
                        RecipeIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          ingredient: { __typename?: 'Ingredient' } & Pick<
                            Ingredient,
                            | 'id'
                            | 'displayName'
                            | 'averageCost100g'
                            | 'averageWastagePercentage'
                            | 'confirmationState'
                          > & {
                              metrics: Array<
                                { __typename?: 'Metric' } & Pick<
                                  Metric,
                                  'id' | 'type' | 'grams'
                                >
                              >;
                            };
                        }
                    >
                  >;
                  recipeAsIngredients?: Maybe<
                    Array<
                      { __typename?: 'RecipeAsIngredient' } & Pick<
                        RecipeAsIngredient,
                        'id' | 'unit' | 'quantity' | 'order'
                      > & {
                          recipe: { __typename?: 'Recipe' } & Pick<
                            Recipe,
                            | 'id'
                            | 'displayName'
                            | 'serves'
                            | 'totalGrams'
                            | 'totalTime'
                            | 'recipeRevenue'
                            | 'recipeProfit'
                          >;
                        }
                    >
                  >;
                }
            >;
            recipeProfitTrend?: Maybe<
              Array<
                { __typename?: 'RecipeProfitTrend' } & Pick<
                  RecipeProfitTrend,
                  | 'id'
                  | 'serves'
                  | 'recipeRevenue'
                  | 'recipeProfit'
                  | 'recipeProfitIncreasePerServe'
                  | 'ingredientCost'
                  | 'staffCost'
                  | 'totalCost'
                  | 'recipeActiveSeconds'
                  | 'weeklySalesPerServe'
                  | 'createdAt'
                  | 'creatorId'
                >
              >
            >;
          }
      >;
    };
};

export type UpdateRecipeCategoryMutationVariables = Exact<{
  input: UpdateRecipeCategoryInput;
}>;

export type UpdateRecipeCategoryMutation = { __typename?: 'Mutation' } & {
  updateRecipeCategory: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error'
  >;
};

export type SignS3MutationVariables = Exact<{
  input: SignS3Input;
}>;

export type SignS3Mutation = { __typename?: 'Mutation' } & {
  signS3: { __typename?: 'SignS3Response' } & Pick<
    SignS3Response,
    'successful' | 'error' | 'signedRequest' | 'url'
  >;
};

export type ConfirmStocktakeMutationVariables = Exact<{
  input: ConfirmStocktakeInput;
}>;

export type ConfirmStocktakeMutation = { __typename?: 'Mutation' } & {
  confirmStocktake: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error' | 'errorMessage'
  >;
};

export type CreateStocktakeMutationVariables = Exact<{
  input: CreateStocktakeInput;
}>;

export type CreateStocktakeMutation = { __typename?: 'Mutation' } & {
  createStocktake: { __typename?: 'StocktakeResponse' } & Pick<
    StocktakeResponse,
    'successful' | 'error' | 'errorMessage'
  > & {
      stocktake?: Maybe<
        { __typename?: 'Stocktake' } & Pick<
          Stocktake,
          'id' | 'displayName' | 'totalItems' | 'totalRequiredItems'
        > & {
            creator: { __typename?: 'User' } & Pick<
              User,
              'id' | 'firstName' | 'lastName'
            >;
            stocktakeSections: Array<
              { __typename?: 'StocktakeSection' } & Pick<
                StocktakeSection,
                'id' | 'totalItems'
              > & {
                  section: { __typename?: 'Section' } & Pick<
                    Section,
                    'id' | 'displayName' | 'totalRequiredItems'
                  >;
                  stocktakeIngredients: Array<
                    { __typename?: 'StocktakeIngredient' } & Pick<
                      StocktakeIngredient,
                      'id' | 'quantity'
                    > & {
                        ingredient: { __typename?: 'Ingredient' } & Pick<
                          Ingredient,
                          'id' | 'displayName'
                        > & {
                            ingredientProducts: Array<
                              { __typename?: 'IngredientProduct' } & {
                                size?: Maybe<
                                  { __typename?: 'Size' } & Pick<
                                    Size,
                                    | 'id'
                                    | 'productCost'
                                    | 'productGrams'
                                    | 'unitAmount'
                                    | 'unitType'
                                  >
                                >;
                                supplier?: Maybe<
                                  { __typename?: 'Supplier' } & Pick<
                                    Supplier,
                                    'id' | 'displayName'
                                  >
                                >;
                              }
                            >;
                          };
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export type CreateStocktakeSectionMutationVariables = Exact<{
  input: CreateStocktakeSectionInput;
}>;

export type CreateStocktakeSectionMutation = { __typename?: 'Mutation' } & {
  createStocktakeSection: { __typename?: 'StocktakeResponse' } & Pick<
    StocktakeResponse,
    'successful' | 'error' | 'errorMessage'
  > & {
      stocktake?: Maybe<
        { __typename?: 'Stocktake' } & Pick<
          Stocktake,
          'id' | 'displayName' | 'totalItems' | 'totalRequiredItems'
        > & {
            creator: { __typename?: 'User' } & Pick<
              User,
              'id' | 'firstName' | 'lastName'
            >;
            stocktakeSections: Array<
              { __typename?: 'StocktakeSection' } & Pick<
                StocktakeSection,
                'id' | 'totalItems'
              > & {
                  section: { __typename?: 'Section' } & Pick<
                    Section,
                    'id' | 'displayName' | 'totalRequiredItems'
                  >;
                  stocktakeIngredients: Array<
                    { __typename?: 'StocktakeIngredient' } & Pick<
                      StocktakeIngredient,
                      'id' | 'quantity'
                    > & {
                        ingredient: { __typename?: 'Ingredient' } & Pick<
                          Ingredient,
                          'id' | 'displayName'
                        > & {
                            ingredientProducts: Array<
                              { __typename?: 'IngredientProduct' } & {
                                size?: Maybe<
                                  { __typename?: 'Size' } & Pick<
                                    Size,
                                    | 'id'
                                    | 'productCost'
                                    | 'productGrams'
                                    | 'unitAmount'
                                    | 'unitType'
                                  >
                                >;
                                supplier?: Maybe<
                                  { __typename?: 'Supplier' } & Pick<
                                    Supplier,
                                    'id' | 'displayName'
                                  >
                                >;
                              }
                            >;
                          };
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export type DeleteStocktakeSectionMutationVariables = Exact<{
  input: DeleteStocktakeSectionInput;
}>;

export type DeleteStocktakeSectionMutation = { __typename?: 'Mutation' } & {
  deleteStocktakeSection: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error' | 'errorMessage'
  >;
};

export type InventoryStocktakeQueryVariables = Exact<{
  input: GetInventoryStocktakeInput;
}>;

export type InventoryStocktakeQuery = { __typename?: 'Query' } & {
  inventoryStocktake: { __typename?: 'StocktakeResponse' } & Pick<
    StocktakeResponse,
    'successful' | 'error' | 'errorMessage'
  > & {
      stocktake?: Maybe<
        { __typename?: 'Stocktake' } & Pick<
          Stocktake,
          | 'id'
          | 'active'
          | 'displayName'
          | 'totalItems'
          | 'totalRequiredItems'
          | 'status'
          | 'duplicatedId'
          | 'duplicatedName'
        > & {
            creator: { __typename?: 'User' } & Pick<
              User,
              'id' | 'firstName' | 'lastName'
            >;
            stocktakeSections: Array<
              { __typename?: 'StocktakeSection' } & Pick<
                StocktakeSection,
                'id' | 'stocktakeId' | 'totalItems'
              > & {
                  section: { __typename?: 'Section' } & Pick<
                    Section,
                    'id' | 'displayName' | 'totalRequiredItems'
                  >;
                  stocktakeIngredients: Array<
                    { __typename?: 'StocktakeIngredient' } & Pick<
                      StocktakeIngredient,
                      'id' | 'quantity' | 'order'
                    > & {
                        ingredient: { __typename?: 'Ingredient' } & Pick<
                          Ingredient,
                          'id' | 'displayName'
                        > & {
                            ingredientProducts: Array<
                              { __typename?: 'IngredientProduct' } & Pick<
                                IngredientProduct,
                                'id' | 'venueId'
                              > & {
                                  size?: Maybe<
                                    { __typename?: 'Size' } & Pick<
                                      Size,
                                      | 'id'
                                      | 'productCost'
                                      | 'productGrams'
                                      | 'unitAmount'
                                      | 'unitType'
                                    >
                                  >;
                                  required: Array<
                                    { __typename?: 'Required' } & Pick<
                                      Required,
                                      | 'id'
                                      | 'quantity'
                                      | 'unitAmount'
                                      | 'active'
                                    >
                                  >;
                                  supplier?: Maybe<
                                    { __typename?: 'Supplier' } & Pick<
                                      Supplier,
                                      'id' | 'displayName' | 'email'
                                    >
                                  >;
                                }
                            >;
                          };
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export type StocktakeQueryVariables = Exact<{
  input: GetStocktakeInput;
}>;

export type StocktakeQuery = { __typename?: 'Query' } & {
  stocktake: { __typename?: 'StocktakeResponse' } & Pick<
    StocktakeResponse,
    'successful' | 'error' | 'errorMessage'
  > & {
      stocktake?: Maybe<
        { __typename?: 'Stocktake' } & Pick<
          Stocktake,
          | 'id'
          | 'active'
          | 'displayName'
          | 'totalItems'
          | 'totalRequiredItems'
          | 'status'
          | 'duplicatedId'
          | 'duplicatedName'
        > & {
            creator: { __typename?: 'User' } & Pick<
              User,
              'id' | 'firstName' | 'lastName'
            >;
            stocktakeSections: Array<
              { __typename?: 'StocktakeSection' } & Pick<
                StocktakeSection,
                'id' | 'totalItems'
              > & {
                  section: { __typename?: 'Section' } & Pick<
                    Section,
                    'id' | 'displayName' | 'totalRequiredItems'
                  >;
                  stocktakeIngredients: Array<
                    { __typename?: 'StocktakeIngredient' } & Pick<
                      StocktakeIngredient,
                      'id' | 'quantity' | 'order'
                    > & {
                        ingredient: { __typename?: 'Ingredient' } & Pick<
                          Ingredient,
                          'id' | 'displayName'
                        > & {
                            ingredientProducts: Array<
                              { __typename?: 'IngredientProduct' } & Pick<
                                IngredientProduct,
                                'id' | 'venueId'
                              > & {
                                  size?: Maybe<
                                    { __typename?: 'Size' } & Pick<
                                      Size,
                                      | 'id'
                                      | 'productCost'
                                      | 'productGrams'
                                      | 'unitAmount'
                                      | 'unitType'
                                    >
                                  >;
                                  required: Array<
                                    { __typename?: 'Required' } & Pick<
                                      Required,
                                      | 'id'
                                      | 'quantity'
                                      | 'unitAmount'
                                      | 'active'
                                    >
                                  >;
                                  supplier?: Maybe<
                                    { __typename?: 'Supplier' } & Pick<
                                      Supplier,
                                      'id' | 'displayName'
                                    >
                                  >;
                                }
                            >;
                          };
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export type StocktakesQueryVariables = Exact<{
  input: GetStocktakesInput;
}>;

export type StocktakesQuery = { __typename?: 'Query' } & {
  stocktakes: { __typename?: 'StocktakeResponse' } & Pick<
    StocktakeResponse,
    'successful' | 'error' | 'errorMessage'
  > & {
      stocktakes?: Maybe<
        Array<
          { __typename?: 'Stocktake' } & Pick<
            Stocktake,
            | 'id'
            | 'displayName'
            | 'totalItems'
            | 'totalRequiredItems'
            | 'status'
            | 'createdAt'
            | 'updatedAt'
          > & {
              creator: { __typename?: 'User' } & Pick<
                User,
                'id' | 'firstName' | 'lastName'
              >;
            }
        >
      >;
    };
};

export type UpdateStocktakeSectionMutationVariables = Exact<{
  input: UpdateStocktakeSectionInput;
}>;

export type UpdateStocktakeSectionMutation = { __typename?: 'Mutation' } & {
  updateStocktakeSection: { __typename?: 'StocktakeResponse' } & Pick<
    StocktakeResponse,
    'successful' | 'error' | 'errorMessage'
  > & {
      stocktake?: Maybe<
        { __typename?: 'Stocktake' } & Pick<
          Stocktake,
          'id' | 'displayName' | 'totalItems' | 'totalRequiredItems'
        > & {
            creator: { __typename?: 'User' } & Pick<
              User,
              'id' | 'firstName' | 'lastName'
            >;
            stocktakeSections: Array<
              { __typename?: 'StocktakeSection' } & Pick<
                StocktakeSection,
                'id' | 'totalItems'
              > & {
                  section: { __typename?: 'Section' } & Pick<
                    Section,
                    'id' | 'displayName' | 'totalRequiredItems'
                  >;
                  stocktakeIngredients: Array<
                    { __typename?: 'StocktakeIngredient' } & Pick<
                      StocktakeIngredient,
                      'id' | 'quantity'
                    > & {
                        ingredient: { __typename?: 'Ingredient' } & Pick<
                          Ingredient,
                          'id' | 'displayName'
                        > & {
                            ingredientProducts: Array<
                              { __typename?: 'IngredientProduct' } & {
                                size?: Maybe<
                                  { __typename?: 'Size' } & Pick<
                                    Size,
                                    | 'id'
                                    | 'productCost'
                                    | 'productGrams'
                                    | 'unitAmount'
                                    | 'unitType'
                                  >
                                >;
                                supplier?: Maybe<
                                  { __typename?: 'Supplier' } & Pick<
                                    Supplier,
                                    'id' | 'displayName'
                                  >
                                >;
                              }
                            >;
                          };
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export type ClearPaymentMethodMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ClearPaymentMethodMutation = { __typename?: 'Mutation' } & {
  clearPaymentMethod: { __typename?: 'CrudResponse' } & Pick<
    CrudResponse,
    'successful' | 'error'
  >;
};

export type SavePaymentMethodMutationVariables = Exact<{
  input: SavePaymentMethodInput;
}>;

export type SavePaymentMethodMutation = { __typename?: 'Mutation' } & {
  savePaymentMethod: { __typename?: 'SavePaymentMethodResponse' } & Pick<
    SavePaymentMethodResponse,
    'successful' | 'error'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'accountType'
          | 'billingCycle'
          | 'subscriptionStatus'
          | 'stripeCustomerId'
          | 'stripePaymentMethodId'
          | 'stripePaymentLast4'
          | 'stripePaymentExpMonth'
          | 'stripePaymentExpYear'
          | 'email'
          | 'firstName'
          | 'lastName'
        > & {
            addOns: Array<
              { __typename?: 'AddOn' } & Pick<AddOn, 'app' | 'endDate'>
            >;
          }
      >;
      billingAddress?: Maybe<
        { __typename?: 'Address' } & Pick<
          Address,
          | 'id'
          | 'location'
          | 'unit'
          | 'streetNumber'
          | 'road'
          | 'city'
          | 'shire'
          | 'state'
          | 'country'
          | 'countryShort'
          | 'postcode'
          | 'addressLine1'
          | 'addressLine2'
          | 'addressLookup'
          | 'lat'
          | 'lng'
        >
      >;
    };
};

export type SubscriptionDataQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionDataQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserResponse' } & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'accountType'
          | 'billingCycle'
          | 'subscriptionStatus'
          | 'stripeCustomerId'
          | 'stripePaymentMethodId'
          | 'stripePaymentLast4'
          | 'stripePaymentExpMonth'
          | 'stripePaymentExpYear'
          | 'email'
          | 'firstName'
          | 'lastName'
        > & {
            addOns: Array<
              { __typename?: 'AddOn' } & Pick<AddOn, 'app' | 'endDate'>
            >;
          }
      >;
    }
  >;
};

export type UpdateBillingAddressMutationVariables = Exact<{
  input: UpdateBillingAddressInput;
}>;

export type UpdateBillingAddressMutation = { __typename?: 'Mutation' } & {
  updateBillingAddress: { __typename?: 'UpdateBillingAddressResponse' } & Pick<
    UpdateBillingAddressResponse,
    'successful' | 'error'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'accountType'
          | 'billingCycle'
          | 'subscriptionStatus'
          | 'stripeCustomerId'
          | 'stripePaymentMethodId'
          | 'stripePaymentLast4'
          | 'stripePaymentExpMonth'
          | 'stripePaymentExpYear'
          | 'email'
          | 'firstName'
          | 'lastName'
        > & {
            addOns: Array<
              { __typename?: 'AddOn' } & Pick<AddOn, 'app' | 'endDate'>
            >;
          }
      >;
      billingAddress?: Maybe<
        { __typename?: 'Address' } & Pick<
          Address,
          | 'id'
          | 'location'
          | 'unit'
          | 'streetNumber'
          | 'road'
          | 'city'
          | 'shire'
          | 'state'
          | 'country'
          | 'countryShort'
          | 'postcode'
          | 'addressLine1'
          | 'addressLine2'
          | 'addressLookup'
          | 'lat'
          | 'lng'
        >
      >;
    };
};

export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
}>;

export type UpdateSubscriptionMutation = { __typename?: 'Mutation' } & {
  updateSubscription: { __typename?: 'UpdateSubscriptionResponse' } & Pick<
    UpdateSubscriptionResponse,
    'successful' | 'error'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'accountType'
          | 'billingCycle'
          | 'subscriptionStatus'
          | 'stripeCustomerId'
        > & {
            addOns: Array<
              { __typename?: 'AddOn' } & Pick<AddOn, 'app' | 'endDate'>
            >;
          }
      >;
    };
};

export type CreateSupplierMutationVariables = Exact<{
  input: CreateSupplierInput;
}>;

export type CreateSupplierMutation = { __typename?: 'Mutation' } & {
  createSupplier: { __typename?: 'SupplierResponse' } & Pick<
    SupplierResponse,
    'successful'
  > & {
      supplier?: Maybe<
        { __typename?: 'Supplier' } & Pick<
          Supplier,
          | 'id'
          | 'creatorId'
          | 'displayName'
          | 'website'
          | 'email'
          | 'phone'
          | 'internationalPhone'
        > & {
            address: { __typename?: 'Address' } & Pick<
              Address,
              | 'id'
              | 'location'
              | 'unit'
              | 'streetNumber'
              | 'road'
              | 'city'
              | 'shire'
              | 'state'
              | 'country'
              | 'countryShort'
              | 'postcode'
              | 'addressLine1'
              | 'addressLine2'
              | 'addressLookup'
              | 'lat'
              | 'lng'
            >;
          }
      >;
    };
};

export type SupplierQueryVariables = Exact<{
  input: GetSupplierInput;
}>;

export type SupplierQuery = { __typename?: 'Query' } & {
  supplier: { __typename?: 'SupplierResponse' } & Pick<
    SupplierResponse,
    'successful'
  > & {
      supplier?: Maybe<
        { __typename?: 'Supplier' } & Pick<
          Supplier,
          | 'id'
          | 'creatorId'
          | 'displayName'
          | 'website'
          | 'email'
          | 'phone'
          | 'internationalPhone'
        > & {
            supplierGroup?: Maybe<
              { __typename?: 'SupplierGroup' } & Pick<
                SupplierGroup,
                'id' | 'displayName'
              >
            >;
            address: { __typename?: 'Address' } & Pick<
              Address,
              | 'id'
              | 'location'
              | 'unit'
              | 'streetNumber'
              | 'road'
              | 'city'
              | 'shire'
              | 'state'
              | 'country'
              | 'countryShort'
              | 'postcode'
              | 'addressLine1'
              | 'addressLine2'
              | 'addressLookup'
              | 'lat'
              | 'lng'
            >;
          }
      >;
    };
};

export type SupplierGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type SupplierGroupsQuery = { __typename?: 'Query' } & {
  supplierGroups: { __typename?: 'SupplierGroupResponse' } & Pick<
    SupplierGroupResponse,
    'successful' | 'error'
  > & {
      supplierGroups?: Maybe<
        Array<
          { __typename?: 'Supplier' } & Pick<
            Supplier,
            | 'id'
            | 'creatorId'
            | 'displayName'
            | 'website'
            | 'email'
            | 'phone'
            | 'internationalPhone'
          > & {
              address: { __typename?: 'Address' } & Pick<
                Address,
                | 'id'
                | 'location'
                | 'unit'
                | 'streetNumber'
                | 'road'
                | 'city'
                | 'shire'
                | 'state'
                | 'country'
                | 'countryShort'
                | 'postcode'
                | 'addressLine1'
                | 'addressLine2'
                | 'addressLookup'
                | 'lat'
                | 'lng'
              >;
            }
        >
      >;
    };
};

export type SuppliersQueryVariables = Exact<{ [key: string]: never }>;

export type SuppliersQuery = { __typename?: 'Query' } & {
  suppliers: { __typename?: 'SupplierResponse' } & Pick<
    SupplierResponse,
    'successful' | 'error'
  > & {
      suppliers?: Maybe<
        Array<
          { __typename?: 'Supplier' } & Pick<
            Supplier,
            | 'id'
            | 'creatorId'
            | 'displayName'
            | 'website'
            | 'email'
            | 'phone'
            | 'internationalPhone'
          > & {
              supplierGroup?: Maybe<
                { __typename?: 'SupplierGroup' } & Pick<
                  SupplierGroup,
                  'id' | 'displayName'
                >
              >;
              address: { __typename?: 'Address' } & Pick<
                Address,
                | 'id'
                | 'location'
                | 'unit'
                | 'streetNumber'
                | 'road'
                | 'city'
                | 'shire'
                | 'state'
                | 'country'
                | 'countryShort'
                | 'postcode'
                | 'addressLine1'
                | 'addressLine2'
                | 'addressLookup'
                | 'lat'
                | 'lng'
              >;
            }
        >
      >;
    };
};

export type UpdateSupplierMutationVariables = Exact<{
  input: UpdateSupplierInput;
}>;

export type UpdateSupplierMutation = { __typename?: 'Mutation' } & {
  updateSupplier: { __typename?: 'SupplierResponse' } & Pick<
    SupplierResponse,
    'successful'
  > & {
      supplier?: Maybe<
        { __typename?: 'Supplier' } & Pick<
          Supplier,
          | 'id'
          | 'creatorId'
          | 'displayName'
          | 'website'
          | 'email'
          | 'phone'
          | 'internationalPhone'
        > & {
            address: { __typename?: 'Address' } & Pick<
              Address,
              | 'id'
              | 'location'
              | 'unit'
              | 'streetNumber'
              | 'road'
              | 'city'
              | 'shire'
              | 'state'
              | 'country'
              | 'countryShort'
              | 'postcode'
              | 'addressLine1'
              | 'addressLine2'
              | 'addressLookup'
              | 'lat'
              | 'lng'
            >;
          }
      >;
    };
};

export type InvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type InvoicesQuery = { __typename?: 'Query' } & {
  invoices: { __typename?: 'InvoicesResponse' } & Pick<
    InvoicesResponse,
    'successful' | 'error'
  > & {
      invoices?: Maybe<
        Array<
          { __typename?: 'StripeInvoice' } & Pick<
            StripeInvoice,
            'id' | 'invoiceUrl' | 'createdUnixSec'
          >
        >
      >;
    };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'UserResponse' } & Pick<
    UserResponse,
    'successful' | 'error'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'id'
          | 'firstName'
          | 'lastName'
          | 'email'
          | 'phone'
          | 'internationalPhone'
          | 'isEmailConfirmed'
          | 'position'
          | 'accountType'
          | 'billingCycle'
          | 'admin'
          | 'createdAt'
          | 'updatedAt'
        > & {
            address?: Maybe<
              { __typename?: 'Address' } & Pick<
                Address,
                | 'id'
                | 'location'
                | 'unit'
                | 'streetNumber'
                | 'road'
                | 'city'
                | 'shire'
                | 'state'
                | 'country'
                | 'countryShort'
                | 'postcode'
                | 'addressLine1'
                | 'addressLine2'
                | 'addressLookup'
                | 'lat'
                | 'lng'
              >
            >;
            addOns: Array<{ __typename?: 'AddOn' } & Pick<AddOn, 'app'>>;
          }
      >;
    };
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = { __typename?: 'Query' } & {
  users: { __typename?: 'UsersResponse' } & Pick<
    UsersResponse,
    'successful' | 'error'
  > & {
      users?: Maybe<
        Array<
          { __typename?: 'User' } & Pick<
            User,
            | 'id'
            | 'email'
            | 'isEmailConfirmed'
            | 'firstName'
            | 'lastName'
            | 'phone'
            | 'internationalPhone'
            | 'position'
            | 'accountType'
            | 'billingCycle'
            | 'subscriptionStatus'
            | 'lastLoginAt'
            | 'createdAt'
            | 'updatedAt'
          > & {
              recipes: Array<
                { __typename?: 'Recipe' } & Pick<Recipe, 'creatorId'>
              >;
            }
        >
      >;
    };
};

export type CreateVenueMutationVariables = Exact<{
  input: CreateVenueInput;
}>;

export type CreateVenueMutation = { __typename?: 'Mutation' } & {
  createVenue: { __typename?: 'VenueResponse' } & Pick<
    VenueResponse,
    'successful' | 'error'
  > & {
      venue?: Maybe<
        { __typename?: 'Venue' } & Pick<
          Venue,
          | 'id'
          | 'displayName'
          | 'email'
          | 'type'
          | 'phone'
          | 'internationalPhone'
          | 'website'
          | 'weeksOpen'
          | 'weeksOpenUnit'
          | 'prepTime'
          | 'prepTimeUnit'
          | 'avgWastagePercentage'
          | 'wastageUnit'
          | 'venueCostId'
          | 'targetFoodMargin'
          | 'recommendedPrice'
          | 'recipeProfitIncreasePerYear'
        > & {
            address?: Maybe<
              { __typename?: 'Address' } & Pick<
                Address,
                | 'id'
                | 'location'
                | 'unit'
                | 'streetNumber'
                | 'road'
                | 'city'
                | 'shire'
                | 'state'
                | 'country'
                | 'countryShort'
                | 'postcode'
                | 'addressLine1'
                | 'addressLine2'
                | 'addressLookup'
                | 'lat'
                | 'lng'
              >
            >;
            venueCost: { __typename?: 'VenueCost' } & Pick<
              VenueCost,
              | 'id'
              | 'chefCost'
              | 'chefCostUnit'
              | 'councilCost'
              | 'councilCostUnit'
              | 'insuranceCost'
              | 'insuranceCostUnit'
              | 'rentCost'
              | 'rentCostUnit'
              | 'powerCost'
              | 'powerCostUnit'
              | 'waterCost'
              | 'waterCostUnit'
            >;
          }
      >;
    };
};

export type DeleteVenueMutationVariables = Exact<{
  input: DeleteVenueInput;
}>;

export type DeleteVenueMutation = { __typename?: 'Mutation' } & {
  deleteVenue: { __typename?: 'VenueResponse' } & Pick<
    VenueResponse,
    'successful' | 'error'
  > & {
      venue?: Maybe<
        { __typename?: 'Venue' } & Pick<
          Venue,
          | 'id'
          | 'displayName'
          | 'email'
          | 'type'
          | 'phone'
          | 'internationalPhone'
          | 'website'
          | 'weeksOpen'
          | 'weeksOpenUnit'
          | 'prepTime'
          | 'prepTimeUnit'
          | 'avgWastagePercentage'
          | 'wastageUnit'
          | 'venueCostId'
          | 'targetFoodMargin'
          | 'recommendedPrice'
          | 'recipeProfitIncreasePerYear'
        > & {
            address?: Maybe<
              { __typename?: 'Address' } & Pick<
                Address,
                | 'id'
                | 'location'
                | 'unit'
                | 'streetNumber'
                | 'road'
                | 'city'
                | 'shire'
                | 'state'
                | 'country'
                | 'countryShort'
                | 'postcode'
                | 'addressLine1'
                | 'addressLine2'
                | 'addressLookup'
                | 'lat'
                | 'lng'
              >
            >;
            venueCost: { __typename?: 'VenueCost' } & Pick<
              VenueCost,
              | 'id'
              | 'chefCost'
              | 'chefCostUnit'
              | 'councilCost'
              | 'councilCostUnit'
              | 'insuranceCost'
              | 'insuranceCostUnit'
              | 'rentCost'
              | 'rentCostUnit'
              | 'powerCost'
              | 'powerCostUnit'
              | 'waterCost'
              | 'waterCostUnit'
            >;
          }
      >;
    };
};

export type SetSelectedVenueMutationVariables = Exact<{
  input: SetSelectedVenueInput;
}>;

export type SetSelectedVenueMutation = { __typename?: 'Mutation' } & {
  setSelectedVenue: { __typename?: 'UserVenueResponse' } & Pick<
    UserVenueResponse,
    'successful' | 'error'
  > & {
      userVenue?: Maybe<
        { __typename?: 'UserVenue' } & Pick<
          UserVenue,
          'status' | 'selected' | 'totalActiveSeconds' | 'venueId'
        > & {
            venue: { __typename?: 'Venue' } & Pick<
              Venue,
              'id' | 'displayName' | 'email' | 'recipeProfitIncreasePerYear'
            > & {
                address?: Maybe<
                  { __typename?: 'Address' } & Pick<
                    Address,
                    | 'id'
                    | 'location'
                    | 'unit'
                    | 'streetNumber'
                    | 'road'
                    | 'city'
                    | 'shire'
                    | 'state'
                    | 'country'
                    | 'countryShort'
                    | 'postcode'
                    | 'addressLine1'
                    | 'addressLine2'
                    | 'addressLookup'
                    | 'lat'
                    | 'lng'
                  >
                >;
                venueCost: { __typename?: 'VenueCost' } & Pick<
                  VenueCost,
                  | 'id'
                  | 'chefCost'
                  | 'chefCostUnit'
                  | 'councilCost'
                  | 'councilCostUnit'
                  | 'foodWastePercentage'
                  | 'insuranceCost'
                  | 'insuranceCostUnit'
                  | 'powerCost'
                  | 'powerCostUnit'
                  | 'rentCost'
                  | 'rentCostUnit'
                  | 'waterCost'
                  | 'waterCostUnit'
                >;
                recipes: Array<
                  { __typename?: 'Recipe' } & Pick<
                    Recipe,
                    | 'id'
                    | 'active'
                    | 'numScaledRecipeVersions'
                    | 'recipeProfit'
                    | 'recipeRevenue'
                    | 'serves'
                    | 'totalGrams'
                  >
                >;
              };
          }
      >;
    };
};

export type UpdateVenueMutationVariables = Exact<{
  input: UpdateVenueInput;
}>;

export type UpdateVenueMutation = { __typename?: 'Mutation' } & {
  updateVenue: { __typename?: 'VenueResponse' } & Pick<
    VenueResponse,
    'successful' | 'error'
  > & {
      venue?: Maybe<
        { __typename?: 'Venue' } & Pick<
          Venue,
          | 'id'
          | 'displayName'
          | 'email'
          | 'type'
          | 'phone'
          | 'internationalPhone'
          | 'website'
          | 'weeksOpen'
          | 'weeksOpenUnit'
          | 'prepTime'
          | 'prepTimeUnit'
          | 'avgWastagePercentage'
          | 'wastageUnit'
          | 'venueCostId'
          | 'targetFoodMargin'
          | 'recommendedPrice'
          | 'recipeProfitIncreasePerYear'
        > & {
            address?: Maybe<
              { __typename?: 'Address' } & Pick<
                Address,
                | 'id'
                | 'location'
                | 'unit'
                | 'streetNumber'
                | 'road'
                | 'city'
                | 'shire'
                | 'state'
                | 'country'
                | 'countryShort'
                | 'postcode'
                | 'addressLine1'
                | 'addressLine2'
                | 'addressLookup'
                | 'lat'
                | 'lng'
              >
            >;
            venueCost: { __typename?: 'VenueCost' } & Pick<
              VenueCost,
              | 'id'
              | 'chefCost'
              | 'chefCostUnit'
              | 'councilCost'
              | 'councilCostUnit'
              | 'insuranceCost'
              | 'insuranceCostUnit'
              | 'rentCost'
              | 'rentCostUnit'
              | 'powerCost'
              | 'powerCostUnit'
              | 'waterCost'
              | 'waterCostUnit'
            >;
          }
      >;
    };
};

export type VenueQueryVariables = Exact<{
  input: GetVenueInput;
}>;

export type VenueQuery = { __typename?: 'Query' } & {
  venue?: Maybe<
    { __typename?: 'UserVenueResponse' } & Pick<
      UserVenueResponse,
      'successful' | 'error'
    > & {
        userVenue?: Maybe<
          { __typename?: 'UserVenue' } & Pick<
            UserVenue,
            'status' | 'selected' | 'totalActiveSeconds' | 'venueId'
          > & {
              venue: { __typename?: 'Venue' } & Pick<
                Venue,
                | 'id'
                | 'active'
                | 'displayName'
                | 'email'
                | 'phone'
                | 'internationalPhone'
                | 'personal'
                | 'prepTime'
                | 'prepTimeUnit'
                | 'avgWastagePercentage'
                | 'wastageUnit'
                | 'type'
                | 'website'
                | 'weeksOpen'
                | 'weeksOpenUnit'
                | 'creatorId'
                | 'targetFoodMargin'
                | 'recommendedPrice'
                | 'recipeProfitIncreasePerYear'
              > & {
                  address?: Maybe<
                    { __typename?: 'Address' } & Pick<
                      Address,
                      | 'id'
                      | 'location'
                      | 'unit'
                      | 'streetNumber'
                      | 'road'
                      | 'city'
                      | 'shire'
                      | 'state'
                      | 'country'
                      | 'countryShort'
                      | 'postcode'
                      | 'addressLine1'
                      | 'addressLine2'
                      | 'addressLookup'
                      | 'lat'
                      | 'lng'
                    >
                  >;
                  venueCost: { __typename?: 'VenueCost' } & Pick<
                    VenueCost,
                    | 'id'
                    | 'chefCost'
                    | 'chefCostUnit'
                    | 'councilCost'
                    | 'councilCostUnit'
                    | 'foodWastePercentage'
                    | 'insuranceCost'
                    | 'insuranceCostUnit'
                    | 'powerCost'
                    | 'powerCostUnit'
                    | 'rentCost'
                    | 'rentCostUnit'
                    | 'waterCost'
                    | 'waterCostUnit'
                  >;
                  recipes: Array<
                    { __typename?: 'Recipe' } & Pick<
                      Recipe,
                      | 'id'
                      | 'active'
                      | 'numScaledRecipeVersions'
                      | 'recipeProfit'
                      | 'recipeRevenue'
                      | 'serves'
                      | 'totalGrams'
                    >
                  >;
                  ingredientProducts: Array<
                    { __typename?: 'IngredientProduct' } & Pick<
                      IngredientProduct,
                      | 'id'
                      | 'ingredientId'
                      | 'sizeId'
                      | 'brandId'
                      | 'supplierId'
                      | 'wastageId'
                      | 'recipeCount'
                    > & {
                        ingredient: { __typename?: 'Ingredient' } & Pick<
                          Ingredient,
                          | 'id'
                          | 'displayName'
                          | 'averageCost100g'
                          | 'averageWastagePercentage'
                          | 'creatorId'
                          | 'confirmationState'
                        > & {
                            metrics: Array<
                              { __typename?: 'Metric' } & Pick<
                                Metric,
                                'type' | 'grams'
                              >
                            >;
                          };
                        size?: Maybe<
                          { __typename?: 'Size' } & Pick<
                            Size,
                            'id' | 'productCost' | 'productGrams'
                          >
                        >;
                        brand?: Maybe<
                          { __typename?: 'Brand' } & Pick<
                            Brand,
                            'id' | 'displayName' | 'email' | 'website' | 'phone'
                          >
                        >;
                        supplier?: Maybe<
                          { __typename?: 'Supplier' } & Pick<
                            Supplier,
                            'id' | 'displayName' | 'email' | 'website' | 'phone'
                          > & {
                              address: { __typename?: 'Address' } & Pick<
                                Address,
                                | 'id'
                                | 'location'
                                | 'unit'
                                | 'streetNumber'
                                | 'road'
                                | 'city'
                                | 'shire'
                                | 'state'
                                | 'country'
                                | 'countryShort'
                                | 'postcode'
                                | 'addressLine1'
                                | 'addressLine2'
                                | 'addressLookup'
                                | 'lat'
                                | 'lng'
                              >;
                            }
                        >;
                        wastage?: Maybe<
                          { __typename?: 'Wastage' } & Pick<
                            Wastage,
                            'id' | 'unitType' | 'amount'
                          >
                        >;
                      }
                  >;
                };
            }
        >;
      }
  >;
};

export type VenueProfitTrendQueryVariables = Exact<{
  input: GetVenueInput;
}>;

export type VenueProfitTrendQuery = { __typename?: 'Query' } & {
  venue?: Maybe<
    { __typename?: 'UserVenueResponse' } & Pick<
      UserVenueResponse,
      'successful' | 'error'
    > & {
        userVenue?: Maybe<
          { __typename?: 'UserVenue' } & Pick<
            UserVenue,
            'status' | 'selected' | 'totalActiveSeconds' | 'venueId'
          > & {
              venue: { __typename?: 'Venue' } & Pick<
                Venue,
                'id' | 'recipeProfitIncreasePerYear'
              > & {
                  venueProfitTrends: Array<
                    { __typename?: 'VenueProfitTrend' } & Pick<
                      VenueProfitTrend,
                      | 'id'
                      | 'totalActiveSeconds'
                      | 'recipeProfitIncreasePerYear'
                      | 'createdAt'
                    >
                  >;
                };
            }
        >;
      }
  >;
};

export type VenuesQueryVariables = Exact<{ [key: string]: never }>;

export type VenuesQuery = { __typename?: 'Query' } & {
  venueStats: { __typename?: 'VenueResponse' } & Pick<
    VenueResponse,
    'successful' | 'error'
  > & {
      venues?: Maybe<
        Array<
          { __typename?: 'Venue' } & Pick<
            Venue,
            | 'id'
            | 'displayName'
            | 'email'
            | 'type'
            | 'phone'
            | 'internationalPhone'
            | 'website'
            | 'weeksOpen'
            | 'weeksOpenUnit'
            | 'prepTime'
            | 'prepTimeUnit'
            | 'avgWastagePercentage'
            | 'wastageUnit'
            | 'personal'
            | 'active'
            | 'creatorId'
            | 'createdAt'
            | 'updatedAt'
            | 'targetFoodMargin'
            | 'recommendedPrice'
            | 'recipeProfitIncreasePerYear'
          > & {
              address?: Maybe<
                { __typename?: 'Address' } & Pick<
                  Address,
                  'id' | 'addressLookup'
                >
              >;
              venueCost: { __typename?: 'VenueCost' } & Pick<
                VenueCost,
                | 'id'
                | 'chefCost'
                | 'chefCostUnit'
                | 'councilCost'
                | 'councilCostUnit'
                | 'foodWastePercentage'
                | 'insuranceCost'
                | 'insuranceCostUnit'
                | 'powerCost'
                | 'powerCostUnit'
                | 'rentCost'
                | 'rentCostUnit'
                | 'waterCost'
                | 'waterCostUnit'
              >;
              recipes: Array<
                { __typename?: 'Recipe' } & Pick<
                  Recipe,
                  | 'id'
                  | 'recipeProfit'
                  | 'recipeRevenue'
                  | 'serves'
                  | 'totalGrams'
                  | 'createdAt'
                  | 'updatedAt'
                  | 'numScaledRecipeVersions'
                >
              >;
            }
        >
      >;
    };
};

export const ConfirmEmailWithTokenDocument = gql`
  mutation ConfirmEmailWithToken($input: ConfirmEmailWithTokenInput!) {
    confirmEmailWithToken(input: $input) {
      successful
      error
    }
  }
`;
export type ConfirmEmailWithTokenMutationFn = Apollo.MutationFunction<
  ConfirmEmailWithTokenMutation,
  ConfirmEmailWithTokenMutationVariables
>;

/**
 * __useConfirmEmailWithTokenMutation__
 *
 * To run a mutation, you first call `useConfirmEmailWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailWithTokenMutation, { data, loading, error }] = useConfirmEmailWithTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailWithTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmEmailWithTokenMutation,
    ConfirmEmailWithTokenMutationVariables
  >
) {
  return Apollo.useMutation<
    ConfirmEmailWithTokenMutation,
    ConfirmEmailWithTokenMutationVariables
  >(ConfirmEmailWithTokenDocument, baseOptions);
}
export type ConfirmEmailWithTokenMutationHookResult = ReturnType<
  typeof useConfirmEmailWithTokenMutation
>;
export type ConfirmEmailWithTokenMutationResult = Apollo.MutationResult<
  ConfirmEmailWithTokenMutation
>;
export type ConfirmEmailWithTokenMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailWithTokenMutation,
  ConfirmEmailWithTokenMutationVariables
>;
export const CreateFeedbackUserDocument = gql`
  mutation CreateFeedbackUser($input: CreateFeedbackUserInput!) {
    createFeedbackUser(input: $input) {
      successful
      error
      accessToken
      user {
        id
        firstName
        email
        lastName
        phone
        internationalPhone
        position
        accountType
        billingCycle
        admin
        isEmailConfirmed
        createdAt
      }
    }
  }
`;
export type CreateFeedbackUserMutationFn = Apollo.MutationFunction<
  CreateFeedbackUserMutation,
  CreateFeedbackUserMutationVariables
>;

/**
 * __useCreateFeedbackUserMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackUserMutation, { data, loading, error }] = useCreateFeedbackUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedbackUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFeedbackUserMutation,
    CreateFeedbackUserMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateFeedbackUserMutation,
    CreateFeedbackUserMutationVariables
  >(CreateFeedbackUserDocument, baseOptions);
}
export type CreateFeedbackUserMutationHookResult = ReturnType<
  typeof useCreateFeedbackUserMutation
>;
export type CreateFeedbackUserMutationResult = Apollo.MutationResult<
  CreateFeedbackUserMutation
>;
export type CreateFeedbackUserMutationOptions = Apollo.BaseMutationOptions<
  CreateFeedbackUserMutation,
  CreateFeedbackUserMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      successful
      error
      user {
        id
        email
        phone
        internationalPhone
        firstName
        lastName
        position
        accountType
        billingCycle
        admin
        isEmailConfirmed
        createdAt
        address {
          addressLookup
        }
        billingAddress {
          addressLookup
        }
        venuesSharedWithYou {
          status
          selected
          totalActiveSeconds
          venue {
            id
            displayName
            weeksOpen
            website
            email
            type
            website
            phone
            internationalPhone
            active
            creatorId
            recipeProfitIncreasePerYear
            address {
              id
              location
              unit
              streetNumber
              road
              city
              shire
              state
              country
              postcode
              addressLine1
              addressLine2
              addressLookup
              lat
              lng
            }
            venueCost {
              id
              chefCost
              chefCostUnit
              rentCost
              rentCostUnit
            }
          }
        }
        addOns {
          app
        }
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      successful
      error
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    baseOptions
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      successful
      error
      user {
        id
        email
        phone
        internationalPhone
        firstName
        lastName
        position
        accountType
        billingCycle
        admin
        isEmailConfirmed
        createdAt
        updatedAt
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
        billingAddress {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
        venuesSharedWithYou {
          id
          status
          venueId
          userId
          selected
          totalActiveSeconds
          venue {
            id
            displayName
            email
            type
            phone
            internationalPhone
            website
            weeksOpen
            weeksOpenUnit
            prepTime
            prepTimeUnit
            avgWastagePercentage
            targetFoodMargin
            recommendedPrice
            wastageUnit
            personal
            active
            creatorId
            createdAt
            updatedAt
            recipeProfitIncreasePerYear
            address {
              id
              location
              unit
              streetNumber
              road
              city
              shire
              state
              country
              postcode
              addressLine1
              addressLine2
              addressLookup
              lat
              lng
            }
            venueCost {
              id
              chefCost
              chefCostUnit
              councilCost
              councilCostUnit
              foodWastePercentage
              insuranceCost
              insuranceCostUnit
              powerCost
              powerCostUnit
              rentCost
              rentCostUnit
              waterCost
              waterCostUnit
            }
            recipes {
              id
              numScaledRecipeVersions
              displayName
              recipeProfit
              recipeProfitIncreasePerServe
              highestRecipeProfitIncreasePerServe
              recipeRevenue
              salesPricePerServe
              weeklySalesPerServe
              foodCostPercentage
              active
            }
          }
        }
        addOns {
          app
          endDate
        }
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    baseOptions
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ResendEmailConfirmationWithAuthDocument = gql`
  mutation ResendEmailConfirmationWithAuth {
    resendEmailConfirmationWithAuth {
      successful
      error
    }
  }
`;
export type ResendEmailConfirmationWithAuthMutationFn = Apollo.MutationFunction<
  ResendEmailConfirmationWithAuthMutation,
  ResendEmailConfirmationWithAuthMutationVariables
>;

/**
 * __useResendEmailConfirmationWithAuthMutation__
 *
 * To run a mutation, you first call `useResendEmailConfirmationWithAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailConfirmationWithAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailConfirmationWithAuthMutation, { data, loading, error }] = useResendEmailConfirmationWithAuthMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailConfirmationWithAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendEmailConfirmationWithAuthMutation,
    ResendEmailConfirmationWithAuthMutationVariables
  >
) {
  return Apollo.useMutation<
    ResendEmailConfirmationWithAuthMutation,
    ResendEmailConfirmationWithAuthMutationVariables
  >(ResendEmailConfirmationWithAuthDocument, baseOptions);
}
export type ResendEmailConfirmationWithAuthMutationHookResult = ReturnType<
  typeof useResendEmailConfirmationWithAuthMutation
>;
export type ResendEmailConfirmationWithAuthMutationResult = Apollo.MutationResult<
  ResendEmailConfirmationWithAuthMutation
>;
export type ResendEmailConfirmationWithAuthMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailConfirmationWithAuthMutation,
  ResendEmailConfirmationWithAuthMutationVariables
>;
export const ResetPasswordWithTokenDocument = gql`
  mutation ResetPasswordWithToken($input: ResetPasswordWithTokenInput!) {
    resetPasswordWithToken(input: $input) {
      successful
      error
    }
  }
`;
export type ResetPasswordWithTokenMutationFn = Apollo.MutationFunction<
  ResetPasswordWithTokenMutation,
  ResetPasswordWithTokenMutationVariables
>;

/**
 * __useResetPasswordWithTokenMutation__
 *
 * To run a mutation, you first call `useResetPasswordWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordWithTokenMutation, { data, loading, error }] = useResetPasswordWithTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordWithTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordWithTokenMutation,
    ResetPasswordWithTokenMutationVariables
  >
) {
  return Apollo.useMutation<
    ResetPasswordWithTokenMutation,
    ResetPasswordWithTokenMutationVariables
  >(ResetPasswordWithTokenDocument, baseOptions);
}
export type ResetPasswordWithTokenMutationHookResult = ReturnType<
  typeof useResetPasswordWithTokenMutation
>;
export type ResetPasswordWithTokenMutationResult = Apollo.MutationResult<
  ResetPasswordWithTokenMutation
>;
export type ResetPasswordWithTokenMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordWithTokenMutation,
  ResetPasswordWithTokenMutationVariables
>;
export const SendPasswordResetDocument = gql`
  mutation SendPasswordReset($input: SendPasswordResetInput!) {
    sendPasswordReset(input: $input) {
      successful
      error
    }
  }
`;
export type SendPasswordResetMutationFn = Apollo.MutationFunction<
  SendPasswordResetMutation,
  SendPasswordResetMutationVariables
>;

/**
 * __useSendPasswordResetMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetMutation, { data, loading, error }] = useSendPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPasswordResetMutation,
    SendPasswordResetMutationVariables
  >
) {
  return Apollo.useMutation<
    SendPasswordResetMutation,
    SendPasswordResetMutationVariables
  >(SendPasswordResetDocument, baseOptions);
}
export type SendPasswordResetMutationHookResult = ReturnType<
  typeof useSendPasswordResetMutation
>;
export type SendPasswordResetMutationResult = Apollo.MutationResult<
  SendPasswordResetMutation
>;
export type SendPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  SendPasswordResetMutation,
  SendPasswordResetMutationVariables
>;
export const SignupDocument = gql`
  mutation Signup($input: SignupInput!) {
    signup(input: $input) {
      successful
      error
      accessToken
      user {
        id
        firstName
        email
        lastName
        phone
        internationalPhone
        position
        accountType
        billingCycle
        admin
        isEmailConfirmed
        createdAt
        venuesSharedWithYou {
          status
          selected
          totalActiveSeconds
          venue {
            id
            displayName
            weeksOpen
            website
            email
            type
            website
            phone
            internationalPhone
            active
            creatorId
            recipeProfitIncreasePerYear
            address {
              id
              location
              unit
              streetNumber
              road
              city
              shire
              state
              country
              countryShort
              postcode
              addressLine1
              addressLine2
              addressLookup
              lat
              lng
            }
            venueCost {
              chefCost
              chefCostUnit
              rentCost
            }
          }
        }
        addOns {
          app
        }
      }
    }
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<
  SignupMutation,
  SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupMutation,
    SignupMutationVariables
  >
) {
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(
    SignupDocument,
    baseOptions
  );
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const SignupWithRecipeDocument = gql`
  mutation SignupWithRecipe($input: SignupWithRecipeInput!) {
    signupWithRecipe(input: $input) {
      successful
      error
      accessToken
      user {
        id
        firstName
        email
        lastName
        phone
        internationalPhone
        position
        isEmailConfirmed
        admin
        createdAt
        venuesSharedWithYou {
          status
          selected
          totalActiveSeconds
          venue {
            id
            displayName
            weeksOpen
            website
            email
            type
            website
            phone
            internationalPhone
            active
            creatorId
            recipeProfitIncreasePerYear
            address {
              id
              location
              unit
              streetNumber
              road
              city
              shire
              state
              country
              countryShort
              postcode
              addressLine1
              addressLine2
              addressLookup
              lat
              lng
            }
            venueCost {
              chefCost
              chefCostUnit
              rentCost
            }
          }
        }
        addOns {
          app
        }
      }
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        venueId
        active
        recipeVersions {
          id
          displayName
          serves
          selected
          active
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
            }
            order
          }
        }
      }
    }
  }
`;
export type SignupWithRecipeMutationFn = Apollo.MutationFunction<
  SignupWithRecipeMutation,
  SignupWithRecipeMutationVariables
>;

/**
 * __useSignupWithRecipeMutation__
 *
 * To run a mutation, you first call `useSignupWithRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithRecipeMutation, { data, loading, error }] = useSignupWithRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupWithRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupWithRecipeMutation,
    SignupWithRecipeMutationVariables
  >
) {
  return Apollo.useMutation<
    SignupWithRecipeMutation,
    SignupWithRecipeMutationVariables
  >(SignupWithRecipeDocument, baseOptions);
}
export type SignupWithRecipeMutationHookResult = ReturnType<
  typeof useSignupWithRecipeMutation
>;
export type SignupWithRecipeMutationResult = Apollo.MutationResult<
  SignupWithRecipeMutation
>;
export type SignupWithRecipeMutationOptions = Apollo.BaseMutationOptions<
  SignupWithRecipeMutation,
  SignupWithRecipeMutationVariables
>;
export const SignupWithRecipeTemplateDocument = gql`
  mutation SignupWithRecipeTemplate($input: SignupWithRecipeTemplateInput!) {
    signupWithRecipeTemplate(input: $input) {
      successful
      error
      accessToken
      user {
        id
        firstName
        email
        lastName
        phone
        internationalPhone
        position
        isEmailConfirmed
        admin
        createdAt
        venuesSharedWithYou {
          status
          selected
          totalActiveSeconds
          venue {
            id
            displayName
            weeksOpen
            website
            email
            type
            website
            phone
            internationalPhone
            active
            creatorId
            recipeProfitIncreasePerYear
            address {
              id
              location
              unit
              streetNumber
              road
              city
              shire
              state
              country
              countryShort
              postcode
              addressLine1
              addressLine2
              addressLookup
              lat
              lng
            }
            venueCost {
              chefCost
              chefCostUnit
              rentCost
            }
          }
        }
        addOns {
          app
        }
      }
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        venueId
        active
        recipeVersions {
          id
          displayName
          serves
          selected
          active
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
            }
            order
          }
        }
      }
    }
  }
`;
export type SignupWithRecipeTemplateMutationFn = Apollo.MutationFunction<
  SignupWithRecipeTemplateMutation,
  SignupWithRecipeTemplateMutationVariables
>;

/**
 * __useSignupWithRecipeTemplateMutation__
 *
 * To run a mutation, you first call `useSignupWithRecipeTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithRecipeTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithRecipeTemplateMutation, { data, loading, error }] = useSignupWithRecipeTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupWithRecipeTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignupWithRecipeTemplateMutation,
    SignupWithRecipeTemplateMutationVariables
  >
) {
  return Apollo.useMutation<
    SignupWithRecipeTemplateMutation,
    SignupWithRecipeTemplateMutationVariables
  >(SignupWithRecipeTemplateDocument, baseOptions);
}
export type SignupWithRecipeTemplateMutationHookResult = ReturnType<
  typeof useSignupWithRecipeTemplateMutation
>;
export type SignupWithRecipeTemplateMutationResult = Apollo.MutationResult<
  SignupWithRecipeTemplateMutation
>;
export type SignupWithRecipeTemplateMutationOptions = Apollo.BaseMutationOptions<
  SignupWithRecipeTemplateMutation,
  SignupWithRecipeTemplateMutationVariables
>;
export const BrandDocument = gql`
  query Brand($input: GetBrandInput!) {
    brand(input: $input) {
      successful
      brand {
        id
        displayName
        email
        website
        phone
        creatorId
      }
    }
  }
`;

/**
 * __useBrandQuery__
 *
 * To run a query within a React component, call `useBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandQuery(
  baseOptions: Apollo.QueryHookOptions<BrandQuery, BrandQueryVariables>
) {
  return Apollo.useQuery<BrandQuery, BrandQueryVariables>(
    BrandDocument,
    baseOptions
  );
}
export function useBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BrandQuery, BrandQueryVariables>
) {
  return Apollo.useLazyQuery<BrandQuery, BrandQueryVariables>(
    BrandDocument,
    baseOptions
  );
}
export type BrandQueryHookResult = ReturnType<typeof useBrandQuery>;
export type BrandLazyQueryHookResult = ReturnType<typeof useBrandLazyQuery>;
export type BrandQueryResult = Apollo.QueryResult<
  BrandQuery,
  BrandQueryVariables
>;
export const BrandsDocument = gql`
  query Brands {
    brands {
      successful
      brands {
        id
        displayName
        website
        email
        creatorId
        phone
      }
    }
  }
`;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandsQuery(
  baseOptions?: Apollo.QueryHookOptions<BrandsQuery, BrandsQueryVariables>
) {
  return Apollo.useQuery<BrandsQuery, BrandsQueryVariables>(
    BrandsDocument,
    baseOptions
  );
}
export function useBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>
) {
  return Apollo.useLazyQuery<BrandsQuery, BrandsQueryVariables>(
    BrandsDocument,
    baseOptions
  );
}
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = Apollo.QueryResult<
  BrandsQuery,
  BrandsQueryVariables
>;
export const CreateBrandDocument = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      successful
      brand {
        id
        displayName
        website
        email
        phone
        creatorId
      }
    }
  }
`;
export type CreateBrandMutationFn = Apollo.MutationFunction<
  CreateBrandMutation,
  CreateBrandMutationVariables
>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBrandMutation,
    CreateBrandMutationVariables
  >
) {
  return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(
    CreateBrandDocument,
    baseOptions
  );
}
export type CreateBrandMutationHookResult = ReturnType<
  typeof useCreateBrandMutation
>;
export type CreateBrandMutationResult = Apollo.MutationResult<
  CreateBrandMutation
>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<
  CreateBrandMutation,
  CreateBrandMutationVariables
>;
export const UpdateBrandDocument = gql`
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      successful
      error
      brand {
        id
        displayName
        website
        email
        phone
        creatorId
      }
    }
  }
`;
export type UpdateBrandMutationFn = Apollo.MutationFunction<
  UpdateBrandMutation,
  UpdateBrandMutationVariables
>;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBrandMutation,
    UpdateBrandMutationVariables
  >
) {
  return Apollo.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(
    UpdateBrandDocument,
    baseOptions
  );
}
export type UpdateBrandMutationHookResult = ReturnType<
  typeof useUpdateBrandMutation
>;
export type UpdateBrandMutationResult = Apollo.MutationResult<
  UpdateBrandMutation
>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<
  UpdateBrandMutation,
  UpdateBrandMutationVariables
>;
export const BulkCreateIngredientsDocument = gql`
  mutation BulkCreateIngredients($input: BulkCreateIngredientsInput!) {
    bulkCreateIngredients(input: $input) {
      successful
      data {
        created
        updated
        skipped
      }
      error
    }
  }
`;
export type BulkCreateIngredientsMutationFn = Apollo.MutationFunction<
  BulkCreateIngredientsMutation,
  BulkCreateIngredientsMutationVariables
>;

/**
 * __useBulkCreateIngredientsMutation__
 *
 * To run a mutation, you first call `useBulkCreateIngredientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateIngredientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateIngredientsMutation, { data, loading, error }] = useBulkCreateIngredientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateIngredientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkCreateIngredientsMutation,
    BulkCreateIngredientsMutationVariables
  >
) {
  return Apollo.useMutation<
    BulkCreateIngredientsMutation,
    BulkCreateIngredientsMutationVariables
  >(BulkCreateIngredientsDocument, baseOptions);
}
export type BulkCreateIngredientsMutationHookResult = ReturnType<
  typeof useBulkCreateIngredientsMutation
>;
export type BulkCreateIngredientsMutationResult = Apollo.MutationResult<
  BulkCreateIngredientsMutation
>;
export type BulkCreateIngredientsMutationOptions = Apollo.BaseMutationOptions<
  BulkCreateIngredientsMutation,
  BulkCreateIngredientsMutationVariables
>;
export const BulkVerifyIngredientsDocument = gql`
  mutation BulkVerifyIngredients($input: BulkVerifyIngredientsInput!) {
    bulkVerifyIngredients(input: $input) {
      successful
      error
    }
  }
`;
export type BulkVerifyIngredientsMutationFn = Apollo.MutationFunction<
  BulkVerifyIngredientsMutation,
  BulkVerifyIngredientsMutationVariables
>;

/**
 * __useBulkVerifyIngredientsMutation__
 *
 * To run a mutation, you first call `useBulkVerifyIngredientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkVerifyIngredientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkVerifyIngredientsMutation, { data, loading, error }] = useBulkVerifyIngredientsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkVerifyIngredientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkVerifyIngredientsMutation,
    BulkVerifyIngredientsMutationVariables
  >
) {
  return Apollo.useMutation<
    BulkVerifyIngredientsMutation,
    BulkVerifyIngredientsMutationVariables
  >(BulkVerifyIngredientsDocument, baseOptions);
}
export type BulkVerifyIngredientsMutationHookResult = ReturnType<
  typeof useBulkVerifyIngredientsMutation
>;
export type BulkVerifyIngredientsMutationResult = Apollo.MutationResult<
  BulkVerifyIngredientsMutation
>;
export type BulkVerifyIngredientsMutationOptions = Apollo.BaseMutationOptions<
  BulkVerifyIngredientsMutation,
  BulkVerifyIngredientsMutationVariables
>;
export const CreateIngredientDocument = gql`
  mutation CreateIngredient($input: CreateIngredientInput!) {
    createIngredient(input: $input) {
      successful
      ingredient {
        id
        displayName
        liquid
        averageCost100g
        averageWastagePercentage
        creatorId
        confirmationState
        metrics {
          type
          grams
        }
      }
      ingredientProduct {
        id
        brandId
        supplierId
        sizeId
        wastageId
        ingredient {
          id
          displayName
        }
        brand {
          id
          displayName
          website
          email
          phone
        }
        supplier {
          id
          creatorId
          displayName
          website
          email
          phone
          address {
            id
            location
            unit
            streetNumber
            road
            city
            shire
            state
            country
            countryShort
            postcode
            addressLine1
            addressLine2
            addressLookup
            lat
            lng
          }
        }
        size {
          id
          productCost
          productGrams
        }
        wastage {
          id
          unitType
          amount
        }
      }
      error
    }
  }
`;
export type CreateIngredientMutationFn = Apollo.MutationFunction<
  CreateIngredientMutation,
  CreateIngredientMutationVariables
>;

/**
 * __useCreateIngredientMutation__
 *
 * To run a mutation, you first call `useCreateIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIngredientMutation, { data, loading, error }] = useCreateIngredientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIngredientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIngredientMutation,
    CreateIngredientMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateIngredientMutation,
    CreateIngredientMutationVariables
  >(CreateIngredientDocument, baseOptions);
}
export type CreateIngredientMutationHookResult = ReturnType<
  typeof useCreateIngredientMutation
>;
export type CreateIngredientMutationResult = Apollo.MutationResult<
  CreateIngredientMutation
>;
export type CreateIngredientMutationOptions = Apollo.BaseMutationOptions<
  CreateIngredientMutation,
  CreateIngredientMutationVariables
>;
export const CreateIngredientProductDocument = gql`
  mutation CreateIngredientProduct($input: CreateIngredientProductInput!) {
    createIngredientProduct(input: $input) {
      successful
      error
      ingredientProduct {
        id
        ingredientId
        brandId
        supplierId
        sizeId
        wastageId
        ingredient {
          id
          displayName
        }
        brand {
          id
          displayName
          website
          email
          phone
        }
        supplier {
          id
          creatorId
          displayName
          website
          email
          phone
          address {
            id
            location
            unit
            streetNumber
            road
            city
            shire
            state
            country
            countryShort
            postcode
            addressLine1
            addressLine2
            addressLookup
            lat
            lng
          }
        }
        size {
          id
          productCost
          productGrams
        }
        wastage {
          id
          unitType
          amount
        }
      }
    }
  }
`;
export type CreateIngredientProductMutationFn = Apollo.MutationFunction<
  CreateIngredientProductMutation,
  CreateIngredientProductMutationVariables
>;

/**
 * __useCreateIngredientProductMutation__
 *
 * To run a mutation, you first call `useCreateIngredientProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIngredientProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIngredientProductMutation, { data, loading, error }] = useCreateIngredientProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIngredientProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIngredientProductMutation,
    CreateIngredientProductMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateIngredientProductMutation,
    CreateIngredientProductMutationVariables
  >(CreateIngredientProductDocument, baseOptions);
}
export type CreateIngredientProductMutationHookResult = ReturnType<
  typeof useCreateIngredientProductMutation
>;
export type CreateIngredientProductMutationResult = Apollo.MutationResult<
  CreateIngredientProductMutation
>;
export type CreateIngredientProductMutationOptions = Apollo.BaseMutationOptions<
  CreateIngredientProductMutation,
  CreateIngredientProductMutationVariables
>;
export const IgnoreIngredientDocument = gql`
  mutation IgnoreIngredient($input: IgnoreIngredientInput!) {
    ignoreIngredient(input: $input) {
      successful
      error
    }
  }
`;
export type IgnoreIngredientMutationFn = Apollo.MutationFunction<
  IgnoreIngredientMutation,
  IgnoreIngredientMutationVariables
>;

/**
 * __useIgnoreIngredientMutation__
 *
 * To run a mutation, you first call `useIgnoreIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreIngredientMutation, { data, loading, error }] = useIgnoreIngredientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIgnoreIngredientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IgnoreIngredientMutation,
    IgnoreIngredientMutationVariables
  >
) {
  return Apollo.useMutation<
    IgnoreIngredientMutation,
    IgnoreIngredientMutationVariables
  >(IgnoreIngredientDocument, baseOptions);
}
export type IgnoreIngredientMutationHookResult = ReturnType<
  typeof useIgnoreIngredientMutation
>;
export type IgnoreIngredientMutationResult = Apollo.MutationResult<
  IgnoreIngredientMutation
>;
export type IgnoreIngredientMutationOptions = Apollo.BaseMutationOptions<
  IgnoreIngredientMutation,
  IgnoreIngredientMutationVariables
>;
export const IngredientDocument = gql`
  query Ingredient($input: GetIngredientInput!) {
    ingredient(input: $input) {
      successful
      error
      ingredient {
        id
        displayName
        liquid
        averageCost100g
        averageWastagePercentage
        confirmationState
        metrics {
          id
          type
          grams
        }
        ingredientProducts {
          id
          venueId
          sizeId
          brandId
          supplierId
          wastageId
          size {
            id
            productCost
            productGrams
            unitAmount
            unitType
          }
          brand {
            id
            displayName
            email
            website
            phone
          }
          supplier {
            id
            displayName
            email
            website
            phone
            address {
              id
              location
              unit
              streetNumber
              road
              city
              shire
              state
              country
              countryShort
              postcode
              addressLine1
              addressLine2
              addressLookup
              lat
              lng
            }
          }
          wastage {
            id
            unitType
            amount
          }
        }
      }
      error
    }
  }
`;

/**
 * __useIngredientQuery__
 *
 * To run a query within a React component, call `useIngredientQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIngredientQuery(
  baseOptions: Apollo.QueryHookOptions<
    IngredientQuery,
    IngredientQueryVariables
  >
) {
  return Apollo.useQuery<IngredientQuery, IngredientQueryVariables>(
    IngredientDocument,
    baseOptions
  );
}
export function useIngredientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IngredientQuery,
    IngredientQueryVariables
  >
) {
  return Apollo.useLazyQuery<IngredientQuery, IngredientQueryVariables>(
    IngredientDocument,
    baseOptions
  );
}
export type IngredientQueryHookResult = ReturnType<typeof useIngredientQuery>;
export type IngredientLazyQueryHookResult = ReturnType<
  typeof useIngredientLazyQuery
>;
export type IngredientQueryResult = Apollo.QueryResult<
  IngredientQuery,
  IngredientQueryVariables
>;
export const IngredientRequestDocument = gql`
  query IngredientRequest($input: GetIngredientRequestInput!) {
    ingredientRequest(input: $input) {
      successful
      error
      ingredientRequest {
        id
        creatorId
        ingredientId
        venueId
        metricId
        type
        grams
        newMetric
        status
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useIngredientRequestQuery__
 *
 * To run a query within a React component, call `useIngredientRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIngredientRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    IngredientRequestQuery,
    IngredientRequestQueryVariables
  >
) {
  return Apollo.useQuery<
    IngredientRequestQuery,
    IngredientRequestQueryVariables
  >(IngredientRequestDocument, baseOptions);
}
export function useIngredientRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IngredientRequestQuery,
    IngredientRequestQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IngredientRequestQuery,
    IngredientRequestQueryVariables
  >(IngredientRequestDocument, baseOptions);
}
export type IngredientRequestQueryHookResult = ReturnType<
  typeof useIngredientRequestQuery
>;
export type IngredientRequestLazyQueryHookResult = ReturnType<
  typeof useIngredientRequestLazyQuery
>;
export type IngredientRequestQueryResult = Apollo.QueryResult<
  IngredientRequestQuery,
  IngredientRequestQueryVariables
>;
export const IngredientsDocument = gql`
  query Ingredients {
    ingredients {
      successful
      error
      ingredients {
        id
        displayName
        liquid
        averageCost100g
        averageWastagePercentage
        confirmationState
        active
        metrics {
          id
          type
          grams
        }
      }
    }
  }
`;

/**
 * __useIngredientsQuery__
 *
 * To run a query within a React component, call `useIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIngredientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IngredientsQuery,
    IngredientsQueryVariables
  >
) {
  return Apollo.useQuery<IngredientsQuery, IngredientsQueryVariables>(
    IngredientsDocument,
    baseOptions
  );
}
export function useIngredientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IngredientsQuery,
    IngredientsQueryVariables
  >
) {
  return Apollo.useLazyQuery<IngredientsQuery, IngredientsQueryVariables>(
    IngredientsDocument,
    baseOptions
  );
}
export type IngredientsQueryHookResult = ReturnType<typeof useIngredientsQuery>;
export type IngredientsLazyQueryHookResult = ReturnType<
  typeof useIngredientsLazyQuery
>;
export type IngredientsQueryResult = Apollo.QueryResult<
  IngredientsQuery,
  IngredientsQueryVariables
>;
export const IngredientsWithStateDocument = gql`
  query IngredientsWithState($input: GetIngredientsWithStateInput!) {
    ingredientsWithState(input: $input) {
      successful
      error
      ingredients {
        id
        displayName
        averageCost100g
        metrics {
          id
          type
          grams
        }
        ingredientProducts {
          id
          brand {
            id
            displayName
          }
          supplier {
            id
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useIngredientsWithStateQuery__
 *
 * To run a query within a React component, call `useIngredientsWithStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientsWithStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientsWithStateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIngredientsWithStateQuery(
  baseOptions: Apollo.QueryHookOptions<
    IngredientsWithStateQuery,
    IngredientsWithStateQueryVariables
  >
) {
  return Apollo.useQuery<
    IngredientsWithStateQuery,
    IngredientsWithStateQueryVariables
  >(IngredientsWithStateDocument, baseOptions);
}
export function useIngredientsWithStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IngredientsWithStateQuery,
    IngredientsWithStateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IngredientsWithStateQuery,
    IngredientsWithStateQueryVariables
  >(IngredientsWithStateDocument, baseOptions);
}
export type IngredientsWithStateQueryHookResult = ReturnType<
  typeof useIngredientsWithStateQuery
>;
export type IngredientsWithStateLazyQueryHookResult = ReturnType<
  typeof useIngredientsWithStateLazyQuery
>;
export type IngredientsWithStateQueryResult = Apollo.QueryResult<
  IngredientsWithStateQuery,
  IngredientsWithStateQueryVariables
>;
export const UpdateIngredientDocument = gql`
  mutation UpdateIngredient($input: UpdateIngredientInput!) {
    updateIngredient(input: $input) {
      successful
      error
      ingredient {
        id
        displayName
        liquid
        averageCost100g
        averageWastagePercentage
        confirmationState
        metrics {
          id
          type
          grams
        }
      }
    }
  }
`;
export type UpdateIngredientMutationFn = Apollo.MutationFunction<
  UpdateIngredientMutation,
  UpdateIngredientMutationVariables
>;

/**
 * __useUpdateIngredientMutation__
 *
 * To run a mutation, you first call `useUpdateIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIngredientMutation, { data, loading, error }] = useUpdateIngredientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIngredientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIngredientMutation,
    UpdateIngredientMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateIngredientMutation,
    UpdateIngredientMutationVariables
  >(UpdateIngredientDocument, baseOptions);
}
export type UpdateIngredientMutationHookResult = ReturnType<
  typeof useUpdateIngredientMutation
>;
export type UpdateIngredientMutationResult = Apollo.MutationResult<
  UpdateIngredientMutation
>;
export type UpdateIngredientMutationOptions = Apollo.BaseMutationOptions<
  UpdateIngredientMutation,
  UpdateIngredientMutationVariables
>;
export const UpdateIngredientProductDocument = gql`
  mutation UpdateIngredientProduct($input: UpdateIngredientProductInput!) {
    updateIngredientProduct(input: $input) {
      successful
      error
      ingredientProduct {
        id
        ingredientId
        brandId
        supplierId
        sizeId
        wastageId
        ingredient {
          id
          displayName
        }
        brand {
          id
          displayName
          website
          email
          phone
        }
        supplier {
          id
          creatorId
          displayName
          website
          email
          phone
          internationalPhone
          supplierGroup {
            id
            displayName
          }
          address {
            id
            location
            unit
            streetNumber
            road
            city
            shire
            state
            country
            countryShort
            postcode
            addressLine1
            addressLine2
            addressLookup
            lat
            lng
          }
        }
        size {
          id
          productCost
          productGrams
        }
        wastage {
          id
          unitType
          amount
        }
      }
    }
  }
`;
export type UpdateIngredientProductMutationFn = Apollo.MutationFunction<
  UpdateIngredientProductMutation,
  UpdateIngredientProductMutationVariables
>;

/**
 * __useUpdateIngredientProductMutation__
 *
 * To run a mutation, you first call `useUpdateIngredientProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIngredientProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIngredientProductMutation, { data, loading, error }] = useUpdateIngredientProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIngredientProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIngredientProductMutation,
    UpdateIngredientProductMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateIngredientProductMutation,
    UpdateIngredientProductMutationVariables
  >(UpdateIngredientProductDocument, baseOptions);
}
export type UpdateIngredientProductMutationHookResult = ReturnType<
  typeof useUpdateIngredientProductMutation
>;
export type UpdateIngredientProductMutationResult = Apollo.MutationResult<
  UpdateIngredientProductMutation
>;
export type UpdateIngredientProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateIngredientProductMutation,
  UpdateIngredientProductMutationVariables
>;
export const CreateRecipeDocument = gql`
  mutation CreateRecipe($input: CreateRecipeInput!) {
    createRecipe(input: $input) {
      successful
      error
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        recipeRevenue
        recipeProfit
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        serves
        totalGrams
        foodCostPercentage
        venueId
        active
        createdAt
        category
        imageUrl
        numScaledRecipeVersions
        recipeVersions {
          id
          displayName
          serves
          recipeWastage
          totalStaffTime
          totalTime
          totalGrams
          selected
          active
          original
          createdAt
          updatedAt
          isScaled
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
              averageCost100g
              averageWastagePercentage
              metrics {
                id
                type
                grams
              }
              confirmationState
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
              serves
              totalGrams
              totalTime
              recipeRevenue
              recipeProfit
            }
            order
          }
        }
      }
    }
  }
`;
export type CreateRecipeMutationFn = Apollo.MutationFunction<
  CreateRecipeMutation,
  CreateRecipeMutationVariables
>;

/**
 * __useCreateRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeMutation, { data, loading, error }] = useCreateRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecipeMutation,
    CreateRecipeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateRecipeMutation,
    CreateRecipeMutationVariables
  >(CreateRecipeDocument, baseOptions);
}
export type CreateRecipeMutationHookResult = ReturnType<
  typeof useCreateRecipeMutation
>;
export type CreateRecipeMutationResult = Apollo.MutationResult<
  CreateRecipeMutation
>;
export type CreateRecipeMutationOptions = Apollo.BaseMutationOptions<
  CreateRecipeMutation,
  CreateRecipeMutationVariables
>;
export const CreateRecipeFromTemplateDocument = gql`
  mutation CreateRecipeFromTemplate($input: CreateRecipeFromTemplateInput!) {
    createRecipeFromTemplate(input: $input) {
      successful
      error
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        venueId
        active
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        recipeProfit
        recipeVersions {
          id
          displayName
          serves
          selected
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
              serves
              totalGrams
              totalTime
              recipeRevenue
              recipeProfit
            }
            order
          }
        }
      }
    }
  }
`;
export type CreateRecipeFromTemplateMutationFn = Apollo.MutationFunction<
  CreateRecipeFromTemplateMutation,
  CreateRecipeFromTemplateMutationVariables
>;

/**
 * __useCreateRecipeFromTemplateMutation__
 *
 * To run a mutation, you first call `useCreateRecipeFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeFromTemplateMutation, { data, loading, error }] = useCreateRecipeFromTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecipeFromTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecipeFromTemplateMutation,
    CreateRecipeFromTemplateMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateRecipeFromTemplateMutation,
    CreateRecipeFromTemplateMutationVariables
  >(CreateRecipeFromTemplateDocument, baseOptions);
}
export type CreateRecipeFromTemplateMutationHookResult = ReturnType<
  typeof useCreateRecipeFromTemplateMutation
>;
export type CreateRecipeFromTemplateMutationResult = Apollo.MutationResult<
  CreateRecipeFromTemplateMutation
>;
export type CreateRecipeFromTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateRecipeFromTemplateMutation,
  CreateRecipeFromTemplateMutationVariables
>;
export const DeleteRecipeVersionDocument = gql`
  mutation DeleteRecipeVersion($input: DeleteRecipeVersionInput!) {
    deleteRecipeVersion(input: $input) {
      successful
      error
      selectedRecipeVersion
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        venueId
        active
        numScaledRecipeVersions
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        recipeProfit
        recipeVersions {
          id
          displayName
          serves
          selected
          active
          isScaled
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
            }
            order
          }
        }
      }
    }
  }
`;
export type DeleteRecipeVersionMutationFn = Apollo.MutationFunction<
  DeleteRecipeVersionMutation,
  DeleteRecipeVersionMutationVariables
>;

/**
 * __useDeleteRecipeVersionMutation__
 *
 * To run a mutation, you first call `useDeleteRecipeVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipeVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipeVersionMutation, { data, loading, error }] = useDeleteRecipeVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRecipeVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRecipeVersionMutation,
    DeleteRecipeVersionMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteRecipeVersionMutation,
    DeleteRecipeVersionMutationVariables
  >(DeleteRecipeVersionDocument, baseOptions);
}
export type DeleteRecipeVersionMutationHookResult = ReturnType<
  typeof useDeleteRecipeVersionMutation
>;
export type DeleteRecipeVersionMutationResult = Apollo.MutationResult<
  DeleteRecipeVersionMutation
>;
export type DeleteRecipeVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteRecipeVersionMutation,
  DeleteRecipeVersionMutationVariables
>;
export const GetRecipeCategoryRecipesDocument = gql`
  query getRecipeCategoryRecipes($input: GetRecipeCategoryRecipesInput!) {
    recipeCategoryRecipes(input: $input) {
      successful
      error
      recipes {
        id
        displayName
        imageUrl
      }
    }
  }
`;

/**
 * __useGetRecipeCategoryRecipesQuery__
 *
 * To run a query within a React component, call `useGetRecipeCategoryRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeCategoryRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeCategoryRecipesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecipeCategoryRecipesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecipeCategoryRecipesQuery,
    GetRecipeCategoryRecipesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRecipeCategoryRecipesQuery,
    GetRecipeCategoryRecipesQueryVariables
  >(GetRecipeCategoryRecipesDocument, baseOptions);
}
export function useGetRecipeCategoryRecipesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipeCategoryRecipesQuery,
    GetRecipeCategoryRecipesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRecipeCategoryRecipesQuery,
    GetRecipeCategoryRecipesQueryVariables
  >(GetRecipeCategoryRecipesDocument, baseOptions);
}
export type GetRecipeCategoryRecipesQueryHookResult = ReturnType<
  typeof useGetRecipeCategoryRecipesQuery
>;
export type GetRecipeCategoryRecipesLazyQueryHookResult = ReturnType<
  typeof useGetRecipeCategoryRecipesLazyQuery
>;
export type GetRecipeCategoryRecipesQueryResult = Apollo.QueryResult<
  GetRecipeCategoryRecipesQuery,
  GetRecipeCategoryRecipesQueryVariables
>;
export const RecipeDocument = gql`
  query recipe($input: GetRecipeInput!) {
    recipe(input: $input) {
      successful
      error
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        recipeRevenue
        recipeProfit
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        serves
        originalServes
        originalRecipeProfit
        totalGrams
        foodCostPercentage
        venueId
        active
        createdAt
        category
        imageUrl
        numScaledRecipeVersions
        recipeActiveSeconds
        recipeVersions {
          id
          displayName
          serves
          recipeWastage
          totalStaffTime
          totalTime
          totalGrams
          selected
          active
          original
          createdAt
          updatedAt
          isScaled
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
              averageCost100g
              averageWastagePercentage
              metrics {
                id
                type
                grams
              }
              confirmationState
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
              serves
              totalGrams
              recipeRevenue
              recipeProfit
              totalTime
            }
            order
          }
        }
        recipeProfitTrend {
          id
          serves
          recipeRevenue
          recipeProfit
          recipeProfitIncreasePerServe
          ingredientCost
          staffCost
          totalCost
          recipeActiveSeconds
          weeklySalesPerServe
          createdAt
          creatorId
        }
      }
    }
  }
`;

/**
 * __useRecipeQuery__
 *
 * To run a query within a React component, call `useRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecipeQuery(
  baseOptions: Apollo.QueryHookOptions<RecipeQuery, RecipeQueryVariables>
) {
  return Apollo.useQuery<RecipeQuery, RecipeQueryVariables>(
    RecipeDocument,
    baseOptions
  );
}
export function useRecipeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecipeQuery, RecipeQueryVariables>
) {
  return Apollo.useLazyQuery<RecipeQuery, RecipeQueryVariables>(
    RecipeDocument,
    baseOptions
  );
}
export type RecipeQueryHookResult = ReturnType<typeof useRecipeQuery>;
export type RecipeLazyQueryHookResult = ReturnType<typeof useRecipeLazyQuery>;
export type RecipeQueryResult = Apollo.QueryResult<
  RecipeQuery,
  RecipeQueryVariables
>;
export const RecipesDocument = gql`
  query Recipes($input: GetVenueRecipesInput!) {
    venueRecipes(input: $input) {
      successful
      error
      recipes {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        recipeRevenue
        recipeProfit
        originalRecipeProfit
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        originalServes
        serves
        totalGrams
        totalTime
        foodCostPercentage
        venueId
        active
        category
        imageUrl
        numScaledRecipeVersions
        createdAt
        recipeVersions {
          id
          displayName
          serves
          totalGrams
          totalTime
          recipeWastage
          selected
          active
          isScaled
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
            }
            order
          }
        }
        venue {
          id
          displayName
          recipeProfitIncreasePerYear
        }
        creator {
          id
          firstName
        }
      }
    }
  }
`;

/**
 * __useRecipesQuery__
 *
 * To run a query within a React component, call `useRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecipesQuery(
  baseOptions: Apollo.QueryHookOptions<RecipesQuery, RecipesQueryVariables>
) {
  return Apollo.useQuery<RecipesQuery, RecipesQueryVariables>(
    RecipesDocument,
    baseOptions
  );
}
export function useRecipesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecipesQuery, RecipesQueryVariables>
) {
  return Apollo.useLazyQuery<RecipesQuery, RecipesQueryVariables>(
    RecipesDocument,
    baseOptions
  );
}
export type RecipesQueryHookResult = ReturnType<typeof useRecipesQuery>;
export type RecipesLazyQueryHookResult = ReturnType<typeof useRecipesLazyQuery>;
export type RecipesQueryResult = Apollo.QueryResult<
  RecipesQuery,
  RecipesQueryVariables
>;
export const RecipesProfitTrendDocument = gql`
  query RecipesProfitTrend($input: GetVenueRecipesInput!) {
    venueRecipes(input: $input) {
      successful
      error
      recipes {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        recipeRevenue
        recipeProfit
        originalRecipeProfit
        recipeProfitIncreasePerServe
        originalServes
        serves
        foodCostPercentage
        recipeProfitTrend {
          id
          serves
          recipeRevenue
          recipeProfit
          recipeProfitIncreasePerServe
          ingredientCost
          staffCost
          totalCost
          createdAt
        }
      }
    }
  }
`;

/**
 * __useRecipesProfitTrendQuery__
 *
 * To run a query within a React component, call `useRecipesProfitTrendQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesProfitTrendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesProfitTrendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecipesProfitTrendQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecipesProfitTrendQuery,
    RecipesProfitTrendQueryVariables
  >
) {
  return Apollo.useQuery<
    RecipesProfitTrendQuery,
    RecipesProfitTrendQueryVariables
  >(RecipesProfitTrendDocument, baseOptions);
}
export function useRecipesProfitTrendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecipesProfitTrendQuery,
    RecipesProfitTrendQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RecipesProfitTrendQuery,
    RecipesProfitTrendQueryVariables
  >(RecipesProfitTrendDocument, baseOptions);
}
export type RecipesProfitTrendQueryHookResult = ReturnType<
  typeof useRecipesProfitTrendQuery
>;
export type RecipesProfitTrendLazyQueryHookResult = ReturnType<
  typeof useRecipesProfitTrendLazyQuery
>;
export type RecipesProfitTrendQueryResult = Apollo.QueryResult<
  RecipesProfitTrendQuery,
  RecipesProfitTrendQueryVariables
>;
export const SetOriginalRecipeVersionDocument = gql`
  mutation SetOriginalRecipeVersion($input: SetOriginalRecipeVersionInput!) {
    setOriginalRecipeVersion(input: $input) {
      successful
      error
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        venueId
        active
        recipeVersions {
          id
          displayName
          serves
          selected
          original
          active
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
            }
            order
          }
        }
      }
      recipeVersion {
        id
        displayName
        serves
        selected
        recipeTimeItems {
          id
          unit
          quantity
          description
          staffTime
          order
        }
        recipeIngredients {
          id
          unit
          quantity
          ingredient {
            id
            displayName
          }
          order
        }
        recipeAsIngredients {
          id
          unit
          quantity
          recipe {
            id
            displayName
          }
          order
        }
      }
    }
  }
`;
export type SetOriginalRecipeVersionMutationFn = Apollo.MutationFunction<
  SetOriginalRecipeVersionMutation,
  SetOriginalRecipeVersionMutationVariables
>;

/**
 * __useSetOriginalRecipeVersionMutation__
 *
 * To run a mutation, you first call `useSetOriginalRecipeVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOriginalRecipeVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOriginalRecipeVersionMutation, { data, loading, error }] = useSetOriginalRecipeVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOriginalRecipeVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOriginalRecipeVersionMutation,
    SetOriginalRecipeVersionMutationVariables
  >
) {
  return Apollo.useMutation<
    SetOriginalRecipeVersionMutation,
    SetOriginalRecipeVersionMutationVariables
  >(SetOriginalRecipeVersionDocument, baseOptions);
}
export type SetOriginalRecipeVersionMutationHookResult = ReturnType<
  typeof useSetOriginalRecipeVersionMutation
>;
export type SetOriginalRecipeVersionMutationResult = Apollo.MutationResult<
  SetOriginalRecipeVersionMutation
>;
export type SetOriginalRecipeVersionMutationOptions = Apollo.BaseMutationOptions<
  SetOriginalRecipeVersionMutation,
  SetOriginalRecipeVersionMutationVariables
>;
export const SetSelectedRecipeVersionDocument = gql`
  mutation SetSelectedRecipeVersion($input: SetSelectedRecipeVersionInput!) {
    setSelectedRecipeVersion(input: $input) {
      successful
      error
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        venueId
        active
        recipeVersions {
          id
          displayName
          serves
          selected
          original
          active
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
            }
            order
          }
        }
      }
      recipeVersion {
        id
        displayName
        serves
        selected
        recipeTimeItems {
          id
          unit
          quantity
          description
          staffTime
          order
        }
        recipeIngredients {
          id
          unit
          quantity
          ingredient {
            id
            displayName
          }
          order
        }
        recipeAsIngredients {
          id
          unit
          quantity
          recipe {
            id
            displayName
          }
          order
        }
      }
    }
  }
`;
export type SetSelectedRecipeVersionMutationFn = Apollo.MutationFunction<
  SetSelectedRecipeVersionMutation,
  SetSelectedRecipeVersionMutationVariables
>;

/**
 * __useSetSelectedRecipeVersionMutation__
 *
 * To run a mutation, you first call `useSetSelectedRecipeVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSelectedRecipeVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSelectedRecipeVersionMutation, { data, loading, error }] = useSetSelectedRecipeVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSelectedRecipeVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSelectedRecipeVersionMutation,
    SetSelectedRecipeVersionMutationVariables
  >
) {
  return Apollo.useMutation<
    SetSelectedRecipeVersionMutation,
    SetSelectedRecipeVersionMutationVariables
  >(SetSelectedRecipeVersionDocument, baseOptions);
}
export type SetSelectedRecipeVersionMutationHookResult = ReturnType<
  typeof useSetSelectedRecipeVersionMutation
>;
export type SetSelectedRecipeVersionMutationResult = Apollo.MutationResult<
  SetSelectedRecipeVersionMutation
>;
export type SetSelectedRecipeVersionMutationOptions = Apollo.BaseMutationOptions<
  SetSelectedRecipeVersionMutation,
  SetSelectedRecipeVersionMutationVariables
>;
export const UpdateOriginalRecipeDocument = gql`
  mutation UpdateOriginalRecipe($input: UpdateOriginalRecipeInput!) {
    updateOriginalRecipe(input: $input) {
      successful
      error
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        recipeRevenue
        recipeProfit
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        serves
        originalServes
        originalRecipeProfit
        totalGrams
        foodCostPercentage
        venueId
        active
        createdAt
        category
        imageUrl
        numScaledRecipeVersions
        recipeVersions {
          id
          displayName
          serves
          recipeWastage
          totalStaffTime
          totalTime
          totalGrams
          selected
          active
          original
          createdAt
          updatedAt
          isScaled
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
              averageCost100g
              averageWastagePercentage
              metrics {
                id
                type
                grams
              }
              confirmationState
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
              serves
              totalGrams
              recipeRevenue
              recipeProfit
            }
            order
          }
        }
        recipeProfitTrend {
          id
          serves
          recipeRevenue
          recipeProfit
          recipeProfitIncreasePerServe
          ingredientCost
          staffCost
          totalCost
          recipeActiveSeconds
          weeklySalesPerServe
          createdAt
          creatorId
        }
      }
    }
  }
`;
export type UpdateOriginalRecipeMutationFn = Apollo.MutationFunction<
  UpdateOriginalRecipeMutation,
  UpdateOriginalRecipeMutationVariables
>;

/**
 * __useUpdateOriginalRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateOriginalRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOriginalRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOriginalRecipeMutation, { data, loading, error }] = useUpdateOriginalRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOriginalRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOriginalRecipeMutation,
    UpdateOriginalRecipeMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateOriginalRecipeMutation,
    UpdateOriginalRecipeMutationVariables
  >(UpdateOriginalRecipeDocument, baseOptions);
}
export type UpdateOriginalRecipeMutationHookResult = ReturnType<
  typeof useUpdateOriginalRecipeMutation
>;
export type UpdateOriginalRecipeMutationResult = Apollo.MutationResult<
  UpdateOriginalRecipeMutation
>;
export type UpdateOriginalRecipeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOriginalRecipeMutation,
  UpdateOriginalRecipeMutationVariables
>;
export const UpdateRecipeDocument = gql`
  mutation UpdateRecipe($input: UpdateRecipeInput!) {
    updateRecipe(input: $input) {
      successful
      error
      recipe {
        id
        displayName
        weeklySalesPerServe
        salesPricePerServe
        recipeRevenue
        recipeProfit
        recipeProfitIncreasePerServe
        highestRecipeProfitIncreasePerServe
        serves
        originalServes
        originalRecipeProfit
        totalGrams
        foodCostPercentage
        venueId
        active
        createdAt
        category
        imageUrl
        numScaledRecipeVersions
        recipeVersions {
          id
          displayName
          serves
          recipeWastage
          totalStaffTime
          totalTime
          totalGrams
          selected
          active
          original
          createdAt
          updatedAt
          isScaled
          recipeTimeItems {
            id
            unit
            quantity
            description
            staffTime
            order
          }
          recipeIngredients {
            id
            unit
            quantity
            ingredient {
              id
              displayName
              averageCost100g
              averageWastagePercentage
              metrics {
                id
                type
                grams
              }
              confirmationState
            }
            order
          }
          recipeAsIngredients {
            id
            unit
            quantity
            recipe {
              id
              displayName
              serves
              totalGrams
              totalTime
              recipeRevenue
              recipeProfit
            }
            order
          }
        }
        recipeProfitTrend {
          id
          serves
          recipeRevenue
          recipeProfit
          recipeProfitIncreasePerServe
          ingredientCost
          staffCost
          totalCost
          recipeActiveSeconds
          weeklySalesPerServe
          createdAt
          creatorId
        }
      }
    }
  }
`;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<
  UpdateRecipeMutation,
  UpdateRecipeMutationVariables
>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecipeMutation,
    UpdateRecipeMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateRecipeMutation,
    UpdateRecipeMutationVariables
  >(UpdateRecipeDocument, baseOptions);
}
export type UpdateRecipeMutationHookResult = ReturnType<
  typeof useUpdateRecipeMutation
>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<
  UpdateRecipeMutation
>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecipeMutation,
  UpdateRecipeMutationVariables
>;
export const UpdateRecipeCategoryDocument = gql`
  mutation UpdateRecipeCategory($input: UpdateRecipeCategoryInput!) {
    updateRecipeCategory(input: $input) {
      successful
      error
    }
  }
`;
export type UpdateRecipeCategoryMutationFn = Apollo.MutationFunction<
  UpdateRecipeCategoryMutation,
  UpdateRecipeCategoryMutationVariables
>;

/**
 * __useUpdateRecipeCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeCategoryMutation, { data, loading, error }] = useUpdateRecipeCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipeCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecipeCategoryMutation,
    UpdateRecipeCategoryMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateRecipeCategoryMutation,
    UpdateRecipeCategoryMutationVariables
  >(UpdateRecipeCategoryDocument, baseOptions);
}
export type UpdateRecipeCategoryMutationHookResult = ReturnType<
  typeof useUpdateRecipeCategoryMutation
>;
export type UpdateRecipeCategoryMutationResult = Apollo.MutationResult<
  UpdateRecipeCategoryMutation
>;
export type UpdateRecipeCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecipeCategoryMutation,
  UpdateRecipeCategoryMutationVariables
>;
export const SignS3Document = gql`
  mutation SignS3($input: SignS3Input!) {
    signS3(input: $input) {
      successful
      error
      signedRequest
      url
    }
  }
`;
export type SignS3MutationFn = Apollo.MutationFunction<
  SignS3Mutation,
  SignS3MutationVariables
>;

/**
 * __useSignS3Mutation__
 *
 * To run a mutation, you first call `useSignS3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignS3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signS3Mutation, { data, loading, error }] = useSignS3Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignS3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignS3Mutation,
    SignS3MutationVariables
  >
) {
  return Apollo.useMutation<SignS3Mutation, SignS3MutationVariables>(
    SignS3Document,
    baseOptions
  );
}
export type SignS3MutationHookResult = ReturnType<typeof useSignS3Mutation>;
export type SignS3MutationResult = Apollo.MutationResult<SignS3Mutation>;
export type SignS3MutationOptions = Apollo.BaseMutationOptions<
  SignS3Mutation,
  SignS3MutationVariables
>;
export const ConfirmStocktakeDocument = gql`
  mutation ConfirmStocktake($input: ConfirmStocktakeInput!) {
    confirmStocktake(input: $input) {
      successful
      error
      errorMessage
    }
  }
`;
export type ConfirmStocktakeMutationFn = Apollo.MutationFunction<
  ConfirmStocktakeMutation,
  ConfirmStocktakeMutationVariables
>;

/**
 * __useConfirmStocktakeMutation__
 *
 * To run a mutation, you first call `useConfirmStocktakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmStocktakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmStocktakeMutation, { data, loading, error }] = useConfirmStocktakeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmStocktakeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmStocktakeMutation,
    ConfirmStocktakeMutationVariables
  >
) {
  return Apollo.useMutation<
    ConfirmStocktakeMutation,
    ConfirmStocktakeMutationVariables
  >(ConfirmStocktakeDocument, baseOptions);
}
export type ConfirmStocktakeMutationHookResult = ReturnType<
  typeof useConfirmStocktakeMutation
>;
export type ConfirmStocktakeMutationResult = Apollo.MutationResult<
  ConfirmStocktakeMutation
>;
export type ConfirmStocktakeMutationOptions = Apollo.BaseMutationOptions<
  ConfirmStocktakeMutation,
  ConfirmStocktakeMutationVariables
>;
export const CreateStocktakeDocument = gql`
  mutation CreateStocktake($input: CreateStocktakeInput!) {
    createStocktake(input: $input) {
      successful
      error
      errorMessage
      stocktake {
        id
        displayName
        totalItems
        totalRequiredItems
        creator {
          id
          firstName
          lastName
        }
        stocktakeSections {
          id
          totalItems
          section {
            id
            displayName
            totalRequiredItems
          }
          stocktakeIngredients {
            id
            quantity
            ingredient {
              id
              displayName
              ingredientProducts {
                size {
                  id
                  productCost
                  productGrams
                  unitAmount
                  unitType
                }
                supplier {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateStocktakeMutationFn = Apollo.MutationFunction<
  CreateStocktakeMutation,
  CreateStocktakeMutationVariables
>;

/**
 * __useCreateStocktakeMutation__
 *
 * To run a mutation, you first call `useCreateStocktakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStocktakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStocktakeMutation, { data, loading, error }] = useCreateStocktakeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStocktakeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStocktakeMutation,
    CreateStocktakeMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateStocktakeMutation,
    CreateStocktakeMutationVariables
  >(CreateStocktakeDocument, baseOptions);
}
export type CreateStocktakeMutationHookResult = ReturnType<
  typeof useCreateStocktakeMutation
>;
export type CreateStocktakeMutationResult = Apollo.MutationResult<
  CreateStocktakeMutation
>;
export type CreateStocktakeMutationOptions = Apollo.BaseMutationOptions<
  CreateStocktakeMutation,
  CreateStocktakeMutationVariables
>;
export const CreateStocktakeSectionDocument = gql`
  mutation CreateStocktakeSection($input: CreateStocktakeSectionInput!) {
    createStocktakeSection(input: $input) {
      successful
      error
      errorMessage
      stocktake {
        id
        displayName
        totalItems
        totalRequiredItems
        creator {
          id
          firstName
          lastName
        }
        stocktakeSections {
          id
          totalItems
          section {
            id
            displayName
            totalRequiredItems
          }
          stocktakeIngredients {
            id
            quantity
            ingredient {
              id
              displayName
              ingredientProducts {
                size {
                  id
                  productCost
                  productGrams
                  unitAmount
                  unitType
                }
                supplier {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateStocktakeSectionMutationFn = Apollo.MutationFunction<
  CreateStocktakeSectionMutation,
  CreateStocktakeSectionMutationVariables
>;

/**
 * __useCreateStocktakeSectionMutation__
 *
 * To run a mutation, you first call `useCreateStocktakeSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStocktakeSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStocktakeSectionMutation, { data, loading, error }] = useCreateStocktakeSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStocktakeSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStocktakeSectionMutation,
    CreateStocktakeSectionMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateStocktakeSectionMutation,
    CreateStocktakeSectionMutationVariables
  >(CreateStocktakeSectionDocument, baseOptions);
}
export type CreateStocktakeSectionMutationHookResult = ReturnType<
  typeof useCreateStocktakeSectionMutation
>;
export type CreateStocktakeSectionMutationResult = Apollo.MutationResult<
  CreateStocktakeSectionMutation
>;
export type CreateStocktakeSectionMutationOptions = Apollo.BaseMutationOptions<
  CreateStocktakeSectionMutation,
  CreateStocktakeSectionMutationVariables
>;
export const DeleteStocktakeSectionDocument = gql`
  mutation DeleteStocktakeSection($input: DeleteStocktakeSectionInput!) {
    deleteStocktakeSection(input: $input) {
      successful
      error
      errorMessage
    }
  }
`;
export type DeleteStocktakeSectionMutationFn = Apollo.MutationFunction<
  DeleteStocktakeSectionMutation,
  DeleteStocktakeSectionMutationVariables
>;

/**
 * __useDeleteStocktakeSectionMutation__
 *
 * To run a mutation, you first call `useDeleteStocktakeSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStocktakeSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStocktakeSectionMutation, { data, loading, error }] = useDeleteStocktakeSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStocktakeSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStocktakeSectionMutation,
    DeleteStocktakeSectionMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteStocktakeSectionMutation,
    DeleteStocktakeSectionMutationVariables
  >(DeleteStocktakeSectionDocument, baseOptions);
}
export type DeleteStocktakeSectionMutationHookResult = ReturnType<
  typeof useDeleteStocktakeSectionMutation
>;
export type DeleteStocktakeSectionMutationResult = Apollo.MutationResult<
  DeleteStocktakeSectionMutation
>;
export type DeleteStocktakeSectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteStocktakeSectionMutation,
  DeleteStocktakeSectionMutationVariables
>;
export const InventoryStocktakeDocument = gql`
  query InventoryStocktake($input: GetInventoryStocktakeInput!) {
    inventoryStocktake(input: $input) {
      successful
      error
      errorMessage
      stocktake {
        id
        active
        displayName
        totalItems
        totalRequiredItems
        status
        duplicatedId
        duplicatedName
        creator {
          id
          firstName
          lastName
        }
        stocktakeSections {
          id
          stocktakeId
          totalItems
          section {
            id
            displayName
            totalRequiredItems
          }
          stocktakeIngredients {
            id
            quantity
            order
            ingredient {
              id
              displayName
              ingredientProducts {
                id
                venueId
                size {
                  id
                  productCost
                  productGrams
                  unitAmount
                  unitType
                }
                required {
                  id
                  quantity
                  unitAmount
                  active
                }
                supplier {
                  id
                  displayName
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useInventoryStocktakeQuery__
 *
 * To run a query within a React component, call `useInventoryStocktakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryStocktakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryStocktakeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInventoryStocktakeQuery(
  baseOptions: Apollo.QueryHookOptions<
    InventoryStocktakeQuery,
    InventoryStocktakeQueryVariables
  >
) {
  return Apollo.useQuery<
    InventoryStocktakeQuery,
    InventoryStocktakeQueryVariables
  >(InventoryStocktakeDocument, baseOptions);
}
export function useInventoryStocktakeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InventoryStocktakeQuery,
    InventoryStocktakeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    InventoryStocktakeQuery,
    InventoryStocktakeQueryVariables
  >(InventoryStocktakeDocument, baseOptions);
}
export type InventoryStocktakeQueryHookResult = ReturnType<
  typeof useInventoryStocktakeQuery
>;
export type InventoryStocktakeLazyQueryHookResult = ReturnType<
  typeof useInventoryStocktakeLazyQuery
>;
export type InventoryStocktakeQueryResult = Apollo.QueryResult<
  InventoryStocktakeQuery,
  InventoryStocktakeQueryVariables
>;
export const StocktakeDocument = gql`
  query Stocktake($input: GetStocktakeInput!) {
    stocktake(input: $input) {
      successful
      error
      errorMessage
      stocktake {
        id
        active
        displayName
        totalItems
        totalRequiredItems
        status
        duplicatedId
        duplicatedName
        creator {
          id
          firstName
          lastName
        }
        stocktakeSections {
          id
          totalItems
          section {
            id
            displayName
            totalRequiredItems
          }
          stocktakeIngredients {
            id
            quantity
            order
            ingredient {
              id
              displayName
              ingredientProducts {
                id
                venueId
                size {
                  id
                  productCost
                  productGrams
                  unitAmount
                  unitType
                }
                required {
                  id
                  quantity
                  unitAmount
                  active
                }
                supplier {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useStocktakeQuery__
 *
 * To run a query within a React component, call `useStocktakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStocktakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStocktakeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStocktakeQuery(
  baseOptions: Apollo.QueryHookOptions<StocktakeQuery, StocktakeQueryVariables>
) {
  return Apollo.useQuery<StocktakeQuery, StocktakeQueryVariables>(
    StocktakeDocument,
    baseOptions
  );
}
export function useStocktakeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StocktakeQuery,
    StocktakeQueryVariables
  >
) {
  return Apollo.useLazyQuery<StocktakeQuery, StocktakeQueryVariables>(
    StocktakeDocument,
    baseOptions
  );
}
export type StocktakeQueryHookResult = ReturnType<typeof useStocktakeQuery>;
export type StocktakeLazyQueryHookResult = ReturnType<
  typeof useStocktakeLazyQuery
>;
export type StocktakeQueryResult = Apollo.QueryResult<
  StocktakeQuery,
  StocktakeQueryVariables
>;
export const StocktakesDocument = gql`
  query Stocktakes($input: GetStocktakesInput!) {
    stocktakes(input: $input) {
      successful
      error
      errorMessage
      stocktakes {
        id
        displayName
        totalItems
        totalRequiredItems
        status
        createdAt
        updatedAt
        creator {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useStocktakesQuery__
 *
 * To run a query within a React component, call `useStocktakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStocktakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStocktakesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStocktakesQuery(
  baseOptions: Apollo.QueryHookOptions<
    StocktakesQuery,
    StocktakesQueryVariables
  >
) {
  return Apollo.useQuery<StocktakesQuery, StocktakesQueryVariables>(
    StocktakesDocument,
    baseOptions
  );
}
export function useStocktakesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StocktakesQuery,
    StocktakesQueryVariables
  >
) {
  return Apollo.useLazyQuery<StocktakesQuery, StocktakesQueryVariables>(
    StocktakesDocument,
    baseOptions
  );
}
export type StocktakesQueryHookResult = ReturnType<typeof useStocktakesQuery>;
export type StocktakesLazyQueryHookResult = ReturnType<
  typeof useStocktakesLazyQuery
>;
export type StocktakesQueryResult = Apollo.QueryResult<
  StocktakesQuery,
  StocktakesQueryVariables
>;
export const UpdateStocktakeSectionDocument = gql`
  mutation UpdateStocktakeSection($input: UpdateStocktakeSectionInput!) {
    updateStocktakeSection(input: $input) {
      successful
      error
      errorMessage
      stocktake {
        id
        displayName
        totalItems
        totalRequiredItems
        creator {
          id
          firstName
          lastName
        }
        stocktakeSections {
          id
          totalItems
          section {
            id
            displayName
            totalRequiredItems
          }
          stocktakeIngredients {
            id
            quantity
            ingredient {
              id
              displayName
              ingredientProducts {
                size {
                  id
                  productCost
                  productGrams
                  unitAmount
                  unitType
                }
                supplier {
                  id
                  displayName
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type UpdateStocktakeSectionMutationFn = Apollo.MutationFunction<
  UpdateStocktakeSectionMutation,
  UpdateStocktakeSectionMutationVariables
>;

/**
 * __useUpdateStocktakeSectionMutation__
 *
 * To run a mutation, you first call `useUpdateStocktakeSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStocktakeSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStocktakeSectionMutation, { data, loading, error }] = useUpdateStocktakeSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStocktakeSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStocktakeSectionMutation,
    UpdateStocktakeSectionMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateStocktakeSectionMutation,
    UpdateStocktakeSectionMutationVariables
  >(UpdateStocktakeSectionDocument, baseOptions);
}
export type UpdateStocktakeSectionMutationHookResult = ReturnType<
  typeof useUpdateStocktakeSectionMutation
>;
export type UpdateStocktakeSectionMutationResult = Apollo.MutationResult<
  UpdateStocktakeSectionMutation
>;
export type UpdateStocktakeSectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateStocktakeSectionMutation,
  UpdateStocktakeSectionMutationVariables
>;
export const ClearPaymentMethodDocument = gql`
  mutation ClearPaymentMethod {
    clearPaymentMethod {
      successful
      error
    }
  }
`;
export type ClearPaymentMethodMutationFn = Apollo.MutationFunction<
  ClearPaymentMethodMutation,
  ClearPaymentMethodMutationVariables
>;

/**
 * __useClearPaymentMethodMutation__
 *
 * To run a mutation, you first call `useClearPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPaymentMethodMutation, { data, loading, error }] = useClearPaymentMethodMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearPaymentMethodMutation,
    ClearPaymentMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    ClearPaymentMethodMutation,
    ClearPaymentMethodMutationVariables
  >(ClearPaymentMethodDocument, baseOptions);
}
export type ClearPaymentMethodMutationHookResult = ReturnType<
  typeof useClearPaymentMethodMutation
>;
export type ClearPaymentMethodMutationResult = Apollo.MutationResult<
  ClearPaymentMethodMutation
>;
export type ClearPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  ClearPaymentMethodMutation,
  ClearPaymentMethodMutationVariables
>;
export const SavePaymentMethodDocument = gql`
  mutation SavePaymentMethod($input: SavePaymentMethodInput!) {
    savePaymentMethod(input: $input) {
      successful
      user {
        id
        accountType
        billingCycle
        subscriptionStatus
        stripeCustomerId
        stripePaymentMethodId
        stripePaymentLast4
        stripePaymentExpMonth
        stripePaymentExpYear
        email
        firstName
        lastName
        addOns {
          app
          endDate
        }
      }
      billingAddress {
        id
        location
        unit
        streetNumber
        road
        city
        shire
        state
        country
        countryShort
        postcode
        addressLine1
        addressLine2
        addressLookup
        lat
        lng
      }
      error
    }
  }
`;
export type SavePaymentMethodMutationFn = Apollo.MutationFunction<
  SavePaymentMethodMutation,
  SavePaymentMethodMutationVariables
>;

/**
 * __useSavePaymentMethodMutation__
 *
 * To run a mutation, you first call `useSavePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePaymentMethodMutation, { data, loading, error }] = useSavePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePaymentMethodMutation,
    SavePaymentMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    SavePaymentMethodMutation,
    SavePaymentMethodMutationVariables
  >(SavePaymentMethodDocument, baseOptions);
}
export type SavePaymentMethodMutationHookResult = ReturnType<
  typeof useSavePaymentMethodMutation
>;
export type SavePaymentMethodMutationResult = Apollo.MutationResult<
  SavePaymentMethodMutation
>;
export type SavePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  SavePaymentMethodMutation,
  SavePaymentMethodMutationVariables
>;
export const SubscriptionDataDocument = gql`
  query SubscriptionData {
    me {
      user {
        id
        accountType
        billingCycle
        subscriptionStatus
        stripeCustomerId
        stripePaymentMethodId
        stripePaymentLast4
        stripePaymentExpMonth
        stripePaymentExpYear
        email
        firstName
        lastName
        addOns {
          app
          endDate
        }
      }
    }
  }
`;

/**
 * __useSubscriptionDataQuery__
 *
 * To run a query within a React component, call `useSubscriptionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionDataQuery,
    SubscriptionDataQueryVariables
  >
) {
  return Apollo.useQuery<SubscriptionDataQuery, SubscriptionDataQueryVariables>(
    SubscriptionDataDocument,
    baseOptions
  );
}
export function useSubscriptionDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionDataQuery,
    SubscriptionDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SubscriptionDataQuery,
    SubscriptionDataQueryVariables
  >(SubscriptionDataDocument, baseOptions);
}
export type SubscriptionDataQueryHookResult = ReturnType<
  typeof useSubscriptionDataQuery
>;
export type SubscriptionDataLazyQueryHookResult = ReturnType<
  typeof useSubscriptionDataLazyQuery
>;
export type SubscriptionDataQueryResult = Apollo.QueryResult<
  SubscriptionDataQuery,
  SubscriptionDataQueryVariables
>;
export const UpdateBillingAddressDocument = gql`
  mutation UpdateBillingAddress($input: UpdateBillingAddressInput!) {
    updateBillingAddress(input: $input) {
      successful
      user {
        id
        accountType
        billingCycle
        subscriptionStatus
        stripeCustomerId
        stripePaymentMethodId
        stripePaymentLast4
        stripePaymentExpMonth
        stripePaymentExpYear
        email
        firstName
        lastName
        addOns {
          app
          endDate
        }
      }
      billingAddress {
        id
        location
        unit
        streetNumber
        road
        city
        shire
        state
        country
        countryShort
        postcode
        addressLine1
        addressLine2
        addressLookup
        lat
        lng
      }
      error
    }
  }
`;
export type UpdateBillingAddressMutationFn = Apollo.MutationFunction<
  UpdateBillingAddressMutation,
  UpdateBillingAddressMutationVariables
>;

/**
 * __useUpdateBillingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingAddressMutation, { data, loading, error }] = useUpdateBillingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillingAddressMutation,
    UpdateBillingAddressMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateBillingAddressMutation,
    UpdateBillingAddressMutationVariables
  >(UpdateBillingAddressDocument, baseOptions);
}
export type UpdateBillingAddressMutationHookResult = ReturnType<
  typeof useUpdateBillingAddressMutation
>;
export type UpdateBillingAddressMutationResult = Apollo.MutationResult<
  UpdateBillingAddressMutation
>;
export type UpdateBillingAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillingAddressMutation,
  UpdateBillingAddressMutationVariables
>;
export const UpdateSubscriptionDocument = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      successful
      user {
        id
        accountType
        billingCycle
        subscriptionStatus
        stripeCustomerId
        addOns {
          app
          endDate
        }
      }
      error
    }
  }
`;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >(UpdateSubscriptionDocument, baseOptions);
}
export type UpdateSubscriptionMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionMutation
>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<
  UpdateSubscriptionMutation
>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;
export const CreateSupplierDocument = gql`
  mutation CreateSupplier($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
      successful
      supplier {
        id
        creatorId
        displayName
        website
        email
        phone
        internationalPhone
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
      }
    }
  }
`;
export type CreateSupplierMutationFn = Apollo.MutationFunction<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >(CreateSupplierDocument, baseOptions);
}
export type CreateSupplierMutationHookResult = ReturnType<
  typeof useCreateSupplierMutation
>;
export type CreateSupplierMutationResult = Apollo.MutationResult<
  CreateSupplierMutation
>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;
export const SupplierDocument = gql`
  query Supplier($input: GetSupplierInput!) {
    supplier(input: $input) {
      successful
      supplier {
        id
        creatorId
        displayName
        website
        email
        phone
        internationalPhone
        supplierGroup {
          id
          displayName
        }
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
      }
    }
  }
`;

/**
 * __useSupplierQuery__
 *
 * To run a query within a React component, call `useSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupplierQuery(
  baseOptions: Apollo.QueryHookOptions<SupplierQuery, SupplierQueryVariables>
) {
  return Apollo.useQuery<SupplierQuery, SupplierQueryVariables>(
    SupplierDocument,
    baseOptions
  );
}
export function useSupplierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupplierQuery,
    SupplierQueryVariables
  >
) {
  return Apollo.useLazyQuery<SupplierQuery, SupplierQueryVariables>(
    SupplierDocument,
    baseOptions
  );
}
export type SupplierQueryHookResult = ReturnType<typeof useSupplierQuery>;
export type SupplierLazyQueryHookResult = ReturnType<
  typeof useSupplierLazyQuery
>;
export type SupplierQueryResult = Apollo.QueryResult<
  SupplierQuery,
  SupplierQueryVariables
>;
export const SupplierGroupsDocument = gql`
  query SupplierGroups {
    supplierGroups {
      successful
      error
      supplierGroups {
        id
        creatorId
        displayName
        website
        email
        phone
        internationalPhone
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
      }
    }
  }
`;

/**
 * __useSupplierGroupsQuery__
 *
 * To run a query within a React component, call `useSupplierGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupplierGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SupplierGroupsQuery,
    SupplierGroupsQueryVariables
  >
) {
  return Apollo.useQuery<SupplierGroupsQuery, SupplierGroupsQueryVariables>(
    SupplierGroupsDocument,
    baseOptions
  );
}
export function useSupplierGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SupplierGroupsQuery,
    SupplierGroupsQueryVariables
  >
) {
  return Apollo.useLazyQuery<SupplierGroupsQuery, SupplierGroupsQueryVariables>(
    SupplierGroupsDocument,
    baseOptions
  );
}
export type SupplierGroupsQueryHookResult = ReturnType<
  typeof useSupplierGroupsQuery
>;
export type SupplierGroupsLazyQueryHookResult = ReturnType<
  typeof useSupplierGroupsLazyQuery
>;
export type SupplierGroupsQueryResult = Apollo.QueryResult<
  SupplierGroupsQuery,
  SupplierGroupsQueryVariables
>;
export const SuppliersDocument = gql`
  query Suppliers {
    suppliers {
      successful
      error
      suppliers {
        id
        creatorId
        displayName
        website
        email
        phone
        internationalPhone
        supplierGroup {
          id
          displayName
        }
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
      }
    }
  }
`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>
) {
  return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(
    SuppliersDocument,
    baseOptions
  );
}
export function useSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuppliersQuery,
    SuppliersQueryVariables
  >
) {
  return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(
    SuppliersDocument,
    baseOptions
  );
}
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<
  typeof useSuppliersLazyQuery
>;
export type SuppliersQueryResult = Apollo.QueryResult<
  SuppliersQuery,
  SuppliersQueryVariables
>;
export const UpdateSupplierDocument = gql`
  mutation UpdateSupplier($input: UpdateSupplierInput!) {
    updateSupplier(input: $input) {
      successful
      supplier {
        id
        creatorId
        displayName
        website
        email
        phone
        internationalPhone
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
      }
    }
  }
`;
export type UpdateSupplierMutationFn = Apollo.MutationFunction<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSupplierMutation,
    UpdateSupplierMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateSupplierMutation,
    UpdateSupplierMutationVariables
  >(UpdateSupplierDocument, baseOptions);
}
export type UpdateSupplierMutationHookResult = ReturnType<
  typeof useUpdateSupplierMutation
>;
export type UpdateSupplierMutationResult = Apollo.MutationResult<
  UpdateSupplierMutation
>;
export type UpdateSupplierMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
>;
export const InvoicesDocument = gql`
  query Invoices {
    invoices {
      successful
      error
      invoices {
        id
        invoiceUrl
        createdUnixSec
      }
    }
  }
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>
) {
  return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    baseOptions
  );
}
export function useInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoicesQuery,
    InvoicesQueryVariables
  >
) {
  return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    baseOptions
  );
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<
  typeof useInvoicesLazyQuery
>;
export type InvoicesQueryResult = Apollo.QueryResult<
  InvoicesQuery,
  InvoicesQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      successful
      error
      user {
        id
        firstName
        lastName
        email
        phone
        internationalPhone
        isEmailConfirmed
        position
        accountType
        billingCycle
        admin
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
        createdAt
        updatedAt
        addOns {
          app
        }
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    baseOptions
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = Apollo.MutationResult<
  UpdateUserMutation
>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UsersDocument = gql`
  query Users {
    users {
      successful
      error
      users {
        id
        email
        isEmailConfirmed
        firstName
        lastName
        phone
        internationalPhone
        position
        accountType
        billingCycle
        subscriptionStatus
        lastLoginAt
        createdAt
        updatedAt
        recipes {
          creatorId
        }
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const CreateVenueDocument = gql`
  mutation CreateVenue($input: CreateVenueInput!) {
    createVenue(input: $input) {
      successful
      error
      venue {
        id
        displayName
        email
        type
        phone
        internationalPhone
        website
        weeksOpen
        weeksOpenUnit
        prepTime
        prepTimeUnit
        avgWastagePercentage
        wastageUnit
        venueCostId
        targetFoodMargin
        recommendedPrice
        recipeProfitIncreasePerYear
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
        venueCost {
          id
          chefCost
          chefCostUnit
          councilCost
          councilCostUnit
          insuranceCost
          insuranceCostUnit
          rentCost
          rentCostUnit
          powerCost
          powerCostUnit
          waterCost
          waterCostUnit
        }
      }
    }
  }
`;
export type CreateVenueMutationFn = Apollo.MutationFunction<
  CreateVenueMutation,
  CreateVenueMutationVariables
>;

/**
 * __useCreateVenueMutation__
 *
 * To run a mutation, you first call `useCreateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVenueMutation, { data, loading, error }] = useCreateVenueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVenueMutation,
    CreateVenueMutationVariables
  >
) {
  return Apollo.useMutation<CreateVenueMutation, CreateVenueMutationVariables>(
    CreateVenueDocument,
    baseOptions
  );
}
export type CreateVenueMutationHookResult = ReturnType<
  typeof useCreateVenueMutation
>;
export type CreateVenueMutationResult = Apollo.MutationResult<
  CreateVenueMutation
>;
export type CreateVenueMutationOptions = Apollo.BaseMutationOptions<
  CreateVenueMutation,
  CreateVenueMutationVariables
>;
export const DeleteVenueDocument = gql`
  mutation DeleteVenue($input: DeleteVenueInput!) {
    deleteVenue(input: $input) {
      successful
      error
      venue {
        id
        displayName
        email
        type
        phone
        internationalPhone
        website
        weeksOpen
        weeksOpenUnit
        prepTime
        prepTimeUnit
        avgWastagePercentage
        wastageUnit
        venueCostId
        targetFoodMargin
        recommendedPrice
        recipeProfitIncreasePerYear
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
        venueCost {
          id
          chefCost
          chefCostUnit
          councilCost
          councilCostUnit
          insuranceCost
          insuranceCostUnit
          rentCost
          rentCostUnit
          powerCost
          powerCostUnit
          waterCost
          waterCostUnit
        }
      }
    }
  }
`;
export type DeleteVenueMutationFn = Apollo.MutationFunction<
  DeleteVenueMutation,
  DeleteVenueMutationVariables
>;

/**
 * __useDeleteVenueMutation__
 *
 * To run a mutation, you first call `useDeleteVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVenueMutation, { data, loading, error }] = useDeleteVenueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVenueMutation,
    DeleteVenueMutationVariables
  >
) {
  return Apollo.useMutation<DeleteVenueMutation, DeleteVenueMutationVariables>(
    DeleteVenueDocument,
    baseOptions
  );
}
export type DeleteVenueMutationHookResult = ReturnType<
  typeof useDeleteVenueMutation
>;
export type DeleteVenueMutationResult = Apollo.MutationResult<
  DeleteVenueMutation
>;
export type DeleteVenueMutationOptions = Apollo.BaseMutationOptions<
  DeleteVenueMutation,
  DeleteVenueMutationVariables
>;
export const SetSelectedVenueDocument = gql`
  mutation SetSelectedVenue($input: SetSelectedVenueInput!) {
    setSelectedVenue(input: $input) {
      successful
      error
      userVenue {
        status
        selected
        totalActiveSeconds
        venueId
        venue {
          id
          displayName
          email
          recipeProfitIncreasePerYear
          address {
            id
            location
            unit
            streetNumber
            road
            city
            shire
            state
            country
            countryShort
            postcode
            addressLine1
            addressLine2
            addressLookup
            lat
            lng
          }
          venueCost {
            id
            chefCost
            chefCostUnit
            councilCost
            councilCostUnit
            foodWastePercentage
            insuranceCost
            insuranceCostUnit
            powerCost
            powerCostUnit
            rentCost
            rentCostUnit
            waterCost
            waterCostUnit
          }
          recipes {
            id
            active
            numScaledRecipeVersions
            recipeProfit
            recipeRevenue
            serves
            totalGrams
          }
        }
      }
    }
  }
`;
export type SetSelectedVenueMutationFn = Apollo.MutationFunction<
  SetSelectedVenueMutation,
  SetSelectedVenueMutationVariables
>;

/**
 * __useSetSelectedVenueMutation__
 *
 * To run a mutation, you first call `useSetSelectedVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSelectedVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSelectedVenueMutation, { data, loading, error }] = useSetSelectedVenueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSelectedVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSelectedVenueMutation,
    SetSelectedVenueMutationVariables
  >
) {
  return Apollo.useMutation<
    SetSelectedVenueMutation,
    SetSelectedVenueMutationVariables
  >(SetSelectedVenueDocument, baseOptions);
}
export type SetSelectedVenueMutationHookResult = ReturnType<
  typeof useSetSelectedVenueMutation
>;
export type SetSelectedVenueMutationResult = Apollo.MutationResult<
  SetSelectedVenueMutation
>;
export type SetSelectedVenueMutationOptions = Apollo.BaseMutationOptions<
  SetSelectedVenueMutation,
  SetSelectedVenueMutationVariables
>;
export const UpdateVenueDocument = gql`
  mutation UpdateVenue($input: UpdateVenueInput!) {
    updateVenue(input: $input) {
      successful
      error
      venue {
        id
        displayName
        email
        type
        phone
        internationalPhone
        website
        weeksOpen
        weeksOpenUnit
        prepTime
        prepTimeUnit
        avgWastagePercentage
        wastageUnit
        venueCostId
        targetFoodMargin
        recommendedPrice
        recipeProfitIncreasePerYear
        address {
          id
          location
          unit
          streetNumber
          road
          city
          shire
          state
          country
          countryShort
          postcode
          addressLine1
          addressLine2
          addressLookup
          lat
          lng
        }
        venueCost {
          id
          chefCost
          chefCostUnit
          councilCost
          councilCostUnit
          insuranceCost
          insuranceCostUnit
          rentCost
          rentCostUnit
          powerCost
          powerCostUnit
          waterCost
          waterCostUnit
        }
      }
    }
  }
`;
export type UpdateVenueMutationFn = Apollo.MutationFunction<
  UpdateVenueMutation,
  UpdateVenueMutationVariables
>;

/**
 * __useUpdateVenueMutation__
 *
 * To run a mutation, you first call `useUpdateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueMutation, { data, loading, error }] = useUpdateVenueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVenueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVenueMutation,
    UpdateVenueMutationVariables
  >
) {
  return Apollo.useMutation<UpdateVenueMutation, UpdateVenueMutationVariables>(
    UpdateVenueDocument,
    baseOptions
  );
}
export type UpdateVenueMutationHookResult = ReturnType<
  typeof useUpdateVenueMutation
>;
export type UpdateVenueMutationResult = Apollo.MutationResult<
  UpdateVenueMutation
>;
export type UpdateVenueMutationOptions = Apollo.BaseMutationOptions<
  UpdateVenueMutation,
  UpdateVenueMutationVariables
>;
export const VenueDocument = gql`
  query Venue($input: GetVenueInput!) {
    venue(input: $input) {
      successful
      error
      userVenue {
        status
        selected
        totalActiveSeconds
        venueId
        venue {
          id
          active
          displayName
          email
          phone
          internationalPhone
          personal
          prepTime
          prepTimeUnit
          avgWastagePercentage
          wastageUnit
          type
          website
          weeksOpen
          weeksOpenUnit
          creatorId
          targetFoodMargin
          recommendedPrice
          recipeProfitIncreasePerYear
          address {
            id
            location
            unit
            streetNumber
            road
            city
            shire
            state
            country
            countryShort
            postcode
            addressLine1
            addressLine2
            addressLookup
            lat
            lng
          }
          venueCost {
            id
            chefCost
            chefCostUnit
            councilCost
            councilCostUnit
            foodWastePercentage
            insuranceCost
            insuranceCostUnit
            powerCost
            powerCostUnit
            rentCost
            rentCostUnit
            waterCost
            waterCostUnit
          }
          recipes {
            id
            active
            numScaledRecipeVersions
            recipeProfit
            recipeRevenue
            serves
            totalGrams
          }
          ingredientProducts {
            id
            ingredientId
            sizeId
            brandId
            supplierId
            wastageId
            recipeCount
            ingredient {
              id
              displayName
              metrics {
                type
                grams
              }
              averageCost100g
              averageWastagePercentage
              creatorId
              confirmationState
            }
            size {
              id
              productCost
              productGrams
            }
            brand {
              id
              displayName
              email
              website
              phone
            }
            supplier {
              id
              displayName
              email
              website
              phone
              address {
                id
                location
                unit
                streetNumber
                road
                city
                shire
                state
                country
                countryShort
                postcode
                addressLine1
                addressLine2
                addressLookup
                lat
                lng
              }
            }
            wastage {
              id
              unitType
              amount
            }
          }
        }
      }
    }
  }
`;

/**
 * __useVenueQuery__
 *
 * To run a query within a React component, call `useVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVenueQuery(
  baseOptions: Apollo.QueryHookOptions<VenueQuery, VenueQueryVariables>
) {
  return Apollo.useQuery<VenueQuery, VenueQueryVariables>(
    VenueDocument,
    baseOptions
  );
}
export function useVenueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VenueQuery, VenueQueryVariables>
) {
  return Apollo.useLazyQuery<VenueQuery, VenueQueryVariables>(
    VenueDocument,
    baseOptions
  );
}
export type VenueQueryHookResult = ReturnType<typeof useVenueQuery>;
export type VenueLazyQueryHookResult = ReturnType<typeof useVenueLazyQuery>;
export type VenueQueryResult = Apollo.QueryResult<
  VenueQuery,
  VenueQueryVariables
>;
export const VenueProfitTrendDocument = gql`
  query VenueProfitTrend($input: GetVenueInput!) {
    venue(input: $input) {
      successful
      error
      userVenue {
        status
        selected
        totalActiveSeconds
        venueId
        venue {
          id
          recipeProfitIncreasePerYear
          venueProfitTrends {
            id
            totalActiveSeconds
            recipeProfitIncreasePerYear
            createdAt
          }
        }
      }
    }
  }
`;

/**
 * __useVenueProfitTrendQuery__
 *
 * To run a query within a React component, call `useVenueProfitTrendQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueProfitTrendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueProfitTrendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVenueProfitTrendQuery(
  baseOptions: Apollo.QueryHookOptions<
    VenueProfitTrendQuery,
    VenueProfitTrendQueryVariables
  >
) {
  return Apollo.useQuery<VenueProfitTrendQuery, VenueProfitTrendQueryVariables>(
    VenueProfitTrendDocument,
    baseOptions
  );
}
export function useVenueProfitTrendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VenueProfitTrendQuery,
    VenueProfitTrendQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    VenueProfitTrendQuery,
    VenueProfitTrendQueryVariables
  >(VenueProfitTrendDocument, baseOptions);
}
export type VenueProfitTrendQueryHookResult = ReturnType<
  typeof useVenueProfitTrendQuery
>;
export type VenueProfitTrendLazyQueryHookResult = ReturnType<
  typeof useVenueProfitTrendLazyQuery
>;
export type VenueProfitTrendQueryResult = Apollo.QueryResult<
  VenueProfitTrendQuery,
  VenueProfitTrendQueryVariables
>;
export const VenuesDocument = gql`
  query Venues {
    venueStats {
      successful
      error
      venues {
        id
        displayName
        email
        type
        phone
        internationalPhone
        website
        weeksOpen
        weeksOpenUnit
        prepTime
        prepTimeUnit
        avgWastagePercentage
        wastageUnit
        personal
        active
        creatorId
        createdAt
        updatedAt
        targetFoodMargin
        recommendedPrice
        recipeProfitIncreasePerYear
        address {
          id
          addressLookup
        }
        venueCost {
          id
          chefCost
          chefCostUnit
          councilCost
          councilCostUnit
          foodWastePercentage
          insuranceCost
          insuranceCostUnit
          powerCost
          powerCostUnit
          rentCost
          rentCostUnit
          waterCost
          waterCostUnit
        }
        recipes {
          id
          recipeProfit
          recipeRevenue
          serves
          totalGrams
          createdAt
          updatedAt
          numScaledRecipeVersions
        }
      }
    }
  }
`;

/**
 * __useVenuesQuery__
 *
 * To run a query within a React component, call `useVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVenuesQuery(
  baseOptions?: Apollo.QueryHookOptions<VenuesQuery, VenuesQueryVariables>
) {
  return Apollo.useQuery<VenuesQuery, VenuesQueryVariables>(
    VenuesDocument,
    baseOptions
  );
}
export function useVenuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VenuesQuery, VenuesQueryVariables>
) {
  return Apollo.useLazyQuery<VenuesQuery, VenuesQueryVariables>(
    VenuesDocument,
    baseOptions
  );
}
export type VenuesQueryHookResult = ReturnType<typeof useVenuesQuery>;
export type VenuesLazyQueryHookResult = ReturnType<typeof useVenuesLazyQuery>;
export type VenuesQueryResult = Apollo.QueryResult<
  VenuesQuery,
  VenuesQueryVariables
>;
