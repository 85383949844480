import React, { FC, useContext, useState } from 'react';
import * as S from './AddSalesDataForm.styles';
import { LayoutColumn } from '../../../../shared/layout';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../../../shared/formElements';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  isEmpty,
  convertCostCleanly,
  getRecipeData,
} from '../../../../../utils/helper';
import {
  validateNumberValues,
  validatePositiveNumberValues,
} from '../../../../../utils/helper/validation';
import {
  Buttons,
  LinkStyled,
} from '../../../../shared/layout/layoutOverlay/LayoutOverlay.styles';
import { Button } from '../../../../shared/button';
import { DotsLoading } from '../../../../shared/loading';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { IRecipeDataArgs } from '../../../../../utils/clientServerShared/getRecipeData.types';
import {
  AccountType,
  RecipeDocument,
  useUpdateRecipeMutation,
} from '../../../../../generated/graphql';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { ErrorMessage } from '../../../../shared/typefaces/Typefaces.styles';
import { useActiveTimeTracker } from '../../../../../utils/customHooks/useActiveTimeTracker';

interface IAddSalesDataFormProps {
  recommendedPrice: number;
  recipeDataArgs: IRecipeDataArgs;
  getTotalRecipeActiveSeconds: () => number;
}

export const AddSalesDataForm: FC<IAddSalesDataFormProps> = ({
  recommendedPrice,
  recipeDataArgs,
  getTotalRecipeActiveSeconds,
}) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { account, selectedRecipe, selectedVenueObject, dispatch } = useContext(
    LayoutContext
  );
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const [UpdateRecipeMutation, updateRecipe] = useUpdateRecipeMutation();

  const { errors, control, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      salesPricePerServe: '',
      weeklySalesPerServe: '',
    },
  });

  const handleLinkClick = () => {
    dispatch({ type: 'CLOSE_OVERLAY' });
  };

  const onSubmit = async (formData: {
    salesPricePerServe: any;
    weeklySalesPerServe: any;
  }) => {
    try {
      const recipeData = getRecipeData({
        ...recipeDataArgs,
        recipe: {
          ...recipeDataArgs.recipe!,
          salesPricePerServe: Number(formData.salesPricePerServe),
          weeklySalesPerServe: Number(formData.weeklySalesPerServe),
        },
      });

      if (!recipeData) return;

      const input = {
        id: selectedRecipe!,
        venueId: selectedVenueObject?.id!,
        weeklySalesPerServe: recipeData?.weeklySalesPerServe || 0,
        salesPricePerServe: recipeData?.salesPricePerServe || 0,
        serves: recipeData.serves || 0,
        recipeRevenue: recipeData?.recipeRevenue || 0,
        recipeProfit: recipeData?.recipeProfit || 0,
        recipeProfitIncreasePerServe: recipeData?.profitIncreasePerServe || 0,
        highestRecipeProfitIncreasePerServe:
          recipeData?.highestProfitIncreasePerServe || 0,
        foodCostPercentage: recipeData?.foodCostPercentage || 0,
        totalActiveSeconds: getTotalActiveSeconds(),
        recipeActiveSeconds: getTotalRecipeActiveSeconds(),
      };

      const response = await UpdateRecipeMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: RecipeDocument,
            variables: {
              input: {
                venueId: selectedVenueObject?.id!,
                recipeId: selectedRecipe!,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      if (response.data?.updateRecipe.successful) {
        dispatch({ type: 'SET_NEW_RECIPE', payload: false });
        dispatch({ type: 'TOGGLE_RECIPE_UPDATED' });
        dispatch({ type: 'CLOSE_OVERLAY' });
      }
    } catch (err) {
      console.error(err);
      setErrorMessages(['Something went wrong']);
    }
  };

  return (
    <S.Container>
      <S.Form>
        <LayoutColumn>
          <Controller
            as={<Input />}
            type="number"
            label="Sales Price Per Serve"
            name="salesPricePerServe"
            control={control}
            errorText={
              errors.salesPricePerServe && errors.salesPricePerServe.message
            }
            startsymbol={<S.Dollar>$</S.Dollar>}
            defaultValue=""
            helperText={
              !isEmpty(recommendedPrice) &&
              `Minimum Sale Price: ${convertCostCleanly(recommendedPrice)}`
            }
          />
          <Controller
            as={<Input />}
            type="number"
            label="Weekly Sales"
            name="weeklySalesPerServe"
            control={control}
            errorText={
              errors.weeklySalesPerServe && errors.weeklySalesPerServe.message
            }
            defaultValue=""
          />
        </LayoutColumn>
      </S.Form>
      <Buttons>
        <Button
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={updateRecipe.loading}
        >
          <DotsLoading
            text={(loading) =>
              loading
                ? 'Loading'
                : overlayConstants.addSalesDataRecipeResults.buttonText
            }
            isLoading={updateRecipe.loading}
            size="small"
            lineHeight={10}
            noMargin
          />
        </Button>
        {errorMessages.map((err, i) => (
          <ErrorMessage key={i}>{err}</ErrorMessage>
        ))}
        <LinkStyled
          to={account?.type === AccountType.Guest ? '/' : '/recipes'}
          onClick={() => handleLinkClick()}
        >
          {overlayConstants.addSalesDataRecipeResults.linkText}
        </LinkStyled>
      </Buttons>
    </S.Container>
  );
};

const validationSchema = Yup.object().shape({
  salesPricePerServe: Yup.string()
    .required('Sales Price is required')
    .typeError('Sales Price is required')
    .test('numberTest', 'Sales Price is not a number', (value) =>
      validateNumberValues(value)
    )
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),
  weeklySalesPerServe: Yup.string()
    .required('Weekly Sales is required')
    .typeError('Weekly Sales is required')
    .test('numberTest', 'Weekly Sales is not a number', (value) =>
      validateNumberValues(value)
    )
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),
});
