import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  AccountType,
  RecipeDocument,
  useSetOriginalRecipeVersionMutation,
  useSetSelectedRecipeVersionMutation,
} from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import {
  convertCostCleanly,
  getCompareRecipeData,
  isEmpty,
  sortByNestedObject,
} from '../../../../../utils/helper';
import {
  blurContent,
  convertPercentageCleanly,
  getPercentageDifference,
} from '../../../../../utils/helper/numbers';
import { Button } from '../../../../shared/button';
import { Card, Content, Header } from '../../../../shared/card';
import {
  ICompareProps,
  ICompareStateProps,
} from '../../../../shared/recipe/recipe.types';
import {
  ColTitle,
  H3,
  Label,
  Span,
} from '../../../../shared/typefaces/Typefaces.styles';
import { Copy, Edit, Share, Tick } from './../../../../shared/icons';
import {
  Col,
  CTAButton,
  HeaderRow,
  Icon,
  Row,
  Table,
  ToolTipIcon,
} from './Compare.styles';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import useResizeDimensions from '../../../../../utils/customHooks/useResizeDimensions';
import {
  checkIfAccountIsNotComplete,
  useRestrictionCheck,
  userHasCalculatorAddon,
} from '../../../../../utils/helper/account';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';

const Compare: FC<ICompareProps> = ({
  data,
  venue,
  updateRecipeOnSelect,
  updateOriginalRecipeFromTemplate,
}) => {
  const {
    appWidth,
    account,
    user,
    selectedRecipe,
    selectedRecipeVersion,
    selectedVenueObject,
    toolTips,
    newRecipe,
    dispatch,
  } = useContext(LayoutContext);

  const [state, setState] = useState<ICompareStateProps>({
    originalVersionId: '',
    originalVersionServeProfit: undefined,
  });
  const [
    setSelectedRecipeVersionMutation,
    { loading },
  ] = useSetSelectedRecipeVersionMutation();
  const [
    setOrignalRecipeVersionMutation,
  ] = useSetOriginalRecipeVersionMutation();
  const [newSelectedRecipeVersion, setNewSelectedRecipeVersion] = useState('');
  const { originalVersionId, originalVersionServeProfit } = state;
  const sortedRecipeVersions = sortByNestedObject(
    'displayName',
    data?.data?.recipe?.recipe?.recipeVersions
  );
  const showRestrictionPopup = useRestrictionPopup();
  const checkRecipeProfitRestrictions = useRestrictionCheck([
    'CALCULATE_RECIPE_PROFIT',
  ]);
  const checkFoodCostRestrictions = useRestrictionCheck(['FOOD_COST']);
  const checkDuplicateRecipeRestrictions = useRestrictionCheck([
    'MAX_RECIPE_VERSIONS',
  ]);
  const pageWidthRef = useRef<HTMLDivElement>(null);
  const pageDimensions = useResizeDimensions(pageWidthRef);
  const headingsRef = useRef<HTMLUListElement>(null);
  const headingsDimensions = useResizeDimensions(headingsRef, toolTips.resize);

  const profitDifferenceRef = useRef<HTMLLIElement>(null);
  const profitDifferenceDimensions = useResizeDimensions(profitDifferenceRef);
  const foodCostRef = useRef<HTMLLIElement>(null);
  const foodCostDimensions = useResizeDimensions(foodCostRef);
  const profitRef = useRef<HTMLLIElement>(null);
  const profitDimensions = useResizeDimensions(profitRef);

  const { watch } = useFormContext();

  useEffect(() => {
    if (loading && selectedRecipeVersion !== newSelectedRecipeVersion) {
      dispatch({
        type: 'SELECT_RECIPE_VERSION',
        payload: newSelectedRecipeVersion,
      });
    }
  }, [loading, newSelectedRecipeVersion, selectedRecipeVersion, dispatch]);

  useEffect(() => {
    if (
      data?.data?.recipe.successful &&
      data?.data?.recipe?.recipe?.recipeVersions.length !== 0 &&
      data?.data?.recipe?.recipe?.recipeVersions.length !== 1
    ) {
      const originalVersion = data?.data?.recipe?.recipe?.recipeVersions.find(
        (version) => version.original
      );

      const setOriginRecipe = async (newOriginalVerisionId: string) => {
        try {
          const response = await setOrignalRecipeVersionMutation({
            variables: {
              input: {
                recipeId: selectedRecipe!,
                recipeVersionId: newOriginalVerisionId,
              },
            },
            refetchQueries: [
              {
                query: RecipeDocument,
                variables: {
                  input: {
                    venueId: selectedVenueObject?.id!,
                    recipeId: selectedRecipe!,
                  },
                },
              },
            ],
            awaitRefetchQueries: true,
          });
          if (response.data?.setOriginalRecipeVersion.successful) {
            setState({ ...state, originalVersionId: originalVersionId });
          }
        } catch (err) {
          console.log('err ###', err);
        }
      };

      if (!originalVersion) {
        const recipeVersions = data?.data?.recipe?.recipe?.recipeVersions;
        if (recipeVersions) {
          const createdAtTimestamps = recipeVersions?.map((version) =>
            Number(version.createdAt)
          );

          const originalCreatedAtTimestamp = Math.min(
            ...createdAtTimestamps
          ).toString();

          const newOriginalVerision = recipeVersions?.find(
            (version) => version.createdAt === originalCreatedAtTimestamp
          );

          const originalVersionId = newOriginalVerision
            ? newOriginalVerision.id
            : recipeVersions[0].id;
          setOriginRecipe(originalVersionId);
        }
      } else {
        if (
          isEmpty(originalVersionId) &&
          !isEmpty(sortedRecipeVersions && !newRecipe)
        ) {
          setState({ ...state, originalVersionId: originalVersion.id });
        }
      }
    }
  }, [
    data,
    selectedRecipe,
    selectedVenueObject?.id,
    state,
    newRecipe,
    originalVersionId,
    sortedRecipeVersions,
    setOrignalRecipeVersionMutation,
  ]);

  const restrictionCheck = checkRecipeProfitRestrictions({
    data:
      account?.type === AccountType.HeadChef ||
      account?.type === AccountType.Owner ||
      account?.type === AccountType.Feedback ||
      account?.type === AccountType.RecipeRevenue,
  });
  const restrictionFoodCostCheck = checkFoodCostRestrictions({
    data: account?.type !== AccountType.Registered,
  });
  const { validUserCalculatorAddOn } = userHasCalculatorAddon(account?.addOns);
  const showProfitContent = validUserCalculatorAddOn || restrictionCheck.isPass;
  const showFoodCostContent =
    validUserCalculatorAddOn || restrictionFoodCostCheck.isPass;

  if (!data) {
    return <H3>Loading..</H3>;
  }

  const handleRecipeRevenueUpgrade = () => {
    showRestrictionPopup(
      restrictionPopupMessages.cannotDisplayRecipeVersionProfit
    );
  };

  const handleEditRecipe = (recipeVersionId, showEditIcon: boolean) => {
    if (showEditIcon) {
      if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
        dispatch({
          type: 'SET_OVERLAY',
          payload: overlayConstants.noAccountEditRecipe,
        });
      }
      dispatch({ type: 'SLIDER_PAGE', payload: 'editRecipe' });
      dispatch({ type: 'SELECT_RECIPE_VERSION', payload: recipeVersionId });
      dispatch({ type: 'DUPLICATE_RECIPE', payload: false });
      dispatch({ type: 'SCALE_RECIPE', payload: false });
      if (toolTips.editRecipe) {
        dispatch({
          type: 'SET_TOOL_TIPS',
          payload: { editRecipe: false, recipeIngredients: false },
        });
      }
    }
  };

  const handleDuplicateRecipe = () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      const noAccountMessage = sortedRecipeVersions
        ? sortedRecipeVersions?.length > 1
          ? overlayConstants.noAccountDuplicateRecipe
          : overlayConstants.noAccountEditRecipe
        : overlayConstants.noAccountEditRecipe;
      dispatch({
        type: 'SET_OVERLAY',
        payload: noAccountMessage,
      });
    }
    const currentRecipeVersions = sortedRecipeVersions?.filter((v) => v.active);
    const totalRecipeVersions = currentRecipeVersions?.length || 0;

    const restrictionCheck = checkDuplicateRecipeRestrictions({
      // Adding one recipe version
      numRecipeVersions: totalRecipeVersions + 1,
    });

    if (restrictionCheck.isPass) {
      updateOriginalRecipeFromTemplate();
      dispatch({ type: 'DUPLICATE_RECIPE', payload: true });
      dispatch({ type: 'SCALE_RECIPE', payload: false });
      dispatch({ type: 'SLIDER_PAGE', payload: 'editDuplicateRecipe' });
    } else {
      showRestrictionPopup(
        restrictionPopupMessages.cannotSelectRecipeVersionDueToMaxRecipeVersions
      );
    }
  };

  const handleSelectRecipe = async (versionId: string) => {
    try {
      setNewSelectedRecipeVersion(versionId);
      const response = await setSelectedRecipeVersionMutation({
        variables: {
          input: {
            recipeId: selectedRecipe!,
            recipeVersionId: versionId,
          },
        },
        refetchQueries: [
          {
            query: RecipeDocument,
            variables: {
              input: {
                venueId: selectedVenueObject?.id!,
                recipeId: selectedRecipe!,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      if (response.data?.setSelectedRecipeVersion.successful) {
        if (selectedRecipeVersion !== versionId) {
          updateRecipeOnSelect(versionId);
        }
      }
    } catch (err) {
      console.log('err ###', err);
    }
  };

  const onClickHasOnlyProcessTimeIcon = (versionName: string) => {
    console.log('hit');
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: `${versionName} Profit Difference`,
        content:
          'Quick note, this recipe does not have any staff costs associated to it & this might be miss respresenting the profit difference.',
        buttonText: 'Got it',
        pageWidth: pageDimensions.width,
        yAxis: headingsDimensions.top,
        xAxis: headingsDimensions.width,
      },
    });
  };

  const onProfitDifferenceClick = () => {
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: `Profit Difference`,
        content:
          'This is the difference betweek your Original Recipe Version and the Duplicated Recipe Version shown by percentage',
        buttonText: 'Got it',
        pageWidth: pageDimensions.width,
        yAxis: profitDifferenceDimensions.top,
        xAxis: profitDifferenceDimensions.width,
      },
    });
  };

  const onFoodCostClick = () => {
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: `Food Cost`,
        content:
          'The cost of ingredients to make this recipe as a percentage compared to the sales price',
        buttonText: 'Got it',
        pageWidth: pageDimensions.width,
        yAxis: foodCostDimensions.top,
        xAxis: foodCostDimensions.width,
      },
    });
  };

  const onProfitClick = () => {
    dispatch({
      type: 'SHOW_TOOL_TIP',
      payload: {
        heading: `Profit Per Serve`,
        content: 'Total profit per serve in dollars',
        buttonText: 'Got it',
        pageWidth: pageDimensions.width,
        yAxis: profitDimensions.top,
        xAxis: profitDimensions.width,
      },
    });
  };

  const getTableHeader = () => {
    if (appWidth !== 0 && appWidth < theme.mQ.mobileD) {
      return (
        <HeaderRow>
          <Col>
            <Label>Recipe Version</Label>
          </Col>
          <Col>
            <Label>
              <Span color="faded" fontSize="small">
                (per serve)
              </Span>{' '}
              Profit
            </Label>
          </Col>
          <Col>
            <Label className="last">Serves</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.mobileL) {
      return (
        <HeaderRow>
          <Col>
            <Label>Recipe Version</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <Label>
              <Span color="faded" fontSize="small">
                (per serve)
              </Span>{' '}
              Profit
            </Label>
          </Col>
          <Col>
            <Label className="last">Serves</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return (
        <HeaderRow>
          <Col>
            <Label>Version Name</Label>
          </Col>
          <Col>
            <Label>Food Cost</Label>
          </Col>
          <Col>
            <Label>
              <Span color="faded" fontSize="small">
                (per serve)
              </Span>{' '}
              Profit
            </Label>
          </Col>
          <Col>
            <Label className="last">Serves</Label>
          </Col>
        </HeaderRow>
      );
    } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
      return (
        <HeaderRow>
          <Col>
            <Label>Version Name</Label>
          </Col>
          <Col onClick={onProfitDifferenceClick} ref={profitDifferenceRef}>
            <Label className="hasToolTipIcon">Profit Difference</Label>
          </Col>
          <Col onClick={onFoodCostClick} ref={foodCostRef}>
            <Label className="hasToolTipIcon">Food Cost</Label>
          </Col>
          <Col onClick={onProfitClick} ref={profitRef}>
            <Label className="hasToolTipIcon">
              <Span color="faded" fontSize="small">
                (per serve)
              </Span>{' '}
              Profit
            </Label>
          </Col>
          <Col>
            <Label className="last">Serves</Label>
          </Col>
        </HeaderRow>
      );
    } else {
      return (
        <HeaderRow>
          <Col />
          <Col>
            <Label>Recipe Version Name</Label>
          </Col>
          <Col onClick={onProfitDifferenceClick} ref={profitDifferenceRef}>
            <Label className="hasToolTipIcon">Profit Difference</Label>
          </Col>
          <Col onClick={onFoodCostClick} ref={foodCostRef}>
            <Label className="hasToolTipIcon">Food Cost</Label>
          </Col>
          <Col onClick={onProfitClick} ref={profitRef}>
            <Label className="hasToolTipIcon">
              <Span color="faded" fontSize="small">
                (per serve)
              </Span>{' '}
              Profit
            </Label>
          </Col>
          <Col>
            <Label className="last">Serves</Label>
          </Col>
        </HeaderRow>
      );
    }
  };

  const getTableRows = (col, idx) => {
    if (col.active) {
      const recipeCompareData = getCompareRecipeData({
        recipe: data,
        venue,
        selectedVersion: col,
        salesPricePerServeFormData: watch('salesPricePerServe'),
      });

      if (
        recipeCompareData.original &&
        originalVersionServeProfit !== 0 &&
        recipeCompareData.salesPricePerServe &&
        recipeCompareData.weeklySalesPerServe &&
        originalVersionServeProfit !== recipeCompareData.profitPerServe
      ) {
        setState({
          ...state,
          originalVersionServeProfit: recipeCompareData.profitPerServe || 0,
        });
      }

      const difference = getPercentageDifference(
        originalVersionServeProfit,
        recipeCompareData.profitPerServe
      );

      const differenceIsPositive =
        !!difference && difference !== 0 && Math.sign(difference) === 1;

      const editRecipe =
        process.env.REACT_APP_EDIT_ORIGINAL_RECIPE === 'true' ||
        !recipeCompareData.original ||
        account?.type === AccountType.RecipeRevenue;

      const selectedVersion =
        recipeCompareData.versionId === selectedRecipeVersion;

      const getDifferencePercentage = () => {
        // if (
        //   recipeCompareData.versionHasOnlyProcessTime &&
        //   recipeCompareData.totalTimeCost === 0
        // ) {
        //   return (
        //     <ToolTipIcon
        //       onClick={() =>
        //         onClickHasOnlyProcessTimeIcon(recipeCompareData.versionName)
        //       }
        //     />
        //   );
        // }

        const showTip =
          recipeCompareData.versionHasOnlyProcessTime &&
          recipeCompareData.totalTimeCost === 0 ? (
            <ToolTipIcon
              className="showTip"
              onClick={() =>
                onClickHasOnlyProcessTimeIcon(recipeCompareData.versionName)
              }
            />
          ) : null;

        const onClick = () =>
          !showProfitContent
            ? handleRecipeRevenueUpgrade()
            : showTip
            ? onClickHasOnlyProcessTimeIcon(recipeCompareData.versionName)
            : undefined;

        return (
          <>
            <Span
              className={`${
                !showProfitContent &&
                !recipeCompareData.original &&
                'blur hasSeeIcon'
              }`}
              color={
                differenceIsPositive && !recipeCompareData.original
                  ? 'secondary'
                  : 'faded'
              }
              fontWeight={
                differenceIsPositive && !recipeCompareData.original
                  ? 'medium'
                  : 'regular'
              }
              onClick={onClick}
            >
              {recipeCompareData.original
                ? 'original'
                : showProfitContent
                ? convertPercentageCleanly(difference || 0)
                : blurContent(convertPercentageCleanly(difference || 0))}
            </Span>
            {showTip}
          </>
        );
      };

      const differencePercentage = getDifferencePercentage();
      const foodCostPercentage =
        recipeCompareData.profitPerServe !== 0 && showFoodCostContent
          ? convertPercentageCleanly(recipeCompareData.foodCostPercentage)
          : blurContent(
              convertPercentageCleanly(recipeCompareData.foodCostPercentage)
            );

      if (appWidth !== 0 && appWidth < theme.mQ.mobileD) {
        return (
          <Row
            key={col.id}
            onClick={() => handleSelectRecipe(col.id)}
            ref={headingsRef}
          >
            <Col
              onClick={() =>
                handleEditRecipe(recipeCompareData.versionId, editRecipe)
              }
            >
              {editRecipe && (
                <Icon>
                  <Edit size="small" color="grey" faded />
                </Icon>
              )}
            </Col>
            <Col className="recipeVersion">
              <ColTitle selected={selectedVersion}>
                {recipeCompareData.versionName}{' '}
                {selectedVersion && <Tick size="smaller" color="secondary" />}
              </ColTitle>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showProfitContent && handleRecipeRevenueUpgrade()
                }
              >
                {showProfitContent
                  ? convertCostCleanly(recipeCompareData.profitPerServe)
                  : blurContent(
                      convertCostCleanly(recipeCompareData.profitPerServe)
                    )}
              </Span>
            </Col>
            <Col>
              <Span color="grey" className="serves">
                * {recipeCompareData.serves}
              </Span>
            </Col>
          </Row>
        );
      } else if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
        return (
          <Row
            key={idx}
            onClick={() => handleSelectRecipe(recipeCompareData.versionId)}
            ref={headingsRef}
          >
            <Col
              onClick={() =>
                handleEditRecipe(recipeCompareData.versionId, editRecipe)
              }
            >
              <Icon>
                {editRecipe && (
                  <Icon>
                    <Edit size="small" color="grey" faded />
                  </Icon>
                )}
              </Icon>
            </Col>
            <Col className="recipeVersion">
              <ColTitle selected={selectedVersion}>
                {recipeCompareData.versionName}{' '}
                {selectedVersion && <Tick size="smaller" color="secondary" />}
              </ColTitle>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showFoodCostContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showFoodCostContent && handleRecipeRevenueUpgrade()
                }
              >
                {foodCostPercentage}
              </Span>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showProfitContent && handleRecipeRevenueUpgrade()
                }
              >
                {showProfitContent
                  ? convertCostCleanly(recipeCompareData.profitPerServe)
                  : blurContent(
                      convertCostCleanly(recipeCompareData.profitPerServe)
                    )}
              </Span>
            </Col>
            <Col>
              <Span color="grey" className="serves">
                * {recipeCompareData.serves}
              </Span>
            </Col>
          </Row>
        );
      } else if (appWidth !== 0 && appWidth < theme.mQ.laptop) {
        return (
          <Row
            key={idx}
            onClick={() => handleSelectRecipe(recipeCompareData.versionId)}
            ref={headingsRef}
          >
            <Col
              onClick={() =>
                handleEditRecipe(recipeCompareData.versionId, editRecipe)
              }
            >
              <Icon>
                {editRecipe && (
                  <Icon>
                    <Edit size="small" color="grey" faded />
                  </Icon>
                )}
              </Icon>
            </Col>

            <Col className="recipeVersion">
              <ColTitle selected={selectedVersion}>
                {recipeCompareData.versionName}{' '}
                {selectedVersion && <Tick size="smaller" color="secondary" />}
              </ColTitle>
            </Col>
            <Col>
              <Span>{differencePercentage}</Span>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showFoodCostContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showFoodCostContent && handleRecipeRevenueUpgrade()
                }
              >
                {foodCostPercentage}
              </Span>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showProfitContent && handleRecipeRevenueUpgrade()
                }
              >
                {showProfitContent
                  ? convertCostCleanly(recipeCompareData.profitPerServe)
                  : blurContent(
                      convertCostCleanly(recipeCompareData.profitPerServe)
                    )}
              </Span>
            </Col>
            <Col>
              <Span color="grey" className="serves">
                * {recipeCompareData.serves}
              </Span>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row
            key={idx}
            onClick={() => handleSelectRecipe(recipeCompareData.versionId)}
            ref={headingsRef}
          >
            <Col
              onClick={() =>
                handleEditRecipe(recipeCompareData.versionId, editRecipe)
              }
            >
              <Icon>
                {editRecipe && (
                  <Icon>
                    <Edit size="small" color="grey" faded />
                  </Icon>
                )}
              </Icon>
            </Col>
            <Col>
              <ColTitle selected={selectedVersion}>
                {recipeCompareData.versionName}{' '}
                {selectedVersion && <Tick size="smaller" color="secondary" />}
              </ColTitle>
            </Col>
            <Col>
              <Span>{differencePercentage}</Span>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showFoodCostContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showFoodCostContent && handleRecipeRevenueUpgrade()
                }
              >
                {foodCostPercentage}
              </Span>
            </Col>
            <Col>
              <Span
                color="grey"
                className={`${!showProfitContent && 'blur hasSeeIcon'}`}
                onClick={() =>
                  !showProfitContent && handleRecipeRevenueUpgrade()
                }
              >
                {showProfitContent
                  ? convertCostCleanly(recipeCompareData.profitPerServe)
                  : blurContent(
                      convertCostCleanly(recipeCompareData.profitPerServe)
                    )}
              </Span>
            </Col>
            <Col>
              <Span color="grey" className="serves">
                * {recipeCompareData.serves}
              </Span>
            </Col>
          </Row>
        );
      }
    }
  };

  return (
    <Card ref={pageWidthRef}>
      <Header
        toolTip={{
          type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
          heading: 'Recipe Versions',
          content:
            'There are many ways to make a single recipe. Recipe Versions helps you to tinker with each recipe version to quickly discover the optimal balance between profit & flavour accurately',
        }}
        icon={<Copy size="small" />}
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? 'Compare Versions'
            : 'Compare Recipe Versions'
        }
        subHeading="Duplicate recipe versions to compare recipe revenue, return on investment & optimise costs"
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={handleDuplicateRecipe}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <CTAButton color="primary" onClick={handleDuplicateRecipe}>
              {sortedRecipeVersions?.length === 1
                ? 'Edit Recipe'
                : 'Duplicate Recipe'}
            </CTAButton>
          )
        }
      />
      <Content fullWidth>
        <Table>
          {getTableHeader()}
          {sortedRecipeVersions?.map((col, idx) => getTableRows(col, idx))}
        </Table>
      </Content>
    </Card>
  );
};

export default memo(Compare);
