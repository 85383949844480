export const overlayConstants = {
  noAccountScaleRecipe: {
    heading: 'Want to scale your recipe?',
    subHeading: 'Create account to save your recipe changes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountDuplicateRecipe: {
    heading: 'Want to duplicate your recipe version?',
    subHeading: 'Create account to save your recipe changes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountSaveRecipe: {
    heading: 'Want to save the recipe?',
    subHeading: 'Create account to save your recipe changes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountAddRecipe: {
    heading: 'Want to add a recipe?',
    subHeading: 'Create account to save your recipe change',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountEditRecipe: {
    heading: 'Want to edit the recipe?',
    subHeading: 'Create account to save your recipe changes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountSaveIngredient: {
    heading: 'Want to save the ingredient?',
    subHeading: 'Create account to save your ingredient changes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountEditIngredient: {
    heading: 'Want to edit an ingredient?',
    subHeading: 'Create account to save your ingredient changes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountSaveBrand: {
    heading: 'Want to add a brand?',
    subHeading: 'Create account to save your new brand',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountSaveSupplier: {
    heading: 'Want to add a supplier?',
    subHeading: 'Create account to save your new supplier',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountCalculateRecipe: {
    heading: 'Want to save your recipe?',
    subHeading: 'Create account to calculate more recipes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountUpdateVenueDetails: {
    heading: 'Want to update venue details?',
    subHeading: 'Create account to save your venue changes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  noAccountUpdateVenueCosts: {
    heading: 'Want to update venue costs?',
    subHeading: 'Create account to save your venue changes',
    buttonText: 'Create Account',
    buttonLink: '/sign-up',
    linkText: 'Sign in',
    link: '/sign-in',
  },
  helpModal: {
    heading: 'Customer service is key!',
    subHeading:
      'Chat with us immediately or turn on Helpful Hints to get the most out of Recipe Revenue',
    buttonText: 'Start chatting with us now',
    linkText: 'Turn on Helpful Hints',
  },
  addSalesDataRecipeResults: {
    heading: 'Add Recipe Sales Data',
    buttonText: 'Add Sales Data',
    buttonLink: '/',
    linkText: 'cancel',
    hideCTA: true,
  },
  confirmEmail: {
    heading: 'Want to send emails?',
    subHeading: 'Confirm your account email address',
    buttonText: 'Resend Confirmation Email',
    linkText: 'cancel',
  },
  confirmSupplierEmail: {
    heading: 'Want to send supplier emails?',
    subHeading: `Add your supplier's email address`,
    buttonText: 'Add Supplier Email',
    linkText: 'cancel',
  },
};
