import styled from 'styled-components/macro';
import {
  ILayoutPageStylesProps,
  LayoutContentCenterProps,
} from './layoutPage.types';

export const LayoutPageStyles = styled.div<ILayoutPageStylesProps>`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  padding-bottom: ${({ noBottomPadding }) => (noBottomPadding ? '0' : '150px')};

  .centerText {
    text-align: center;
  }
`;

export const LayoutLoading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white.faded};
  z-index: ${({ theme }) => theme.zIndex.aboveNav};
`;

export const LayoutContentStyles = styled.div`
  height: 100%;
  padding-top: 20px;
`;

export const LayoutContentCenterStyles = styled.div<LayoutContentCenterProps>`
  max-width: ${({ align }) =>
    align === 'center' ? '900px' : align === 'left' ? '900px' : '100%'};
  margin: ${({ align }) =>
    align === 'center' ? '0 auto' : align === 'left' ? '0 0 0 20px' : '0'};
`;
