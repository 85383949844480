import React, { FC, useContext } from 'react';
import { H2, H4 } from '../../../../shared/typefaces/Typefaces.styles';
import { Container, Content, VideoImage, Image, CTA } from './About.styles';
import about from '../../../../../images/about-min.png';
import { AnchorButton } from '../../../../shared/button/Button.styles';
import { VideoPlayer } from '../../../../shared/videoPlayer';
import { LayoutContext } from '../../../../../contexts/layoutContext';

interface IAboutProps {
  aboutRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const About: FC<IAboutProps> = ({ aboutRef }) => {
  const { playVideo } = useContext(LayoutContext);
  return (
    <Container id="about" ref={aboutRef}>
      <Content>
        <VideoPlayer
          youtubeVideoID="tcg0-I2OJF0"
          authPage={false}
          play={playVideo === 'about'}
        >
          <VideoImage>
            <Image src={about} />
          </VideoImage>
        </VideoPlayer>
        <CTA>
          <H2>
            How Recipe Revenue <br />
            Works In 2 Minutes
          </H2>
          <H4>
            Have a remarkable recipe?
            <br />
            Make it profitable today!
          </H4>
          <AnchorButton color="primary" href="/select-account" className="cta">
            Get Started
          </AnchorButton>
        </CTA>
      </Content>
    </Container>
  );
};
