import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import {
  MeDocument,
  useCreateVenueMutation,
  useUpdateVenueMutation,
  useVenueQuery,
  VenueDocument,
  VenueType,
} from '../../../generated/graphql';
import { theme } from '../../../styles/theme';
import { formatVenueFormData } from '../../../utils/helper';
import {
  validateNumberValues,
  validatePositiveNumberValues,
} from '../../../utils/helper/validation';
import { Button } from '../../shared/button';
import { LayoutPage } from '../../shared/layout';
import { DotsLoading, LogoLoading } from '../../shared/loading';
import { H3 } from '../../shared/typefaces/Typefaces.styles';
import { SmallButton } from '../ingredient/Ingredient.styles';
import Costs from './sections/Costs';
import Details from './sections/Details';
import Specs from './sections/Specs';
import { Container, Form, Header } from './Venue.styles';
import { DeleteVenue } from './sections/DeleteVenue';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';
import VenueOptions from './sections/VenueOptions';

const Venue = () => {
  const { appWidth, selectedVenueObject, addVenue, dispatch } = useContext(
    LayoutContext
  );
  const {
    getTotalActiveSeconds,
    setInitialActiveSeconds,
  } = useActiveTimeTracker();
  const [createVenueMutation, createVenue] = useCreateVenueMutation();
  const [updateVenueMutation, updateVenue] = useUpdateVenueMutation();
  const venueData = useVenueQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
    fetchPolicy: 'network-only',
  });

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formData: any) => {
    if (addVenue) {
      const venueInput = {
        personal: false,
        active: true,
        totalActiveSeconds: getTotalActiveSeconds(),
        previousVenueId: selectedVenueObject?.id!,
        ...formatVenueFormData(venueData.data, formData),
      };

      try {
        venueInput.venueCost.id = '';
        const response = await createVenueMutation({
          variables: {
            input: {
              ...venueInput,
            },
          },
          refetchQueries: [
            {
              query: MeDocument,
            },
          ],
          awaitRefetchQueries: true,
        });

        if (response.data?.createVenue.successful) {
          dispatch({ type: 'TOGGLE_SLIDER' });
          const venueData = {
            id: response.data?.createVenue.venue?.id,
            displayName: response.data?.createVenue.venue?.displayName,
            address: response.data?.createVenue.venue?.address,
            email: response.data?.createVenue.venue?.email,
            totalActiveSeconds: 0,
            recipeProfitIncreasePerYear: 0,
          };
          dispatch({
            type: 'SELECT_VENUE_OBJECT',
            payload: venueData,
          });
          setInitialActiveSeconds(venueData.totalActiveSeconds || 0);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      const venueInput = {
        venueId: selectedVenueObject?.id!,
        personal: venueData.data?.venue?.userVenue?.venue?.personal,
        active: venueData.data?.venue?.userVenue?.venue?.active || false,
        totalActiveSeconds: getTotalActiveSeconds(),
        ...formatVenueFormData(venueData.data, formData),
      };
      try {
        const response = await updateVenueMutation({
          variables: {
            input: {
              ...venueInput,
            },
          },
          refetchQueries: [
            {
              query: VenueDocument,
              variables: {
                input: { venueId: selectedVenueObject?.id! },
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        if (response.data?.updateVenue.successful) {
          dispatch({ type: 'TOGGLE_SLIDER' });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleCancel = () => {
    dispatch({ type: 'TOGGLE_SLIDER' });
    dispatch({ type: 'ADD_VENUE', payload: false });
  };

  if (!addVenue && venueData?.loading) {
    return <LogoLoading size={60} />;
  }

  if (!addVenue && (venueData?.error || !venueData?.data)) {
    return (
      <div>
        <H3>There was an error accessing your account</H3>
        <Link to="/sign-in">Login to your account</Link>
      </div>
    );
  }

  const venue = venueData?.data?.venue?.userVenue?.venue;

  const heading = addVenue ? 'Add venue' : 'Edit Venue';
  const subHeading = addVenue ? 'Add venue' : 'Edit Venue';

  const isLoading = createVenue.loading || updateVenue.loading;

  if (venueData.loading || !venue) {
    return <LogoLoading size={60} />;
  }

  return (
    <LayoutPage
      backButton
      backButtonCTA={handleCancel}
      align="center"
      withLine
      heading={heading}
      subHeading={subHeading + ' details to enhance your calculations'}
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={handleCancel}>
                x
              </Button>,
              <SmallButton
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(isLoading) => (isLoading ? 'Saving' : 'Save')}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </SmallButton>,
            ]
          : [
              <Button color="default" inversed onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={isLoading}
              >
                <DotsLoading
                  text={(isLoading) => `${isLoading ? 'Saving' : 'Save'} Venue`}
                  isLoading={isLoading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <Container>
        <Header>{!addVenue && venue?.displayName} Venue Details</Header>
        <FormProvider {...methods}>
          <Form>
            <Specs data={venueData} />
            <VenueOptions data={venueData} />
            <Details data={venueData} />
            <Costs data={venueData} />
          </Form>
        </FormProvider>
      </Container>
      {venue.type !== VenueType.Personal &&
        venue.type !== VenueType.RecipeRevenue &&
        !addVenue && <DeleteVenue />}
    </LayoutPage>
  );
};

const validateMaxWeeks = (value: string | number | null | undefined) => {
  if (!value || value === null) {
    return true;
  }

  if (value && Number(value) > 52) {
    return false;
  }

  return true;
};

const validationSchema = Yup.object({
  displayName: Yup.string().when('type', {
    is: 'Personal',
    then: (s: Yup.StringSchema) => s.notRequired(),
    otherwise: (s: Yup.StringSchema) => s.required('Venue name is required'),
  }),
  type: Yup.string().required('Venue type is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Please check email format'),
  weeksOpen: Yup.string()
    .required('Weeks open is required')
    .typeError('Weeks open is accepts numbers only')
    .test('numberTest', 'Weeks open is not a number', (value) =>
      validateNumberValues(value)
    )
    .test(
      'maxWeeks',
      'Now now.. We work hard enough, 52 Weeks is the max',
      (value) => validateMaxWeeks(value)
    ),
  phone: Yup.number()
    .typeError('Mobile accepts numbers only')
    .test('numberTest', 'Phone accepts numbers only', (value) =>
      validateNumberValues(value)
    ),
  website: Yup.string().matches(
    /^$|^((https?):\/\/)?(www.)?[a-zA-Z0-9]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
    'Please check website url format'
  ),
  chefCost: Yup.string()
    .required('Chef cost is required')
    .typeError('Chef cost is required'),
  targetFoodMargin: Yup.string()
    .typeError('Target Food Margin accepts numbers only')
    .test('numberTest', 'Target Food Margin is not a number', (value) =>
      validateNumberValues(value)
    )
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),
});

export default Venue;
