import { RecommendedPriceType } from '../../generated/graphql';
import { getSubRecipeGrams } from '../helper/getSubRecipeGrams';
import { getSubRecipePrepTime } from '../helper/getSubRecipePrepTime';
import { isEmpty } from '../helper/objects';
import { getTotalTimeSeconds, getUnitTotal } from '../helper/units';
import { IRecipeDataArgs, IRecipeDataResult } from './getRecipeData.types';
import { getRecipeIngredientsData } from './getRecipeIngredientData';
import {
  IRecipeAsIngredient,
  IRecipeData,
  IRecipeVersionData,
  IVenueData,
} from './sharedTypes';

const dateTimeFormat = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
} as const;

export function getRecipeData({
  recipe,
  venue,
}: IRecipeDataArgs): IRecipeDataResult | undefined {
  const selectedRecipeVersion = recipe?.recipeVersions?.find(
    (version) => version.selected
  );

  const originalRecipeVersion = recipe?.recipeVersions?.find(
    (version) => version.original
  );

  if (!venue || !selectedRecipeVersion || !recipe || !originalRecipeVersion) {
    return undefined;
  }

  const recipeDataByVersion: Record<
    string,
    ReturnType<typeof getRecipeDataForVersion>
  > = {};

  const filteredVersions = recipe?.recipeVersions.filter((v) => v.active);
  let originalVersionId = '';
  for (const recipeVersion of filteredVersions || []) {
    if (recipeVersion.original) {
      originalVersionId = recipeVersion.id;
    }
    recipeDataByVersion[recipeVersion.id] = getRecipeDataForVersion({
      recipe,
      venue,
      recipeVersion,
    });
  }
  const recipeDetails = getRecipeVersionDetails({
    recipe,
    recipeVersion: selectedRecipeVersion,
    venue,
  });

  const originalRecipeData = recipeDataByVersion[originalVersionId];
  const selectedRecipeData = recipeDataByVersion[selectedRecipeVersion.id];
  const highestAndLowestProfit = getHighestAndLowestProfit({
    byVersion: recipeDataByVersion,
    originalVersionId: originalRecipeVersion!.id,
    selectedRecipeVersionId: selectedRecipeVersion.id,
  });

  // Needs to be the only place original recipe profit is touched
  // This updates only when a template recipe has been created & recipe profit is 0
  // Therefore the front end can run the relevant users profit calculations & submit it to the back end
  const intitalRecipeVersion =
    recipe.originalRecipeProfit === 0 && filteredVersions.length !== 0;
  const originalRecipeVersionProfitMisMatch =
    originalRecipeData.recipeProfit !== recipe.originalRecipeProfit;
  const originalRecipeProfit =
    intitalRecipeVersion || originalRecipeVersionProfitMisMatch
      ? originalRecipeData.recipeProfit
      : recipe.originalRecipeProfit;
  const originalRecipeServes =
    intitalRecipeVersion || originalRecipeVersionProfitMisMatch
      ? originalRecipeData.serves
      : recipe.originalServes;

  return {
    ...recipeDetails,
    ...highestAndLowestProfit,
    ...selectedRecipeData,
    originalRecipeProfit: parseFloat(originalRecipeProfit.toFixed(16)),
    originalRecipeServes,
  };
}

function getRecipeDataForVersion({
  recipe,
  venue,
  recipeVersion,
}: {
  recipe: Pick<IRecipeData, 'salesPricePerServe' | 'weeklySalesPerServe'>;
  venue: IVenueData;
  recipeVersion: IRecipeVersionData;
}) {
  const timeStats = getRecipeVersionTimeStats({
    recipeVersion,
  });

  const salesStats = getRecipeVersionSalesStats({
    recipe,
    recipeVersion,
  });

  const recipeCosts = getRecipeVersionCosts({
    recipeVersion,
    venue,
    salesStats,
    timeStats,
  });

  const profitStats = getRecipeVersionProfitStats({
    recipeVersion,
    venue,
    salesStats,
    recipeCosts,
    timeStats,
  });

  return {
    ...recipeCosts,
    ...salesStats,
    ...profitStats,
    ...timeStats,
  };
}

function getRecipeVersionTimeStats({
  recipeVersion,
  convertTimeToSeconds,
}: {
  recipeVersion: IRecipeVersionData;
  convertTimeToSeconds?: boolean | undefined;
}): Pick<
  IRecipeDataResult,
  'totalTime' | 'totalProcessTime' | 'totalStaffTime' | 'totalPrepTime'
> {
  // calculate sum of time
  let totalProcessTime = 0;
  let totalStaffTime = 0;
  let totalPrepTime = 0;

  for (const recipeTimeItem of recipeVersion.recipeTimeItems) {
    const quantity = convertTimeToSeconds
      ? getTotalTimeSeconds(recipeTimeItem.unit, recipeTimeItem.quantity)
      : recipeTimeItem.quantity;

    if (recipeTimeItem.staffTime) {
      totalStaffTime += quantity;
    } else {
      totalProcessTime += quantity;
    }
  }

  if (
    recipeVersion?.recipeAsIngredients &&
    recipeVersion?.recipeAsIngredients.length !== 0
  ) {
    for (const subRecipeItem of recipeVersion.recipeAsIngredients) {
      totalPrepTime += getSubRecipePrepTime({
        quantity: subRecipeItem.quantity,
        recipeServes: subRecipeItem.recipe.serves,
        totalGrams: subRecipeItem.recipe.totalGrams,
        totalTime: subRecipeItem.recipe.totalTime,
        unit: subRecipeItem.unit,
      });
    }
  }

  return {
    totalProcessTime,
    totalStaffTime,
    totalPrepTime,
    totalTime: totalStaffTime + totalProcessTime,
  };
}

type GetHighestAndLowestProfitRecipeData = Pick<
  IRecipeDataResult,
  | 'profitPerMin'
  | 'profitPerMonth'
  | 'profitPerYear'
  | 'recipeProfit'
  | 'profitPerServe'
>;

function getHighestAndLowestProfit({
  byVersion,
  originalVersionId,
  selectedRecipeVersionId,
}: {
  byVersion: Record<string, GetHighestAndLowestProfitRecipeData>;
  originalVersionId: string;
  selectedRecipeVersionId: string;
}): Pick<
  IRecipeDataResult,
  'highestProfitIncreasePerServe' | 'profitIncreasePerServe'
> {
  let highestProfit: GetHighestAndLowestProfitRecipeData | undefined;
  let originalProfit: GetHighestAndLowestProfitRecipeData | undefined;
  let selectedVersionProfit: GetHighestAndLowestProfitRecipeData | undefined;

  for (const [versionId, recipeVersion] of Object.entries(byVersion)) {
    if (versionId === originalVersionId) {
      originalProfit = recipeVersion;
    }
    if (versionId === selectedRecipeVersionId) {
      selectedVersionProfit = recipeVersion;
    }

    if (
      recipeVersion.profitPerServe >
      (highestProfit?.profitPerServe || -Infinity)
    ) {
      highestProfit = recipeVersion;
    }
  }

  const highestProfitIncreasePerServe = Math.max(
    (highestProfit?.profitPerServe || 0) -
      (originalProfit?.profitPerServe || 0),
    0
  );

  const profitIncreasePerServe =
    (selectedVersionProfit?.profitPerServe || 0) -
    (originalProfit?.profitPerServe || 0);
  return { highestProfitIncreasePerServe, profitIncreasePerServe };
}

function getRecipeVersionCosts({
  recipeVersion,
  venue,
  salesStats,
  timeStats,
}: {
  recipeVersion: IRecipeVersionData;
  venue: IVenueData;
  salesStats: Pick<
    IRecipeDataResult,
    'weeklySalesPerServe' | 'salesPricePerServe' | 'weeklyRecipeRemakes'
  >;
  timeStats: Pick<IRecipeDataResult, 'totalTime' | 'totalStaffTime'>;
}): Pick<
  IRecipeDataResult,
  | 'foodCost'
  | 'wastageCost'
  | 'wastageType'
  | 'recipeCost'
  | 'rentCost'
  | 'powerCost'
  | 'waterCost'
  | 'insuranceCost'
  | 'councilCost'
  | 'totalStaffTimeCost'
  | 'totalVenueTimeCost'
  | 'totalTimeCost'
  | 'venuePrepCost'
  | 'costPerServe'
  | 'totalGrams'
  | 'gramsPerServe'
> {
  const foodCosts = getRecipeVersionFoodStats({
    recipeVersion,
    venue,
  });

  const venueCosts = getRecipeVersionVenueCosts({
    venue,
    timeStats,
    ...salesStats,
  });

  const recipeCost =
    foodCosts.foodCost + venueCosts.totalTimeCost + venueCosts.venuePrepCost;

  const costPerServe = recipeCost / recipeVersion.serves;

  return {
    recipeCost,
    costPerServe,
    ...venueCosts,
    ...foodCosts,
  };
}

function getRecipeVersionFoodStats({
  recipeVersion,
  venue,
}: {
  recipeVersion: IRecipeVersionData;
  venue: IVenueData;
}): Pick<
  IRecipeDataResult,
  'foodCost' | 'wastageCost' | 'wastageType' | 'totalGrams' | 'gramsPerServe'
> {
  const totalIngredientGrams =
    recipeVersion.recipeIngredients?.reduce((totalGramsSum, ingredient) => {
      const metrics =
        ingredient.ingredient?.metrics.reduce((obj, item) => {
          return Object.assign(obj, { [item.type]: item.grams });
        }, {} as Record<string, number>) ?? {};
      return (
        totalGramsSum +
        getUnitTotal(ingredient.unit, ingredient.quantity, metrics)
      );
    }, 0) || 0;

  // get some initial information out of getRecipeIngredientsData
  const {
    foodCost: ingredientDataFoodCost,
    wastageCost: ingredientDataWastageCost,
    wastageType: ingredientDataWastageType,
  } = getRecipeIngredientsData({
    recipeIngredients: recipeVersion.recipeIngredients || [],
    recipeWastage: recipeVersion.recipeWastage,
    venueData: venue,
  });

  // init total grams and food cost
  let mutableTotalGrams = totalIngredientGrams;
  let mutableFoodCost = ingredientDataFoodCost;

  // add subrecipe grams and food costs
  for (const subRecipe of recipeVersion.recipeAsIngredients ?? []) {
    const subRecipeCostAndGrams = getSubRecipeCostAndGrams(subRecipe);

    mutableTotalGrams += subRecipeCostAndGrams.subRecipeGrams;
    mutableFoodCost += subRecipeCostAndGrams.subRecipeCost;
  }

  // Add recipe wastage costs
  let wastageCost = ingredientDataWastageCost;
  if (!isEmpty(recipeVersion.recipeWastage)) {
    const foodWastageCost =
      mutableFoodCost * (recipeVersion.recipeWastage! / 100);
    mutableFoodCost = mutableFoodCost + foodWastageCost;
    wastageCost += foodWastageCost;
  }

  return {
    foodCost: mutableFoodCost,
    wastageCost,
    wastageType: ingredientDataWastageType,
    totalGrams: mutableTotalGrams,
    gramsPerServe: mutableTotalGrams / recipeVersion.serves,
  };
}

function getSubRecipeCostAndGrams(subRecipe: IRecipeAsIngredient) {
  const {
    unit,
    quantity,
    recipe: {
      recipeProfit,
      recipeRevenue,
      serves,
      totalGrams: subRecipeTotalGrams,
    },
  } = subRecipe;

  const subRecipeGrams = getSubRecipeGrams({
    unit,
    quantity,
    recipeServes: serves,
    totalGrams: subRecipeTotalGrams,
  });

  const recipeCost = recipeRevenue - recipeProfit;
  const subRecipeCost = subRecipeGrams * (recipeCost / subRecipeTotalGrams);
  return { subRecipeCost, subRecipeGrams };
}

function getRecipeVersionSalesStats({
  recipeVersion,
  recipe,
}: {
  recipeVersion: IRecipeVersionData;
  recipe:
    | Pick<IRecipeData, 'salesPricePerServe' | 'weeklySalesPerServe'>
    | undefined;
}): Pick<
  IRecipeDataResult,
  'weeklySalesPerServe' | 'salesPricePerServe' | 'weeklyRecipeRemakes'
> {
  const salesPricePerServe = recipe?.salesPricePerServe || 0;
  const weeklySalesPerServe = recipe?.weeklySalesPerServe || 0;

  const weeklyRecipeRemakes = Math.ceil(
    weeklySalesPerServe / recipeVersion.serves
  );

  return {
    salesPricePerServe,
    weeklySalesPerServe,
    weeklyRecipeRemakes,
  };
}

function getRecipeVersionDetails({
  recipe,
  recipeVersion,
  venue,
}: {
  recipe: IRecipeData | undefined;
  venue: IVenueData;
  recipeVersion: IRecipeVersionData;
}): Pick<
  IRecipeDataResult,
  | 'recipeId'
  | 'recipeName'
  | 'category'
  | 'recipeCreatedAt'
  | 'versionName'
  | 'versionId'
  | 'serves'
  | 'recipeIngredientIds'
> {
  return {
    recipeId: recipe?.id,

    recipeName: recipe?.displayName,
    versionName: recipeVersion.displayName,
    category: recipe?.category || undefined,
    recipeCreatedAt: new Date(Number(recipe?.createdAt))
      .toLocaleDateString('en-US', dateTimeFormat)
      .replace('AM', 'am')
      .replace('PM', 'pm'),
    versionId: recipeVersion.id,
    serves: recipeVersion.serves,
    recipeIngredientIds: getIngredientIds(recipeVersion, venue),
  };
}

function getRecipeVersionVenueCosts({
  venue,
  weeklyRecipeRemakes,
  timeStats: { totalStaffTime, totalTime },
}: {
  venue: IVenueData;
  weeklyRecipeRemakes: number;
  timeStats: Pick<IRecipeDataResult, 'totalTime' | 'totalStaffTime'>;
}): Pick<
  IRecipeDataResult,
  | 'rentCost'
  | 'powerCost'
  | 'waterCost'
  | 'insuranceCost'
  | 'councilCost'
  | 'totalStaffTimeCost'
  | 'totalVenueTimeCost'
  | 'totalTimeCost'
  | 'venuePrepCost'
> {
  const totalVenueCostPerSecond = getTotalVenueCostPerSecond(venue);
  const totalStaffTimeCost = totalStaffTime * venue.venueCost.chefCost;
  const totalVenueTimeCost = totalVenueCostPerSecond * totalTime;
  const totalTimeCost = totalStaffTimeCost + totalVenueTimeCost;
  const venuePrepCost = getVenuePrepCost({
    venue,
    totalVenueCostPerSecond,
    weeklyRecipeRemakes,
  });

  return {
    rentCost: totalTime * venue.venueCost.rentCost,
    powerCost: totalTime * venue.venueCost.powerCost,
    waterCost: totalTime * venue.venueCost.waterCost,
    insuranceCost: totalTime * venue.venueCost.insuranceCost,
    councilCost: totalTime * venue.venueCost.councilCost,
    totalStaffTimeCost,
    totalTimeCost,
    totalVenueTimeCost,
    venuePrepCost,
  };
}

function getVenuePrepCost({
  venue,
  totalVenueCostPerSecond,
  weeklyRecipeRemakes,
}: {
  venue: IVenueData;
  totalVenueCostPerSecond: number;
  weeklyRecipeRemakes: number;
}) {
  if (isEmpty(venue.prepTime)) return 0;

  if (venue.prepTimeUnit !== 'week') return 0;

  const chefCost = venue.venueCost.chefCost;

  const venuePrepTimePerSecond = venue.prepTime * 60 * 60;

  const oneWeekVenueCostPerSecond =
    totalVenueCostPerSecond * venuePrepTimePerSecond;

  const oneWeekChefCostPerSecond = venuePrepTimePerSecond * chefCost;

  const totalVenuePrepCostPerSecondPerWeek =
    oneWeekVenueCostPerSecond + oneWeekChefCostPerSecond;

  return (
    totalVenuePrepCostPerSecondPerWeek /
    venue.recipes.length /
    weeklyRecipeRemakes
  );
}

function getRecipeVersionProfitStats({
  recipeVersion,
  venue,
  recipeCosts: { recipeCost, foodCost, totalStaffTimeCost },
  salesStats: { salesPricePerServe, weeklySalesPerServe },
  timeStats: { totalTime },
}: {
  recipeVersion: IRecipeVersionData;
  venue: IVenueData;
  recipeCosts: Pick<
    IRecipeDataResult,
    'foodCost' | 'recipeCost' | 'totalStaffTimeCost'
  >;
  salesStats: Pick<
    IRecipeDataResult,
    'weeklySalesPerServe' | 'salesPricePerServe'
  >;
  timeStats: Pick<IRecipeDataResult, 'totalTime'>;
}): Pick<
  IRecipeDataResult,
  | 'recipeRevenue'
  | 'revenuePerMonth'
  | 'revenuePerYear'
  | 'profitMarginPercentage'
  | 'profitPerMin'
  | 'profitPerMonth'
  | 'profitPerServe'
  | 'profitPerYear'
  | 'recipeProfit'
  | 'markup'
  | 'markupPercentage'
  | 'ingredientMarkupPercentage'
  | 'foodCostPercentage'
  | 'recommendedPrice'
  | 'serves'
  | 'cogs'
  | 'cogsPercentage'
> {
  // revenue
  const recipeRevenue = salesPricePerServe * recipeVersion.serves;
  const revenuePerYear =
    venue.weeksOpen * weeklySalesPerServe * salesPricePerServe;
  const revenuePerMonth = revenuePerYear / 12;

  // profit
  const recipeProfit = recipeRevenue - recipeCost;
  const profitPerMin =
    !isEmpty(recipeProfit) && !isEmpty(totalTime)
      ? recipeProfit / getUnitTotal('minute', totalTime)
      : 0;
  const profitPerServe = recipeProfit / recipeVersion.serves;
  const profitPerYear = venue.weeksOpen * weeklySalesPerServe * profitPerServe;
  const profitPerMonth = profitPerYear / 12;

  // profitMargin
  const profitMarginDecimal = recipeProfit / recipeRevenue;
  const profitMarginPercentage =
    isNaN(profitMarginDecimal) || !isFinite(profitMarginDecimal)
      ? 0
      : profitMarginDecimal * 100;

  // costs
  const costPerServe = recipeCost / recipeVersion.serves;

  // calculate markup
  const markup = (salesPricePerServe - costPerServe) / salesPricePerServe;

  const markupPercentage =
    ((salesPricePerServe - costPerServe) / costPerServe) * 100;

  const ingredientMarkupPercentage =
    ((recipeRevenue - foodCost) / foodCost) * 100;

  const foodCostPercentage = !isEmpty(foodCost) // Also knonw as Food Profit Margin
    ? (foodCost / recipeRevenue) * 100
    : 0;

  const cogs = foodCost + totalStaffTimeCost;

  const cogsPercentage = !isEmpty(foodCost) // Also knonw as Food Profit Margin
    ? (cogs / recipeRevenue) * 100
    : 0;

  // Recommended price
  const recommendedPrice = getRecommendedPrice({
    venue,
    recipeCost,
    serves: recipeVersion.serves,
    foodCost,
  });

  return {
    recipeRevenue,
    revenuePerMonth,
    revenuePerYear,
    profitMarginPercentage,
    markup,
    markupPercentage,
    ingredientMarkupPercentage,
    profitPerMonth,
    profitPerMin,
    profitPerServe,
    profitPerYear,
    recipeProfit,
    foodCostPercentage,
    cogs,
    cogsPercentage,
    recommendedPrice,
    serves: recipeVersion.serves,
  };
}

function getIngredientIds(
  recipeVersion: IRecipeVersionData,
  venueData: IVenueData
): string[] {
  const { ingredientsDataArray } = getRecipeIngredientsData({
    recipeIngredients: recipeVersion.recipeIngredients || [],
    recipeWastage: recipeVersion.recipeWastage,
    venueData,
  });

  return [
    ...(ingredientsDataArray?.map((item) => item.id) || []),

    ...(recipeVersion.recipeAsIngredients?.map(
      (subRecipe) => subRecipe?.recipe.id
    ) || []),
  ];
}

function getTotalVenueCostPerSecond(venueData: IVenueData) {
  return (
    // Not including chef cost
    venueData.venueCost.rentCost +
    venueData.venueCost.waterCost +
    venueData.venueCost.powerCost +
    venueData.venueCost.councilCost +
    venueData.venueCost.insuranceCost
  );
}

const getRecommendedPrice = ({
  venue,
  serves,
  foodCost,
  recipeCost,
}: {
  venue: IVenueData;
  serves: number;
  foodCost: number;
  recipeCost: number;
}) => {
  const dfault = (recipeCost / serves) * 2;
  switch (venue.recommendedPrice) {
    case RecommendedPriceType.FoodCost:
      return (foodCost / serves) * 4.1;
    case RecommendedPriceType.RecipeCost:
      return dfault;
    case RecommendedPriceType.TargetMargin:
      if (venue.targetFoodMargin) {
        const serveFoodCost = foodCost / serves;
        return serveFoodCost / (venue!.targetFoodMargin / 100);
      }
      return dfault;

    default:
      return dfault;
  }
};
