import React from 'react';

import {
  LayoutPageStyles,
  LayoutContentStyles,
  LayoutContentCenterStyles,
  LayoutLoading,
} from './LayoutPage.styles';
import { ILayoutPageProps } from './layoutPage.types';
import { Navbar } from '../../navbar';
import { LogoLoading } from '../../loading';

const LayoutPage: React.FC<ILayoutPageProps> = ({
  children,
  heading,
  align,
  slider,
  noBottomPadding,
  isLoading,
  ...otherProps
}) => {
  return (
    <LayoutPageStyles noBottomPadding={noBottomPadding}>
      {isLoading && (
        <LayoutLoading>
          <LogoLoading size={60} />
        </LayoutLoading>
      )}
      {heading && <Navbar slider={slider} heading={heading} {...otherProps} />}
      <LayoutContentStyles>
        <LayoutContentCenterStyles align={align}>
          {children}
        </LayoutContentCenterStyles>
      </LayoutContentStyles>
    </LayoutPageStyles>
  );
};

export default LayoutPage;
