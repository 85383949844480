import React, { FC, useContext, useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import { Container } from './RecipeProfitGraph.styles';
import { theme } from '../../../../../styles/theme';
import {
  RecipeDocument,
  RecipeQuery,
  RecipeQueryVariables,
  useUpdateOriginalRecipeMutation,
  VenueQuery,
  VenueQueryVariables,
} from '../../../../../generated/graphql';
import { DotsLoading } from '../../../../shared/loading';
import {
  blurContent,
  convertCostCleanly,
  convertTimeFromSecondsCleanly,
  getFiveGraphElements,
  isNegative,
} from '../../../../../utils/helper';
import * as ApolloClient from '@apollo/client';
import { Card, Content, Header } from '../../../../shared/card';
import { Apple } from '../../../../shared/icons';
import { Button } from '../../../../shared/button';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { IRecipeDataResult } from '../../../../../utils/clientServerShared/getRecipeData.types';
import { useShowCalcData } from '../../../../../utils/customHooks/useShowCalcData';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';
import { LayoutContext } from '../../../../../contexts/layoutContext';

interface IRecipeProfitGraphProps {
  data?: ApolloClient.QueryResult<RecipeQuery, RecipeQueryVariables>;
  venue?: ApolloClient.QueryResult<VenueQuery, VenueQueryVariables>;
  recipeData: IRecipeDataResult;
}

// Custom tooltip component
const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: theme.colors.white.default,
          border: `1px solid ${theme.colors.default.default}`,
          padding: '10px',
        }}
      >
        <Span fontSize="small" fontWeight="medium">
          Total Profit Change:
        </Span>
        <br />
        <Span className="intro" fontWeight="medium">
          {convertCostCleanly(payload[0].value)}{' '}
          <Span className="label" color="faded">{`in ${label}`}</Span>
        </Span>
      </div>
    );
  }

  return null;
};

const formatYAxis = (tickItem) => {
  return `$${tickItem}`;
};

const RecipeProfitGraph: FC<IRecipeProfitGraphProps> = (props) => {
  const { appWidth, selectedRecipe, selectedVenueObject } = useContext(
    LayoutContext
  );
  const [UpdateOriginalRecipeMutation] = useUpdateOriginalRecipeMutation();
  const recipe = props.data?.data?.recipe.recipe;
  const weeklySalesPerServe =
    props.recipeData.weeklySalesPerServe || recipe?.weeklySalesPerServe!;
  const originalProfitPerServe =
    props.recipeData.originalRecipeProfit /
    props.recipeData.originalRecipeServes;
  const currentProfitPerServe =
    props.recipeData.recipeProfit / props.recipeData.serves;
  const weeksOpen = props.venue?.data?.venue?.userVenue?.venue.weeksOpen;
  let yearlyProfitIncrease =
    recipe?.recipeProfitIncreasePerServe! * weeklySalesPerServe * weeksOpen!;
  const profitIsMissAligned =
    yearlyProfitIncrease === 0 ||
    props.recipeData.originalRecipeProfit !== recipe?.originalRecipeProfit;
  const servesIsMissAligned =
    yearlyProfitIncrease === 0 ||
    props.recipeData.originalRecipeServes !== recipe?.originalServes;
  let profitPerServeChange = recipe?.recipeProfitIncreasePerServe;
  if (profitIsMissAligned) {
    profitPerServeChange = currentProfitPerServe - originalProfitPerServe;
    yearlyProfitIncrease =
      profitPerServeChange * weeklySalesPerServe * weeksOpen!;
  }
  const increaseDescreased = isNegative(
    profitIsMissAligned
      ? yearlyProfitIncrease
      : recipe?.recipeProfitIncreasePerServe!
  )
    ? 'decreased'
    : 'increased';
  const showContentSubHeading = appWidth < theme.mQ.mobileL;

  const handleUpdateOriginalRecipe = async () => {
    try {
      UpdateOriginalRecipeMutation({
        variables: {
          input: {
            id: selectedRecipe!,
            venueId: selectedVenueObject?.id!,
            originalRecipeProfit:
              originalProfitPerServe * props.recipeData.originalRecipeServes,
            originalRecipeServes: props.recipeData.originalRecipeServes,
          },
        },
        refetchQueries: [
          {
            query: RecipeDocument,
            variables: {
              input: {
                venueId: selectedVenueObject?.id!,
                recipeId: selectedRecipe!,
              },
            },
          },
        ],
        awaitRefetchQueries: true,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (profitIsMissAligned || servesIsMissAligned) {
      handleUpdateOriginalRecipe();
    }
  }, [profitIsMissAligned]);

  const showRestrictionPopup = useRestrictionPopup();
  const { showCalcData } = useShowCalcData();
  const [showRecipeJourney, setShowRecipeJourney] = useState(
    yearlyProfitIncrease !== 0 && showCalcData
  );
  let recipeProfitTrendNew =
    recipe?.recipeProfitTrend?.map((t) => {
      const { timeValue, timeText } = convertTimeFromSecondsCleanly(
        t.recipeActiveSeconds
      );

      return {
        name: `${timeValue} ${timeText}`,
        totalProfit: t.recipeProfitIncreasePerServe,
        amt: t.recipeProfitIncreasePerServe,
      };
    }) || [];

  if (recipeProfitTrendNew.length === 0) {
    recipeProfitTrendNew = [
      {
        name: `0 s`,
        totalProfit: originalProfitPerServe,
        amt: originalProfitPerServe,
      },
      {
        name: `1 s`,
        totalProfit: currentProfitPerServe,
        amt: currentProfitPerServe,
      },
    ];
  }

  const graphData = getFiveGraphElements(recipeProfitTrendNew, true);
  const handleCtaButtonClick = () => {
    setShowRecipeJourney(!showRecipeJourney);
  };

  const handleRecipeRevenueUpgrade = () => {
    showRestrictionPopup(
      restrictionPopupMessages.cannotSeeProfitChangesOvertime
    );
  };

  const ctaButton =
    yearlyProfitIncrease !== 0 ? (
      <Button
        color="secondary"
        inversed={showRecipeJourney}
        onClick={
          showCalcData ? handleCtaButtonClick : handleRecipeRevenueUpgrade
        }
      >
        {showRecipeJourney ? 'Hide Journey' : 'See Journey'}
      </Button>
    ) : null;

  const subHeading = (
    <Span fontSize="small" color="faded">
      Recipe Profit Per Serve started at{' '}
      <Span
        fontSize="small"
        color="black"
        className={`${!showCalcData && 'blur hasSeeIcon'}`}
        onClick={() => !showCalcData && handleRecipeRevenueUpgrade()}
      >
        {showCalcData
          ? convertCostCleanly(originalProfitPerServe, true)
          : blurContent(convertCostCleanly(originalProfitPerServe, true))}
      </Span>
      , currently it's{' '}
      <Span
        fontSize="small"
        color="black"
        className={`${!showCalcData && 'blur hasSeeIcon'}`}
        onClick={() => !showCalcData && handleRecipeRevenueUpgrade()}
      >
        {showCalcData
          ? convertCostCleanly(currentProfitPerServe, true)
          : blurContent(convertCostCleanly(currentProfitPerServe, true))}
      </Span>
      , a total profit {increaseDescreased} of{' '}
      <Span
        fontSize="small"
        color="black"
        className={`${!showCalcData && 'blur hasSeeIcon'}`}
        onClick={() => !showCalcData && handleRecipeRevenueUpgrade()}
      >
        {showCalcData
          ? convertCostCleanly(profitPerServeChange, true)
          : blurContent(convertCostCleanly(profitPerServeChange, true))}
      </Span>
      {'. '}
      {yearlyProfitIncrease !== 0 &&
        `Based on your yearly sales you have ${increaseDescreased} profitability by `}
      {yearlyProfitIncrease !== 0 && (
        <Span
          fontSize="small"
          color="black"
          className={`${!showCalcData && 'blur hasSeeIcon'}`}
          onClick={() => !showCalcData && handleRecipeRevenueUpgrade()}
        >
          {showCalcData
            ? convertCostCleanly(yearlyProfitIncrease, true)
            : blurContent(convertCostCleanly(yearlyProfitIncrease, true))}
        </Span>
      )}
    </Span>
  );

  return (
    <Container>
      <Card>
        <Header
          icon={<Apple size="small" />}
          heading="Profit Changes Overtime"
          subHeading={subHeading}
          showSubHeading={!showContentSubHeading}
          button={ctaButton}
          toolTip={{
            type: 'SET_RECIPE_RESULTS_TOOL_TIPS',
            heading: 'Recipe Changes',
            content:
              'This graph highlights your recipe changes overtime to visualise key aspects that have achieved your recipe results',
          }}
          addPaddingBottom
        />
        <DotsLoading
          isLoading={props.data?.loading}
          size="large"
          lineHeight={10}
          color="default"
        />
        {showContentSubHeading && (
          <div className="contentSubHeading">{subHeading}</div>
        )}
        {showRecipeJourney && (
          <Content fullWidth className="content">
            <Span className="contentText">
              ** Graph is shows profit changes across all recipe versions <br />
            </Span>

            <ResponsiveContainer width="100%" aspect={16 / 5}>
              <LineChart
                width={900}
                height={150}
                data={graphData}
                margin={{ top: 30, right: 55, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" dy={10} />
                <YAxis dataKey="totalProfit" tickFormatter={formatYAxis} />
                <CartesianGrid strokeDasharray="3 3" />
                <Line
                  type="monotone"
                  dataKey="totalProfit"
                  stroke={theme.colors.secondary.default}
                />
                <Tooltip content={<CustomTooltip />} />
              </LineChart>
            </ResponsiveContainer>
            <div className="contentFooter">
              <Span className="contentFooterText" color="faded">
                Total Time refining your recipe changes <br />
              </Span>
            </div>
          </Content>
        )}
      </Card>
    </Container>
  );
};

export default RecipeProfitGraph;
