import React, { FC } from 'react';
import { H3 } from '../../../../shared/typefaces/Typefaces.styles';
import { Container, Header } from './Callout.styles';
import { AnchorButton } from '../../../../shared/button/Button.styles';
import { Button } from '../../../../shared/button';

interface ICalloutProps {
  heading: string;
  subHeading?: string;
  smallSubHeading?: boolean;
  ctaText?: string | React.ReactNode;
  ctaLink?: string;
  cta?: () => void;
}

export const Callout: FC<ICalloutProps> = ({
  heading,
  subHeading,
  smallSubHeading,
  ctaText,
  ctaLink,
  cta,
}) => {
  const subHeadingClassName = smallSubHeading
    ? 'subHeading smallSubHeading'
    : 'subHeading';

  return (
    <Container id="callout">
      <Header>
        <H3>
          {heading}
          <br />
          <span className={subHeadingClassName}>{subHeading}</span>
        </H3>
        {ctaText && cta && (
          <Button onClick={cta} color="primary" className="CTA">
            {ctaText}
          </Button>
        )}
        {ctaText && ctaLink && (
          <AnchorButton href={ctaLink} color="primary" className="CTA">
            {ctaText}
          </AnchorButton>
        )}
      </Header>
    </Container>
  );
};
