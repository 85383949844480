import React, { useContext, useEffect, useState, memo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import LayoutPage from '../../shared/layout/layoutPage';
import { Button } from '../../shared/button';
import { Header, Detail } from './sections';
import { LayoutContext } from '../../../contexts/layoutContext';
import { theme } from '../../../styles/theme';
import { SmallButton } from './Ingredient.styles';
import { yupResolver } from '@hookform/resolvers/yup';
import Suppliers from './sections/suppliers';
import {
  useIngredientQuery,
  useUpdateIngredientProductMutation,
  IngredientsDocument,
  WastageUnitType,
  RecipeDocument,
} from '../../../generated/graphql';
import { LogoLoading, DotsLoading } from '../../shared/loading';
import Brands from './sections/brands';
import { isEmpty, getCostPer100g } from '../../../utils/helper';
import { getSelectableSizes } from '../../../utils/helper';
import { ISizeOptions } from './Ingredient.types';
import {
  validateNumberValues,
  validatePositiveNumberValues,
} from '../../../utils/helper/validation';
import { overlayConstants } from '../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

export interface IStateProps {
  initialLoad: boolean;
  cost: number;
  grams: { label: string; value: string };
  supplier: string;
  brand: string;
  selectableSizes: ISizeOptions[];
  preventUpdate: boolean;
}

const Ingredient = () => {
  const {
    appWidth,
    selectedVenueObject,
    selectedIngredient,
    selectedIngredientProduct,
    selectedRecipe,
    ingredientWastage,
    extraSliderToggle,
    selectedBrand,
    selectedSupplier,
    topSliderToggle,
    account,
    user,
    userEvent,
    dispatch,
  } = useContext(LayoutContext);
  const [state, setState] = useState<IStateProps>({
    initialLoad: true,
    cost: 0,
    grams: { label: '', value: '' },
    supplier: '',
    brand: '',
    selectableSizes: [],
    preventUpdate: false,
  });
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const ingredient = useIngredientQuery({
    variables: {
      input: {
        ingredientId: selectedIngredient!,
        venueId: selectedVenueObject?.id!,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [
    UpdateIngredientProductMutation,
    updateIngredientProduct,
  ] = useUpdateIngredientProductMutation();

  const initialFormValues = ingredient.data?.ingredient.ingredient?.ingredientProducts.find(
    (product) => selectedVenueObject?.id === product.venueId
  );

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      ingredient.data?.ingredient.ingredient?.liquid
        ? validationMillilitersSchema
        : validationGramsSchema
    ),
    defaultValues: !isEmpty(initialFormValues)
      ? {
          cost: initialFormValues?.size?.productCost || undefined,
          grams: {
            label: initialFormValues?.size
              ? `${initialFormValues?.size?.productGrams}g`
              : '',
            value: initialFormValues?.size
              ? `${initialFormValues?.size?.productGrams}`
              : '',
          },
          brand: initialFormValues?.brandId || '',
          supplier: initialFormValues?.supplierId || '',
          wastage:
            ingredientWastage || initialFormValues?.wastage?.amount || '',
        }
      : {
          cost: undefined,
          grams: {
            label: '',
            value: '',
          },
          brand: '',
          supplier: '',
          wastage: ingredientWastage || '',
        },
  });

  const inputCost = methods.watch('cost');
  const inputGrams = methods.watch('grams');
  const inputBrand = methods.watch('brand');
  const inputSupplier = methods.watch('supplier');
  const inputWastage = methods.watch('wastage');
  const { dirtyFields } = methods.formState;

  useEffect(() => {
    if (
      !ingredient.loading &&
      ingredient.data?.ingredient.successful &&
      !selectedIngredientProduct
    ) {
      dispatch({
        type: 'SELECT_INGREDIENT_PRODUCT',
        payload:
          ingredient.data.ingredient.ingredient?.ingredientProducts[0].id,
      });
    }
  }, [ingredient, selectedIngredientProduct, dispatch]);

  useEffect(() => {
    // Loads initial ingredient information

    if (
      !selectedSupplier &&
      !ingredient.loading &&
      !isEmpty(ingredient.data?.ingredient.ingredient?.ingredientProducts) &&
      state.cost !== inputCost &&
      ((state.grams && state.grams.value) !== inputGrams?.value ||
        state.supplier !== inputSupplier ||
        state.brand !== inputBrand) &&
      isEmpty(methods.errors) &&
      !userEvent.supplier &&
      ((!isEmpty(initialFormValues?.supplierId) &&
        state.supplier !== initialFormValues?.supplierId) ||
        (!isEmpty(initialFormValues?.brandId) &&
          state.brand !== initialFormValues?.brandId))
    ) {
      const { initialLoad } = state;
      if (userEvent.ingredient === 'edit' && !userEvent.supplier) {
        const { selectableSizes } = getSelectableSizes(
          inputBrand,
          inputSupplier,
          ingredient
        );

        let cost: number | undefined = undefined;

        if (dirtyFields['cost']) {
          cost = inputCost;
        }

        if (!isEmpty(initialFormValues) && isEmpty(cost)) {
          cost = initialFormValues?.size?.productCost;
        }

        let brand = '';
        if (
          initialLoad &&
          !isEmpty(initialFormValues) &&
          !isEmpty(initialFormValues?.brandId)
        ) {
          brand = initialFormValues?.brandId!;
        }
        if (
          !isEmpty(state.brand) &&
          initialFormValues?.brandId !== state.brand
        ) {
          // If new brand added this ensures the state.brand holds the new brands id
          brand = state.brand;
        }
        if (inputBrand) {
          brand = inputBrand;
        }

        let supplier = '';
        if (
          initialLoad &&
          !isEmpty(initialFormValues) &&
          !isEmpty(initialFormValues?.supplierId)
        ) {
          supplier = initialFormValues?.supplierId!;
        }
        if (
          !isEmpty(state.supplier) &&
          initialFormValues?.supplierId !== state.supplier
        ) {
          // If new supplier added this ensures the state.supplier holds the new suppliers id
          supplier = state.supplier;
        }
        if (inputSupplier) {
          supplier = inputSupplier;
        }

        const resetObject = {
          cost: cost,
          grams:
            initialLoad && !isEmpty(initialFormValues)
              ? {
                  label: initialFormValues?.size
                    ? `${initialFormValues?.size?.productGrams}g`
                    : '',
                  value: initialFormValues?.size
                    ? initialFormValues?.size?.productGrams.toString()
                    : '',
                }
              : dirtyFields['grams']
              ? inputGrams
              : inputBrand === state.brand && inputSupplier === state.supplier
              ? inputGrams
              : inputBrand !== state.brand || inputSupplier !== state.supplier
              ? selectableSizes[0]
              : selectableSizes[0],
          brand: brand,
          supplier: supplier,
          wastage: ingredientWastage
            ? ingredientWastage
            : !isEmpty(initialFormValues) &&
              !isEmpty(initialFormValues?.wastage)
            ? initialFormValues?.wastage?.amount
            : ingredient.data?.ingredient.ingredient?.averageWastagePercentage
            ? ingredient.data?.ingredient.ingredient?.averageWastagePercentage.toString()
            : '',
        };

        methods.reset(resetObject, {
          dirtyFields: true, // true: dirtyFields will not be reset
        });
        setState({
          ...state,
          initialLoad: false,
          cost: inputCost || 0,
          grams: inputGrams,
          supplier: supplier,
          brand: brand,
          selectableSizes: selectableSizes,
          preventUpdate: false,
        });
      }
    }
  }, [
    methods,
    ingredient,
    inputCost,
    inputGrams,
    inputSupplier,
    inputBrand,
    dirtyFields,
    selectedIngredientProduct,
    state,
    selectedVenueObject?.id,
    ingredientWastage,
    selectedSupplier,
    inputWastage,
    topSliderToggle,
    initialFormValues,
    userEvent,
    dispatch,
  ]);

  useEffect(() => {
    // When we add a supplier in the supplier component we set the selected supplier in the store
    // Once this function triggers we remove the selected supplier as it's no longer needed

    const { brand, supplier } = userEvent;
    if (
      (selectedSupplier || selectedBrand) &&
      (state.supplier !== selectedSupplier || state.brand !== selectedBrand) &&
      (brand === 'add' || supplier === 'add')
    ) {
      const b = brand === 'add' && selectedBrand ? selectedBrand : inputBrand;
      const s =
        supplier === 'add' && selectedSupplier
          ? selectedSupplier
          : inputSupplier;

      methods.reset(
        {
          cost: inputCost,
          grams: inputGrams,
          brand: b,
          supplier: s,
          wastage: inputWastage,
        },
        {
          dirtyFields: true, // dirtyFields will not be reset
        }
      );

      setState({
        ...state,
        initialLoad: false,
        cost: inputCost || 0,
        grams: inputGrams,
        brand: b,
        supplier: s,
        selectableSizes: [],
        preventUpdate: false,
      });

      if (selectedSupplier || selectedBrand) {
        dispatch({ type: 'SELECT_SUPPLIER', payload: '' });
        dispatch({ type: 'SELECT_BRAND', payload: '' });
        dispatch({ type: 'SET_SUPPLIER_USER_EVENT', payload: undefined });
        dispatch({ type: 'SET_BRAND_USER_EVENT', payload: undefined });
      }
    }

    if (
      (isEmpty(inputBrand) &&
        !isEmpty(state.brand) &&
        state.brand === initialFormValues?.brandId) ||
      (isEmpty(inputSupplier) &&
        !isEmpty(state.supplier) &&
        state.supplier === initialFormValues?.supplierId)
    ) {
      // Update supplier after being created, once inpredient product has loaded the new supplier
      methods.reset(
        {
          cost: inputCost,
          grams: inputGrams,
          brand: state.brand,
          supplier: state.supplier,
          wastage: inputWastage,
        },
        {
          dirtyFields: true, // dirtyFields will not be reset
        }
      );
    }
  }, [
    inputSupplier,
    selectedBrand,
    userEvent,
    methods,
    ingredient,
    inputCost,
    inputGrams,
    inputBrand,
    dirtyFields,
    selectedIngredientProduct,
    initialFormValues,
    state,
    selectedSupplier,
    inputWastage,
    topSliderToggle,
    dispatch,
  ]);

  useEffect(() => {
    if (
      userEvent.ingredient === 'add' &&
      !isEmpty(ingredient.data?.ingredient.ingredient?.metrics)
    ) {
      dispatch({ type: 'SET_INGREDIENT_USER_EVENT', payload: 'edit' });
    }
  }, [ingredient, userEvent, dispatch]);

  const onSubmit = async (formData) => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveIngredient,
      });
    } else {
      const ingredientInput = {
        id: selectedIngredientProduct!,
        ingredientId: selectedIngredient!,
        venueId: selectedVenueObject?.id!,
        supplierId: formData.supplier,
        brandId: formData.brand,
        sizeInput: {
          productCost: Number(formData.cost),
          productGrams: Number(formData.grams.value),
        },
        wastageInput:
          initialFormValues?.wastageId || formData.wastage
            ? {
                id: initialFormValues?.wastageId || '',
                unitType: WastageUnitType.Percentage,
                amount: Number(formData.wastage),
              }
            : undefined,
        totalActiveSeconds: getTotalActiveSeconds(),
      };

      try {
        const refetchQueries = selectedRecipe
          ? [
              {
                query: RecipeDocument,
                variables: {
                  input: {
                    venueId: selectedVenueObject?.id!,
                    recipeId: selectedRecipe!,
                  },
                },
              },
              {
                query: IngredientsDocument,
              },
            ]
          : [
              {
                query: IngredientsDocument,
              },
            ];

        const response = await UpdateIngredientProductMutation({
          variables: {
            input: {
              ...ingredientInput,
            },
          },
          refetchQueries: refetchQueries,
        });

        if (response.data?.updateIngredientProduct.successful) {
          dispatch({
            type: 'SET_NEW_INGREDIENT_ID',
            payload: selectedIngredient!,
          });
          dispatch({ type: 'SELECT_INGREDIENT', payload: '' });
          dispatch({ type: 'SELECT_INGREDIENT_PRODUCT', payload: '' });
          dispatch({
            type: 'SET_INGREDIENT_DATA',
            payload: {
              ingredientCost: undefined,
              ingredientGrams: { label: '', value: '' },
            },
          });
          dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
          dispatch({
            type: 'SET_NEW_INGREDIENT_NAME',
            payload: '',
          });
        }
      } catch (error) {
        console.log('err ###', error);
      }
    }
  };

  const handleCancel = () => {
    if (extraSliderToggle) {
      dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
    }
    if (topSliderToggle) {
      dispatch({ type: 'TOGGLE_TOP_SLIDER' });
    }
    dispatch({ type: 'SELECT_INGREDIENT', payload: '' });
    dispatch({ type: 'SELECT_INGREDIENT_PRODUCT', payload: '' });
    dispatch({
      type: 'SET_INGREDIENT_DATA',
      payload: {
        ingredientCost: undefined,
        ingredientGrams: { label: '', value: '' },
        ingredientWastage: undefined,
      },
    });
  };

  const setIngredientData = () => {
    setState({ ...state, preventUpdate: true });
    dispatch({
      type: 'SET_INGREDIENT_DATA',
      payload: {
        ingredientCost: methods.watch('cost'),
        ingredientGrams: methods.watch('grams'),
        ingredientWastage: methods.watch('wastage'),
      },
    });
  };

  if (ingredient.loading || isEmpty(ingredient.data)) {
    return <LogoLoading size={60} />;
  }

  if (
    !ingredient.loading &&
    ingredient.data?.ingredient.successful &&
    isEmpty(ingredient.data?.ingredient.ingredient?.metrics) &&
    userEvent.ingredient !== 'add' &&
    !topSliderToggle
  ) {
    dispatch({ type: 'TOP_SLIDER_PAGE', payload: 'editIngredient' });
    return <LogoLoading size={60} />;
  }

  return (
    <LayoutPage
      backButton
      backButtonCTA={handleCancel}
      align="center"
      withLine
      heading="Ingredient"
      subHeading="Personalise your ingredients to make calculations more accurate"
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={handleCancel}>
                x
              </Button>,
              <SmallButton
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={updateIngredientProduct.loading}
              >
                <DotsLoading
                  text={(loading) => (loading ? 'Saving' : 'Save')}
                  isLoading={updateIngredientProduct.loading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </SmallButton>,
            ]
          : [
              <Button color="default" inversed onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                color="secondary"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={updateIngredientProduct.loading}
              >
                <DotsLoading
                  text={(loading) =>
                    `${loading ? 'Saving' : 'Save'} Ingredient`
                  }
                  isLoading={updateIngredientProduct.loading}
                  size="small"
                  lineHeight={10}
                  noMargin
                />
              </Button>,
            ]
      }
    >
      <FormProvider {...methods}>
        <Header ingredient={ingredient} />
        <Detail
          selectableSizes={state.selectableSizes}
          ingredient={ingredient}
          selectedCostPer100g={getCostPer100g(inputCost, inputGrams)}
          setIngredientData={setIngredientData}
        />
        <Brands setIngredientData={setIngredientData} ingredient={ingredient} />
        <Suppliers
          setIngredientData={setIngredientData}
          ingredient={ingredient}
        />
      </FormProvider>
    </LayoutPage>
  );
};

const sizeGramsObject = {
  value: Yup.string().required('Grams is required'),
};

const validationGramsSchema = Yup.object().shape({
  cost: Yup.string()
    .required('Cost is required')
    .typeError('Cost is required')
    .test('numberTest', 'Cost is not a number', (value) =>
      validateNumberValues(value)
    )
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),
  grams: Yup.object()
    .shape(sizeGramsObject)
    .required('Grams is required: Example 100g or 5000g')
    .typeError('Grams is required: Example 100g or 5000g'),
  supplier: Yup.string().required('Please select or add the supplier you use'),
});

const sizeMillilitersObject = {
  value: Yup.string().required(
    'Milliliters is required: : Example 100ml or 5000ml'
  ),
};

const validationMillilitersSchema = Yup.object().shape({
  cost: Yup.string()
    .required('Cost is required')
    .typeError('Cost is required')
    .test('numberTest', 'Cost is not a number', (value) =>
      validateNumberValues(value)
    )
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),
  grams: Yup.object()
    .shape(sizeMillilitersObject)
    .required('Milliliters is required')
    .typeError('Milliliters is required'),
  supplier: Yup.string().required('Please select or add the supplier you use'),
});

export default memo(Ingredient);
