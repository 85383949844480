import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { Button } from '../../../../shared/button';

const info =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20px' height='20px' viewBox='0 0 60 60'  style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath style='opacity:0.984' fill='%2310b561' d='M 25.5,2.5 C 51.9379,2.43538 63.1046,15.4354 59,41.5C 49.913,59.3667 35.913,64.7001 17,57.5C 5.16667,61.6667 1.33333,57.8333 5.5,46C -1.67997,24.871 4.98669,10.371 25.5,2.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23f7fcf9' d='M 28.5,15.5 C 35.4883,14.6478 37.8216,17.4811 35.5,24C 26.8377,26.5009 24.5043,23.6675 28.5,15.5 Z'/%3E%3C/g%3E%3Cg%3E%3Cpath style='opacity:1' fill='%23fbfdfc' d='M 29.5,28.5 C 31.9363,28.317 34.103,28.9837 36,30.5C 36.6667,35.8333 36.6667,41.1667 36,46.5C 33,49.1667 30,49.1667 27,46.5C 26.3333,41.1667 26.3333,35.8333 27,30.5C 27.9947,29.9341 28.828,29.2674 29.5,28.5 Z'/%3E%3C/g%3E%3C/svg%3E";

interface IColProps {
  icon?: boolean;
}

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li<IColProps>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};

  &.recipeVersion {
    justify-content: flex-start !important;
  }

  &:first-child {
    justify-content: flex-start;
    margin-left: 15px;
    padding-left: 0;
    border: 0;
  }

  .hasToolTipIcon {
    display: flex;
    align-items: first baseline;
  }

  &:hover {
    .hasToolTipIcon {
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        background-color: ${({ theme, icon = true }) =>
          icon ? 'transparent' : theme.colors.secondary.default};
        background-image: ${({ theme, icon = true }) =>
          icon
            ? `url(${`"${info}"`})`
            : `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
        width: ${({ icon = true }) => (icon ? '20px' : '8px')};
        height: ${({ icon = true }) => (icon ? '20px' : '8px')};
        border-radius: ${({ icon = true }) => (icon ? '0' : '8px')};
        margin-left: -4px;
        left: 50%;
        top: ${({ icon = true }) => (icon ? '-24px' : '-14px')};
      }
    }
  }

  @media ${device.mobileD} {
    &:first-child {
      margin: 10px;
    }
  }

  @media ${device.laptop} {
    &:first-child {
      margin: 0;
    }

    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 0;
    }
  }
`;

export const Row = styled.ul`
  min-height: 55px;
  display: grid;
  grid-template-columns: 50px auto 70px 75px;
  cursor: pointer;

  .serves {
    padding-right: 10px;
  }

  .selectedTick {
    margin-left: 5px;
    padding-top: 5px;
  }

  &:first-child {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.background};
    min-height: 40px;
    ${Col} {
      border: 0;
    }
  }
  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: 65px 5px 42px;
    grid-gap: 10px;

    .last {
      text-align: right;
    }
  }

  @media ${device.mobileD} {
    grid-template-columns: 50px auto 70px 130px 75px;
  }
  @media ${device.mobileL} {
    grid-template-columns: 50px auto 105px 130px 75px;
  }
  @media ${device.tablet} {
    grid-template-columns: 50px auto 80px 105px 130px 75px;
  }
  @media ${device.laptop} {
    grid-template-columns: 70px auto 145px 115px 150px 80px;
  }
`;

export const HeaderRow = styled.ul`
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-columns: auto 130px 75px;
  padding-right: 15px;

  &:first-child {
    ${Col} {
      border: 0;
      &:last-child {
        padding-right: 15px;
      }
    }
  }

  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto 50px 55px;
    grid-gap: 10px;

    .last {
      text-align: right;
    }
  }

  @media ${device.mobileD} {
    grid-template-columns: auto 92px 130px 75px;
  }
  @media ${device.mobileL} {
    grid-template-columns: auto 105px 130px 75px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto 145px 105px 130px 75px;
  }
  @media ${device.laptop} {
    grid-template-columns: 70px auto 145px 115px 150px 80px;
  }
`;

export const Icon = styled.div`
  height: 50px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }

  @media ${device.laptop} {
    margin-left: 12px;
  }
`;

export const CTAButton = styled(Button)`
  min-width: 170px;
`;

export const ToolTipIcon = styled.div`
  cursor: pointer;
  content: '';
  position: absolute;
  background-color: transparent;
  background-image: url(${`"${info}"`});
  width: 20px;
  height: 20px;
  right: 0;

  &.showTip {
    right: -22px;
    bottom: 12px;
    z-index: ${({ theme }) => theme.zIndex.preAbove};
  }
`;
