import React, {
  memo,
  useState,
  useEffect,
  useRef,
  FC,
  useContext,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import SaladMix from '../../../../shared/icons/components/SaladMix';
import { H3 } from '../../../../shared/typefaces/Typefaces.styles';
import { Input } from '../../../../shared/formElements';
import {
  Container,
  RecipeNameButton,
  ButtonContainer,
  RecipeError,
  ErrorContainer,
  StyledInput,
  HeaderInputs,
  Section,
  InputWrapper,
} from './Header.styles';
import { IHeaderProps } from './header.types';
import { capitalizeFirstLetterPerWord } from '../../../../../utils/helper';
import { LayoutContext } from '../../../../../contexts/layoutContext';

const Header: FC<IHeaderProps> = ({ showUniqueError }) => {
  const { selectedRecipeVersion } = useContext(LayoutContext);
  const { register, errors, control, watch, formState } = useFormContext();
  const [hideButton, setHideButton] = useState(false);
  const displayName = watch('displayName');
  const firstNameRef = useRef<HTMLInputElement | null>();

  const { dirtyFields } = formState;

  useEffect(() => {
    if ((hideButton || !!errors.displayName) && firstNameRef.current) {
      firstNameRef!.current.focus();
    }
    if (hideButton && firstNameRef.current) {
      firstNameRef!.current.focus();
    }
  }, [firstNameRef, hideButton, errors]);

  const showNameInput = () => {
    setHideButton(true);
  };

  const uniqueError = showUniqueError
    ? `You have already created a ${capitalizeFirstLetterPerWord(
        displayName
      )} recipe. Please create a unique name for this recipe or Add your new recipe version to the comparison section in your original ${capitalizeFirstLetterPerWord(
        displayName
      )} recipe results`
    : undefined;

  const showRecipeNameInput =
    !!displayName ||
    hideButton ||
    dirtyFields['displayName'] ||
    !!errors.displayName;

  return (
    <Container>
      <Section>
        <InputWrapper showInput={showRecipeNameInput}>
          <StyledInput
            ref={(e: HTMLInputElement) => {
              register(e);
              firstNameRef.current = e; // you can still assign to your own ref
            }}
            label={
              selectedRecipeVersion ? 'Recipe Version Name' : 'Recipe Name'
            }
            name="displayName"
            errorText={errors.displayName?.message || uniqueError}
            border="noBorder"
            placeholder="Add Recipe Name"
          />
        </InputWrapper>

        <ButtonContainer hideButton={showRecipeNameInput}>
          <RecipeNameButton color="default" inversed onClick={showNameInput}>
            <SaladMix size="large" />
            <H3 color="black" fontWeight="regular">
              Add Recipe Name +
            </H3>
          </RecipeNameButton>
        </ButtonContainer>
        {errors?.items?.message && (
          <ErrorContainer>
            <RecipeError>{errors.items.message}</RecipeError>
          </ErrorContainer>
        )}
      </Section>
      <HeaderInputs>
        <Controller
          as={<Input />}
          type="number"
          label="Serves"
          name="serves"
          control={control}
          errorText={errors.serves?.message}
        />
        <Controller
          as={<Input />}
          type="number"
          label="Pre-sale waste %"
          name="recipeWastage"
          control={control}
          errorText={errors.recipeWastage?.message}
        />
      </HeaderInputs>
    </Container>
  );
};

export default memo(Header);
