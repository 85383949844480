import { isEmpty } from './objects';

export const roundNumber = (value: number, decimals: number) => {
  const d = decimals < 2 ? decimals : 2;
  return +(Math.round(Number(value + 'e+' + d)) + 'e-' + d);
};

export const currency = (value: number) => {
  const number = roundNumber(value, 2);
  return number.toFixed(2);
};

export const convertGramsCleanly = (grams, liquid?: boolean) => {
  let gramsText = '-';
  if (isEmpty(grams) || isNaN(grams)) {
    return gramsText;
  } else {
    if (liquid) {
      return `${roundTo(grams)}ml`;
    }
    return `${roundTo(grams)}g`;
  }
};

export const convertNumberCleanly = (number: number): string => {
  let numberText = '-';
  if (isEmpty(number) || isNaN(number)) {
    return numberText;
  }
  return `${number}`;
};

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertCostCleanly = (cost: any, allowZero?: boolean) => {
  let costText = allowZero ? '$0' : '-';

  if (isEmpty(cost) || isNaN(cost) || !isFinite(cost)) {
    if (cost === 0) {
      return cost;
    }
    return costText;
  } else {
    const c = Number(currency(cost));

    if (isNaN(c)) {
      return costText;
    }

    if (c === 0) {
      const v = Number(roundTo(cost, 4).toFixed(4));
      return v !== 0 ? `${v}` : `${Number(roundTo(cost, 6).toFixed(6))}`;
    }

    return `$${numberWithCommas(c)}`;
  }
};

export const convertPercentageCleanly = (
  percentage: number,
  decimals?: number
) => {
  if (isEmpty(percentage) || isNaN(percentage) || !isFinite(percentage)) {
    return '-';
  } else {
    return `${roundTo(percentage).toFixed(
      decimals === undefined ? 2 : decimals
    )}%`;
  }
};

export const roundTo = function (num: number, decimalPlaces?: number) {
  const places = decimalPlaces ? decimalPlaces : 2;
  const factor = 10 ** places;
  return Math.round(num * factor) / factor;
};

export const roundToWholeNumber = function (
  num: number,
  direction?: 'up' | 'down'
) {
  if (direction) {
    if (direction === 'up') {
      return Math.ceil(num);
    }
    // direction === down
    return Math.floor(num);
  }

  return Math.round(num);
};

export const convertPhoneNumberCleanly = (number?: number | string) => {
  if (!number) return '';

  // Chain the replace methods to remove brackets and whitespace in one line
  const n = number.toString().replace(/[()\s]/g, '');

  if (n.charAt(0) === '4' && n.length < 10) {
    return '0' + n;
  }

  return n;
};

export const getPercentageDifference = (
  original?: number,
  current?: number
): number | undefined => {
  if (!original || !current || roundTo(original) === 0) {
    return undefined;
  }
  // Increase = New Number - Original Number
  const increase = current - original;
  //% increase = Increase ÷ Original Number × 100.
  const result = roundTo((increase / original) * 100);
  const hasImproved = original < current;
  return hasImproved ? Math.abs(result) : -Math.abs(result);
};

export const isEven = (n: number) => {
  return n % 2 === 0;
};

export const blurContent = (content: string | number) => {
  const totalCharacters = content.toString().length;
  if (totalCharacters < 3) {
    return 'x'.repeat(3);
  }
  return 'x'.repeat(totalCharacters);
};

export const isNegative = (number: number) => {
  return Math.sign(number) === -1;
};
