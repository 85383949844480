import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import {
  AccountType,
  IngredientMetricUnit,
  MetricType,
  RecipeMetricUnit,
} from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import {
  convertGramsCleanly,
  convertTimeMinutesCleanly,
  getFieldArrayErrorMessage,
  getTotalTimeSeconds,
  getUnitTotal,
  isEmpty,
  roundTo,
  sortArrayObjects,
} from '../../../../../utils/helper';
import { getSubRecipeGrams } from '../../../../../utils/helper/getSubRecipeGrams';
import { mockData } from '../../../../../utils/mockData';
import { Input, SelectList } from '../../../formElements';
import { IInitialSelectListOptionProps } from '../../../formElements/selectList/selectList.types';
import { Apple, Bin, ChefHat, Timer } from '../../../icons';
import { DeleteRow, RowIcon, RowTotal } from './Items.styles';
import { IIngredientUnitOptionsProps, RecipeRowArgs } from './items.types';

export function getFormRow({
  item,
  index,
  recipes,
  selectedRecipe,
  ingredients,
  liquidRecipe,
  appWidth,
  errors,
  disableIngredient,
  control,
  initialItems,
  accountType,
  hasCalculationAddOn,
  setValue,
  handleDelete,
  setLiquidRecipe,
  handleIngredientChange,
  handleIngredientTotalChange,
}: RecipeRowArgs) {
  const quantity = Number(item.quantity);
  const unit = item.unit;
  const selectedIngredient = item.ingredient;
  const pluralItem = quantity && quantity > 1;
  const isNewIngredient = item?.ingredient && !item?.ingredient?.extra;

  const showSubRecipeIngredients =
    hasCalculationAddOn ||
    accountType === AccountType.RecipePlus ||
    accountType === AccountType.HeadChef ||
    accountType === AccountType.Owner ||
    accountType === AccountType.Feedback ||
    accountType === AccountType.RecipeRevenue;
  const subRecipeData = {
    totalGrams: 0,
    totalTime: 0,
    serves: 0,
  };

  let total =
    item.__typename === 'RecipeTimeItem'
      ? getTotalTimeSeconds(unit, quantity)
      : 0;

  let ingredientUnitOptions: IIngredientUnitOptionsProps[] = [
    {
      label: 'gram',
      value: IngredientMetricUnit.Gram || RecipeMetricUnit.Serve,
    },
  ];

  const liqudMetricOptions = [{ label: 'ml', value: IngredientMetricUnit.Ml }];
  const liquidMetricOptionsPlural = [
    { label: 'mLs', value: IngredientMetricUnit.Ml },
  ];
  const solidMetricOptions = [
    { label: 'gram', value: IngredientMetricUnit.Gram },
  ];
  const solidMetricOptionsPlural = [
    { label: 'grams', value: IngredientMetricUnit.Gram },
  ];
  let metricOptions: IInitialSelectListOptionProps[] = [];
  let metricOptionsPlural: IInitialSelectListOptionProps[] = [];
  let liquid = false;
  let metrics: Record<
    string, // metric name
    number // number of grams per unit of this metric
  >;
  let defaultUnitValue = IngredientMetricUnit.Gram as string;
  let defaultIngredientTypeValue = 'INGREDIENT';
  let label = 'Ingredient name';
  let ingredientOptions: IInitialSelectListOptionProps[] = [];
  let recipeIngredientOptions: IInitialSelectListOptionProps[] = [];
  let mergedOptions: IInitialSelectListOptionProps[] = [];

  if (selectedIngredient) {
    if (selectedIngredient.extra === 'recipe' && recipes) {
      const recipe = recipes.find(
        (recipe) => recipe.id === selectedIngredient.value
      );

      subRecipeData.serves = recipe!.serves;
      subRecipeData.totalGrams = recipe!.totalGrams;
      subRecipeData.totalTime = recipe!.totalTime;

      if (unit && !isEmpty(quantity)) {
        total = getSubRecipeGrams({
          unit: unit,
          quantity: Number(quantity),
          totalGrams: recipe?.totalGrams ?? 0,
          recipeServes: recipe?.serves ?? 0,
        });
        defaultUnitValue = unit;
      }
      label = 'Ingredient name - Sub-recipe';

      defaultIngredientTypeValue = 'RECIPE';

      ingredientUnitOptions = [
        {
          label: pluralItem ? 'serves' : 'serve',
          value: RecipeMetricUnit.Serve,
        },
        {
          label: pluralItem ? 'grams' : 'gram',
          value: IngredientMetricUnit.Gram,
        },
      ];
    } else {
      const ingredient = ingredients?.find(
        (ingredient) => ingredient.id === selectedIngredient?.value
      );

      metrics =
        ingredient?.metrics.reduce((obj, item) => {
          return Object.assign(obj, { [item.type]: item.grams });
        }, {} as Record<string, number>) ?? {};

      total =
        unit && !isEmpty(quantity) ? getUnitTotal(unit, quantity, metrics) : 0;

      if (ingredient) {
        // getIngredient
        // create metric options
        metricOptions = new Array<IInitialSelectListOptionProps>()
          .concat(ingredient?.liquid ? liqudMetricOptions : solidMetricOptions)
          .concat(
            ingredient?.metrics.map((metric) => {
              return {
                label: metric.type.toLowerCase(),
                value: metric.type,
              };
            }) || []
          );

        metricOptionsPlural = new Array<IInitialSelectListOptionProps>()
          .concat(
            ingredient?.liquid
              ? liquidMetricOptionsPlural
              : solidMetricOptionsPlural
          )
          .concat(
            ingredient?.metrics.map((metric) => {
              const label = metric.type.toLowerCase();
              if (metric.type.toUpperCase() === MetricType.Whole) {
                return {
                  label,
                  value: metric.type,
                };
              } else {
                return {
                  label: `${label}s`,
                  value: metric.type,
                };
              }
            }) || []
          );

        ingredientUnitOptions = pluralItem
          ? sortArrayObjects(metricOptionsPlural, 'value')
          : sortArrayObjects(metricOptions, 'value');

        if (
          (ingredient && ingredient?.liquid) ||
          unit === IngredientMetricUnit.Ml
        ) {
          if (unit === IngredientMetricUnit.Gram) {
            setValue(`items[${index}].unit`, IngredientMetricUnit.Ml);
          }
          if (
            (liquidRecipe !== 'gram' && liquidRecipe !== 'liquid') ||
            (unit === IngredientMetricUnit.Ml &&
              liquidRecipe !== 'liquid' &&
              liquidRecipe !== 'gram')
          ) {
            setLiquidRecipe('liquid');
          }
          liquid = true;
        }

        if ((ingredient && !ingredient?.liquid) || unit === 'gram') {
          if (unit === IngredientMetricUnit.Ml) {
            setValue(`items[${index}].unit`, 'gram');
          }
          if (liquidRecipe !== '' && liquidRecipe !== 'gram') {
            setLiquidRecipe('gram');
          }
          liquid = false;
        }

        defaultUnitValue = unit!;
      } else {
        // This will trigger if it's an example recipe & the ingredient is a sub-recipe
        label = 'Ingredient name';
        metricOptions = solidMetricOptions;
        metricOptionsPlural = solidMetricOptionsPlural;
        ingredientUnitOptions = pluralItem
          ? sortArrayObjects(metricOptionsPlural, 'value')
          : sortArrayObjects(metricOptions, 'value');
        defaultUnitValue =
          unit === RecipeMetricUnit.Serve ? IngredientMetricUnit.Gram : unit!;
      }
    }
  }

  if (ingredients) {
    ingredientOptions =
      ingredients?.map((ingredient) => {
        return {
          label: ingredient.displayName,
          value: ingredient.id,
          extra: 'ingredient',
        };
      }) || [];

    if (recipes) {
      recipeIngredientOptions =
        recipes
          .filter((recipe) => {
            return recipe.id !== selectedRecipe;
          })
          .map((recipe) => {
            return {
              label: recipe.displayName,
              value: recipe.id,
              extra: 'recipe',
              highlight: 'sub-recipe',
            };
          }) || [];
    }

    mergedOptions = sortArrayObjects(
      showSubRecipeIngredients
        ? [...ingredientOptions, ...recipeIngredientOptions]
        : ingredientOptions,
      'label'
    );
  }

  if (recipes) {
    recipeIngredientOptions = (
      recipes.map((recipe) => {
        return {
          label: recipe.displayName,
          value: recipe.id,
        };
      }) || []
    ).sort((a, b) => {
      var nameA = a.label.toLowerCase(),
        nameB = b.label.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }
  switch (item.__typename) {
    case 'RecipeIngredient':
      const initialMetricOptions:
        | IIngredientUnitOptionsProps[]
        | undefined = initialItems[index]?.metrics?.map((m) => {
        return {
          label: m.type.toLowerCase(),
          value: m.type as IngredientMetricUnit | RecipeMetricUnit,
        };
      });

      if (ingredientUnitOptions?.length === 1 && initialMetricOptions) {
        ingredientUnitOptions = [
          ...ingredientUnitOptions,
          ...initialMetricOptions,
        ];
      }

      const ingredientTotalLoading =
        item.quantity && defaultUnitValue && total === 0 ? true : false;
      const ingredientHelperText = ingredientTotalLoading
        ? 'Ingredient Total Loading..'
        : isNewIngredient
        ? 'On next page, update ingredient details & metrics.'
        : '';

      return {
        id: item.rowKey,
        hiddenType: (
          <Fragment>
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].rowKey`}
              defaultValue={item.rowKey}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].type`}
              defaultValue={defaultIngredientTypeValue}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].recipeItemId`}
              defaultValue={item.recipeItemId ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].order`}
              defaultValue={item.order ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].__typename`}
              defaultValue={item.__typename}
            />
          </Fragment>
        ),
        icon:
          appWidth < theme.mQ.tablet ? (
            <Fragment />
          ) : (
            <RowIcon>
              <Apple className="icon" faded={disableIngredient} size="small" />
            </RowIcon>
          ),
        name: (
          <SelectList
            className="rowInput"
            autoComplete
            control={control}
            name={`items[${index}].ingredient`}
            label={label}
            handleChange={handleIngredientChange}
            errorText={getFieldArrayErrorMessage(
              'ingredient',
              index,
              errors.items
            )}
            options={mergedOptions}
            defaultValue={item.ingredient || null}
            disabled={disableIngredient}
            helperText={ingredientHelperText}
          />
        ),
        item1: (
          // Ingredient quantity input
          <Controller
            as={<Input />}
            type="number"
            className="rowInput"
            label={appWidth < theme.mQ.tablet ? 'Quanity' : ''}
            align={appWidth < theme.mQ.tablet ? 'left' : 'right'}
            name={`items[${index}].quantity`}
            control={control}
            handleChange={() =>
              handleIngredientTotalChange({ index, subRecipeData, metrics })
            }
            placeholder="Amount"
            defaultValue={
              isEmpty(item.quantity)
                ? ''
                : `${roundTo(Number(item.quantity), 3)}`
            }
            errorText={getFieldArrayErrorMessage(
              'quantity',
              index,
              errors.items
            )}
            disabled={disableIngredient}
          />
        ),
        item2: (
          <SelectList
            className="rowInput"
            noBorder
            control={control}
            name={`items[${index}].unit`}
            handleChange={() =>
              handleIngredientTotalChange({ index, subRecipeData, metrics })
            }
            errorText={getFieldArrayErrorMessage('unit', index, errors.items)}
            options={ingredientUnitOptions}
            defaultValue={defaultUnitValue || 'GRAM'}
            disabled={disableIngredient}
          />
        ),
        item3: <RowTotal>{convertGramsCleanly(total, liquid)}</RowTotal>,
        item4: disableIngredient ? (
          <Fragment />
        ) : (
          <DeleteRow
            disabled={disableIngredient}
            onClick={() => handleDelete(item, index)}
          >
            <Bin color="grey" size="small" />
          </DeleteRow>
        ),
      };
    case 'RecipeTimeItem':
      const isStaffTime = item.staffTime || item.type === 'STAFF_TIME';

      return {
        id: item.rowKey,
        hiddenType: (
          <Fragment>
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].rowKey`}
              defaultValue={item.rowKey}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].type`}
              defaultValue={isStaffTime ? 'STAFF_TIME' : 'PROCESS_TIME'}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].staffTime`}
              defaultValue={isStaffTime}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].recipeItemId`}
              defaultValue={item.recipeItemId ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].order`}
              defaultValue={item.order ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].__typename`}
              defaultValue={item.__typename}
            />
          </Fragment>
        ),
        icon:
          appWidth < theme.mQ.tablet ? (
            <Fragment />
          ) : isStaffTime ? (
            <RowIcon>
              <ChefHat className="icon" size="small" />
            </RowIcon>
          ) : (
            <RowIcon>
              <Timer className="icon" size="small" />
            </RowIcon>
          ),
        name: (
          <Controller
            as={<Input textarea hasCapitalizeFirstLetter />}
            className="rowInput"
            control={control}
            label={
              isStaffTime
                ? 'Hands On Time Description'
                : 'Hands Off Time Description'
            }
            placeholder={
              isStaffTime
                ? 'Add Hands On Tasks for the Recipe Method Description'
                : 'Add Hands Off Tasks for the Recipe Method Description'
            }
            name={`items[${index}].description`}
            errorText={getFieldArrayErrorMessage(
              'description',
              index,
              errors.items
            )}
            defaultValue={item.description || ''}
          />
        ),
        item1: (
          <Controller
            as={<Input />}
            className="rowInput"
            type="number"
            align="right"
            name={`items[${index}].quantity`}
            control={control}
            placeholder="Time"
            defaultValue={
              isEmpty(item.quantity)
                ? ''
                : `${roundTo(Number(item.quantity), 3)}`
            }
            errorText={getFieldArrayErrorMessage(
              'quantity',
              index,
              errors.items
            )}
          />
        ),
        item2: (
          <SelectList
            className="rowInput"
            noBorder
            control={control}
            name={`items[${index}].unit`}
            errorText={getFieldArrayErrorMessage('units', index, errors.items)}
            options={
              quantity > 1 ? mockData.timeUnitsPural : mockData.timeUnits
            }
            defaultValue={item.unit || 'minute'}
          />
        ),
        item3: <RowTotal>{convertTimeMinutesCleanly(total)}</RowTotal>,
        item4: (
          <DeleteRow onClick={() => handleDelete(item, index)}>
            <Bin color="grey" size="small" />
          </DeleteRow>
        ),
      };
    case 'RecipeItemDeleted':
      return {
        id: item.rowKey,
        hiddenType: (
          <Fragment>
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].rowKey`}
              defaultValue={item.rowKey}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].type`}
              defaultValue={'DELETE_ITEM'}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].recipeItemId`}
              defaultValue={item.recipeItemId ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].deleteType`}
              defaultValue={item.deleteType}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].order`}
              defaultValue={item.order ?? ''}
            />
            <Controller
              as={<Input />}
              className="hiddenInput"
              type="hidden"
              name={`items[${index}].__typename`}
              defaultValue={item.__typename}
            />
          </Fragment>
        ),
        icon: <Fragment />,
        name: <Fragment />,
        item1: (
          <Controller
            as={<Input />}
            control={control}
            className="hiddenInput"
            type="hidden"
            name={`items[${index}].quantity`}
            defaultValue={item.quantity}
          />
        ),
        item2: (
          <Controller
            as={<Input />}
            control={control}
            className="hiddenInput"
            type="hidden"
            name={`items[${index}].unit`}
            defaultValue={item.unit}
          />
        ),
        item3: <Fragment />,
        item4: <Fragment />,
        delete: true,
      };

    default:
      return {
        icon: <Fragment />,
        name: <Fragment />,
        item1: <Fragment />,
        item2: <Fragment />,
        item3: <Fragment />,
      };
  }
}
