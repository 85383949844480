import { ILayoutContext, initialLayoutState } from '../contexts/layoutContext';

export const layoutReducer = (
  state: ILayoutContext,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case 'SET_APP_WIDTH':
      return { ...state, appWidth: action.payload };
    case 'OPEN_MENU':
      return { ...state, isOpen: true };
    case 'CLOSE_MENU':
      return { ...state, isOpen: false };
    case 'TOGGLE_MENU':
      return { ...state, menuToggle: !state.menuToggle };
    case 'TOGGLE_DESKTOP_MENU':
      return { ...state, desktopMenuToggle: !state.desktopMenuToggle };
    case 'SHOW_MENU_OVERLAY':
      return { ...state, showMenuOverlay: action.payload };

    case 'CLOSE_SLIDER':
      return { ...state, closeSlider: action.payload };
    case 'TOGGLE_SLIDER':
      if (state.sliderToggle) {
        const overlay = {
          overlayToggle: state.overlayToggle,
          overlay: {
            heading: '',
            subHeading: '',
            buttonText: '',
            linkText: '',
          },
        };
        return {
          ...state,
          sliderToggle: !state.sliderToggle,
          sliderPage: '',
          ...overlay,
        };
      }
      return { ...state, sliderToggle: !state.sliderToggle };
    case 'SLIDER_PAGE':
      return { ...state, sliderPage: action.payload };

    case 'CLOSE_EXTRA_SLIDER':
      return { ...state, closeExtraSlider: action.payload };
    case 'TOGGLE_EXTRA_SLIDER':
      if (state.extraSliderToggle) {
        const overlay = {
          overlayToggle: state.overlayToggle,
          overlay: {
            heading: '',
            subHeading: '',
            buttonText: '',
            linkText: '',
          },
        };
        return {
          ...state,
          extraSliderToggle: !state.extraSliderToggle,
          extraSliderPage: '',
          ...overlay,
        };
      }
      return { ...state, extraSliderToggle: !state.extraSliderToggle };
    case 'EXTRA_SLIDER_PAGE':
      return { ...state, extraSliderPage: action.payload };
    case 'CLOSE_TOP_SLIDER':
      return { ...state, closeTopSlider: action.payload };
    case 'TOGGLE_TOP_SLIDER':
      if (state.topSliderToggle) {
        const overlay = {
          overlayToggle: state.overlayToggle,
          overlay: {
            heading: '',
            subHeading: '',
            buttonText: '',
            linkText: '',
          },
        };
        return {
          ...state,
          topSliderToggle: !state.topSliderToggle,
          topSliderPage: '',
          ...overlay,
        };
      }
      return { ...state, topSliderToggle: !state.topSliderToggle };
    case 'TOP_SLIDER_PAGE':
      return { ...state, topSliderPage: action.payload };
    case 'SELECT_VENUE_OBJECT':
      return { ...state, selectedVenueObject: action.payload };
    case 'ADD_VENUE':
      return { ...state, addVenue: action.payload };
    case 'SELECT_RECIPE':
      return { ...state, selectedRecipe: action.payload };
    case 'SELECT_RECIPE_VERSION':
      return { ...state, selectedRecipeVersion: action.payload };
    case 'SELECT_SUB_RECIPE':
      return { ...state, selectedSubRecipe: action.payload };
    case 'SET_NEW_INGREDIENT_NAME':
      return { ...state, newIngredientName: action.payload };
    case 'SET_NEW_INGREDIENT_ID':
      return { ...state, newIngredientId: action.payload };
    case 'SELECT_INGREDIENT':
      return { ...state, selectedIngredient: action.payload };
    case 'SELECT_INGREDIENT_PRODUCT':
      return { ...state, selectedIngredientProduct: action.payload };
    case 'SELECT_BRAND':
      return { ...state, selectedBrand: action.payload };
    case 'SELECT_SUPPLIER':
      return { ...state, selectedSupplier: action.payload };
    case 'SET_INGREDIENT_DATA':
      return {
        ...state,
        ingredientCost: action.payload.ingredientCost,
        ingredientGrams: action.payload.ingredientGrams,
        ingredientWastage: action.payload.ingredientWastage,
      };
    case 'DUPLICATE_RECIPE':
      return { ...state, duplicateRecipe: action.payload };
    case 'SCALE_RECIPE':
      return { ...state, scaleRecipe: action.payload };
    case 'SET_SCALE_SERVES':
      return { ...state, scaleServes: action.payload };
    case 'TOGGLE_RECIPE_MENU':
      return { ...state, showRecipeMenu: !state.showRecipeMenu };
    case 'SET_NEW_RECIPE':
      return { ...state, newRecipe: action.payload };
    case 'SET_NEW_RECIPE_FROM_TEMPLATE':
      return { ...state, newRecipeFromTemplate: action.payload };
    case 'RESET_ALL_TOOL_TIPS':
      return {
        ...state,
        toolTips: {
          ...state.toolTips,
          editRecipe: true,
          recipeIngredients: true,
          salesPrice: true,
          weeklySales: true,
        },
      };
    case 'SET_TOOL_TIPS':
      return { ...state, toolTips: { ...state.toolTips, ...action.payload } };
    case 'SET__TOOL_TIP_RESIZE':
      return {
        ...state,
        toolTips: { ...state.toolTips, resize: action.payload },
      };
    case 'SET_RECIPE_RESULTS_TOOL_TIPS':
      return {
        ...state,
        toolTips: {
          ...state.toolTips,
          recipeResults: { ...state.toolTips.recipeResults, ...action.payload },
        },
      };
    case 'SET_STOCKTAKE_TOOL_TIPS':
      return {
        ...state,
        toolTips: {
          ...state.toolTips,
          stocktake: { ...state.toolTips.stocktake, ...action.payload },
        },
      };
    case 'SHOW_TOOL_TIP':
      return {
        ...state,
        toolTip: {
          show: true,
          ...action.payload,
        },
      };
    case 'HIDE_TOOL_TIP':
      return {
        ...state,
        toolTip: {
          show: false,
          heading: '',
          content: '',
          buttonText: '',
          height: 100,
          width: 100,
        },
      };
    case 'TOGGLE_RECIPE_UPDATED':
      return { ...state, recipeUpdated: !state.recipeUpdated };
    case 'CLOSE_OVERLAY':
      return {
        ...state,
        overlayToggle: false,
        overlay: {
          heading: '',
          subHeading: '',
          buttonText: '',
          linkText: '',
          extraData: undefined,
          hideCTA: false,
          content: undefined,
          toggleSliderOnButtonCTA: true,
          toggleSliderOnLinkCTA: true,
        },
      };
    case 'SET_OVERLAY':
      return {
        ...state,
        overlayToggle: true,
        overlay: action.payload ? action.payload : state.overlay,
      };
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload,
      };
    case 'SET_NEW_ACCOUNT':
      return {
        ...state,
        newAccount: {
          type: action?.payload?.type || initialLayoutState.newAccount?.type,
          billingCycle:
            action?.payload?.billingCycle ||
            initialLayoutState.newAccount?.billingCycle,
          addOns:
            action?.payload?.addOns || initialLayoutState.newAccount?.addOns,
          awaitingPayment:
            action?.payload?.awaitingPayment ||
            initialLayoutState.newAccount?.awaitingPayment,
        },
      };
    case 'SET_SIGNUP_BILLING_CYCLE':
      return {
        ...state,
        billingCycle: action.payload,
      };
    case 'SET_INGREDIENT_USER_EVENT':
      return {
        ...state,
        userEvent: {
          ...state.userEvent,
          ingredient: action.payload,
        },
      };
    case 'SET_BRAND_USER_EVENT':
      return {
        ...state,
        userEvent: {
          ...state.userEvent,
          brand: action.payload,
        },
      };
    case 'SET_SUPPLIER_USER_EVENT':
      return {
        ...state,
        userEvent: {
          ...state.userEvent,
          supplier: action.payload,
        },
      };
    case 'TOGGLE_HELP_MODAL':
      return {
        ...state,
        help: {
          ...state.help,
          showHelpModal: !state.help.showHelpModal,
        },
      };
    case 'TOGGLE_FB_CHATBOX':
      return {
        ...state,
        help: {
          ...state.help,
          showFBChatbox: !state.help.showFBChatbox,
        },
      };
    case 'SET_EDIT_ACCOUNT':
      return {
        ...state,
        editAccount: action.payload,
      };
    case 'SET_POPUP':
      return {
        ...state,
        popup: action.payload,
      };
    case 'SET_SELECTED_STOCKTAKE':
      return {
        ...state,
        selectedStocktake: action.payload,
      };
    case 'SET_SELECTED_STOCKTAKE_SECTION':
      return {
        ...state,
        selectedStocktakeSection: action.payload,
      };
    case 'SET_USER_DETAILS':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_TOTAL_RECIPES':
      return {
        ...state,
        totalRecipes: action.payload,
      };
    case 'SET_PLAY_VIDEO':
      return {
        ...state,
        playVideo: action.payload,
      };
    case 'RESET_STORE':
      return {
        ...initialLayoutState,
        appWidth: state.appWidth,
        justLoggedOut: Boolean(action.payload.justLoggedOut),
      };
    default:
      return state;
  }
};
