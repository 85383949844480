import * as ApolloClient from '@apollo/client';
import {
  RecipesQuery,
  RecipesQueryVariables,
  RecommendedPriceType,
} from '../../../generated/graphql';

const getPercentThreshold = ({
  arr,
  percentage = 0.1,
  direction = 'top',
}: {
  arr: Array<any>;
  percentage?: number;
  direction?: 'top' | 'bottom';
}) => {
  const thresholdIndex = Math.ceil(arr.length * percentage);
  return direction === 'bottom' ? thresholdIndex : arr.length - thresholdIndex;
};

export const getRecipesThatNeedMoreSales = ({
  data,
}: {
  data: ApolloClient.QueryResult<RecipesQuery, RecipesQueryVariables>;
}) => {
  const recipes = data.data?.venueRecipes.recipes.filter(
    (r) => r.salesPricePerServe && r.weeklySalesPerServe
  );
  if (!recipes) return [];
  const topThresholdIndex = getPercentThreshold({ arr: recipes });

  const recipesReadyToSort = recipes.map((r) => {
    return {
      ...r,
      profitRatio: r.recipeProfit / r.serves / r.weeklySalesPerServe,
    };
  });

  const sortedRecipes = recipesReadyToSort
    .slice()
    .sort((a, b) => a.profitRatio - b.profitRatio);

  const recipesThatNeedMoreSales = sortedRecipes
    .filter((_, idx) => idx >= topThresholdIndex)
    .slice(0, 5);

  return recipesThatNeedMoreSales;
};

export const getRecipesTheNeedMoreProfit = ({
  data,
}: {
  data: ApolloClient.QueryResult<RecipesQuery, RecipesQueryVariables>;
}) => {
  const recipes = data.data?.venueRecipes.recipes.filter(
    (r) => r.salesPricePerServe && r.weeklySalesPerServe
  );
  if (!recipes) return [];
  const topThresholdIndex = getPercentThreshold({ arr: recipes });
  const sortedRecipes = recipes
    .slice()
    .sort((a, b) => a.weeklySalesPerServe - b.weeklySalesPerServe);
  const recipesTheNeedMoreProfit = sortedRecipes
    .slice(topThresholdIndex)
    .slice(0, 5);

  return recipesTheNeedMoreProfit;
};

export const getRecipesToDrop = ({
  data,
}: {
  data: ApolloClient.QueryResult<RecipesQuery, RecipesQueryVariables>;
}) => {
  const recipes = data.data?.venueRecipes.recipes.filter(
    (r) => r.salesPricePerServe && r.weeklySalesPerServe
  );
  if (!recipes) return [];
  const bottomThresholdIndex = getPercentThreshold({
    arr: recipes,
    direction: 'bottom',
  });
  const formattedRecipes = recipes.map((r) => ({
    ...r,
    totalProfit: (r.recipeProfit / r.serves) * r.weeklySalesPerServe,
  }));
  const sortedRecipes = formattedRecipes
    .slice()
    .sort((a, b) => a.totalProfit - b.totalProfit);
  const recipesTheNeedMoreProfit = sortedRecipes
    .slice(0, bottomThresholdIndex)
    .slice(0, 5);
  return recipesTheNeedMoreProfit;
};

export const getRecommendedPriceType = (
  priceType?: string,
  targetFoodMargin?: number | null
) => {
  const base = '2 * Recipe Cost';

  switch (priceType) {
    case RecommendedPriceType.FoodCost:
      return '4.1 * Food Cost';
    case RecommendedPriceType.RecipeCost:
      return base;
    case RecommendedPriceType.TargetMargin:
      if (targetFoodMargin) {
        return 'Target Food Margin';
      }
      return base;

    default:
      return base;
  }
};
